<template>
  <div class="showcases-suggestions">
    <router-link
      :to="`/${$route.name}/${previousShowcase.id}`"
      class="suggestion"
      :style="{ 'background-image': `url(${previousShowcase.bg})` }"
    >
      <footer>
        <img src="@/assets/img/left.svg" alt="Previous room" />
        <p>
          <span v-for="(n, i) in previousShowcase.name" :key="`s-1-n-${i}`">{{
            n
          }}</span>
        </p>
      </footer>
    </router-link>
    <router-link
      :to="`/${$route.name}/${nextShowcase.id}`"
      class="suggestion"
      :style="{ 'background-image': `url(${nextShowcase.bg})` }"
    >
      <footer>
        <p>
          <span v-for="(n, i) in nextShowcase.name" :key="`s-2-n-${i}`">{{
            n
          }}</span>
        </p>
        <img src="@/assets/img/right.svg" alt="Next room" />
      </footer>
    </router-link>
  </div>
</template>

<script>
export default {
  props: ['previousShowcase', 'nextShowcase'],
};
</script>

<style lang="scss" scoped>
@import '../../scss/variables.scss';
@import '../../scss/mixins.scss';
.showcases-suggestions {
  display: flex;
  justify-content: space-around;
  padding: 1rem 1rem 2rem;
  width: 100%;
}
.suggestion {
  align-items: flex-end;
  background-size: cover;
  background-position: center;
  display: flex;
  height: 15rem;
  padding: 0.5rem;
  width: calc(50% - 2rem);
  @include hover();
  footer {
    align-items: center;
    display: flex;
    background-color: #fff;
    color: #000;
    width: 70%;
    padding: 1rem;
    position: relative;
    @include transition($transition);
    > img {
      width: 2.5rem;
      margin: 0 1rem;
    }
    p {
      font-size: 1.4rem;
      span {
        display: block;
      }
    }
  }
  &:first-child {
    // @include transform(translateY(-0.5rem));
    footer {
      left: -1.5rem;
    }
  }
  &:last-child {
    justify-content: flex-end;
    // @include transform(translateY(0.5rem));
    footer {
      justify-content: flex-end;
      right: -1.5rem;
    }
  }
  //   &:hover {
  //     footer {
  //       left: initial;
  //       right: initial;
  //     }
  //   }
}
@media screen and (max-width: $sm) {
  .suggestion {
    footer {
      width: 80%;
      > img {
        width: 2rem;
      }
      p {
        font-size: 1.5rem;
      }
    }
  }
}
@media screen and (max-width: $s) {
  .suggestion {
    height: 20rem;
    footer {
      padding: 0.75rem;
      width: 100%;
      > img {
        margin: 0 0.5rem;
        width: 1rem;
      }
      p {
        font-size: 1rem;
      }
    }
  }
}
</style>