<template>
  <div class="showcases-grand-wrapper">
    <transition name="slide-top" mode="out-in">
      <div class="showcases-wrapper">
        <header
          :style="{
            'background-color': '#cfced3',
          }"
        >
          <aside>
            <transition name="slide" mode="out-in">
              <img
                :src="require(`@/assets/img/gallery/svg_${$i18n.locale}.svg`)"
                class="svg"
                :key="`showcases-${$route.name}-svg`"
                :alt="$t(`gallery-title-alt`)"
              />
            </transition>
          </aside>
          <div class="gallery">
            <header>
              <p>{{ $t(`gallery-select-category`) }}</p>
              <div class="gallery__categories">
                <span
                  class="category-toggle"
                  :class="{ active: !category }"
                  @click="category = ''"
                >
                  {{ $t("gallery-categories.all") }}
                </span>
                <span
                  class="category-toggle"
                  :class="{ active: category === c }"
                  v-for="(g, c) of gallery"
                  :key="'category-' + c"
                  @click="category = c"
                >
                  {{ $t(`gallery-categories.${c}`) }}
                </span>
              </div>
            </header>
            <main
              :class="{ 'open-media': open !== null }"
              ref="galleryContainer"
            >
              <div
                class="media"
                :class="{ active: open === i }"
                v-for="(m, i) in getMedia"
                :key="`media-${i}`"
                @click="openMedia(i)"
              >
                <template v-if="m.match('.(mp4|MP4)$')">
                  <video
                    :src="m"
                    :alt="`Video-${i}`"
                    muted
                    autoplay
                    :controls="open === i"
                    :style="{ top: open === i ? `${topPosition}px` : '' }"
                  />
                </template>
                <template v-else>
                  <img
                    :src="m"
                    :alt="`Image-${i}`"
                    :style="{ top: open === i ? `${topPosition}px` : '' }"
                  />
                </template>
              </div>
            </main>
          </div>
        </header>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      y: 0,
      category: "",
      gallery: [],
      open: null,
    };
  },
  computed: {
    getMedia() {
      const base = `${process.env.VUE_APP_BASE_URI}public/gallery`;
      const media = [];

      for (let g in this.gallery) {
        if (g === this.category || !this.category)
          for (let m of this.gallery[g]) {
            media.push(`${base}/${g}/${m}`);
          }
      }

      return media;
    },
    topPosition() {
      if (this.open !== null) {
        if (window.innerWidth > 768) {
          let base = this.$refs.galleryContainer.children[this.open].offsetTop;

          return this.y - base;
        }
      }
      return "";
    },
  },
  methods: {
    getGalleryData() {
      fetch(`${process.env.VUE_APP_BASE_URI}api/gallery`, {
        method: "get",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data) {
            this.gallery = data;
          }
        })
        .catch((err) => console.log(err));
    },
    openMedia(i) {
      this.open === i ? (this.open = null) : (this.open = i);
    },
    handleScroll() {
      this.y = window.scrollY;
    },
  },
  mounted() {
    this.getGalleryData();

    document.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    document.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/variables.scss";
@import "@/scss/mixins.scss";
.showcases-grand-wrapper {
  overflow: hidden;
}
.showcases-wrapper > header {
  // background-image: url("../../assets/img/showcases.jpg");
  display: flex;
  // height: 100vh;
  // max-height: 100vh;
  min-height: 100vh;
  // height: calc(var(--vh, 1vh) * 100);
  // max-height: calc(var(--vh, 1vh) * 100);
  min-height: calc(var(--vh, 1vh) * 100);
  position: relative;
  @include transition($transition);
  &::before,
  &::after {
    content: "";
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
  }

  &::before {
    background-image: url("../../assets/img/showcases.jpg");
    background-size: cover;
    filter: blur(10px);
  }
  &::after {
    background-color: rgba($color: #000, $alpha: 0.7);
  }
}
aside {
  align-items: center;
  color: #fff;
  display: flex;
  justify-content: center;
  height: 100vh;
  padding: 0;
  left: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 16%;
  z-index: 1;
  h2,
  .svg {
    height: auto;
    width: 150%;
    @include transform(rotate(-90deg));
    @include transition($transition);
    &.hidden {
      opacity: 0;
      right: 0;
    }
  }
}

.gallery {
  flex-basis: 100%;
  gap: 50px 10px;
  position: relative;
  padding-left: 16%;
  padding-top: 100px;
  padding-bottom: 10px;
  padding-right: 1rem;
  z-index: 1;

  > header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;
    p {
      color: #fff;
      font-size: 1.6rem;
      font-weight: 300;
      line-height: 1.6rem;
    }
    .category-toggle {
      background-color: rgba($color: #fff, $alpha: 0.5);
      border-radius: 6rem;
      color: #fff;
      cursor: pointer;
      display: inline-flex;
      font-size: 0.9rem;
      margin: 0.15rem;
      padding: 0.25rem 1rem;
      @include transition($transition);

      &:hover {
        background-color: #e2a151;
      }
      &.active {
        background-color: #fff;
        color: #000;
      }
    }
  }

  > main {
    display: flex;
    flex-wrap: wrap;
    margin-top: 1rem;
    position: relative;
    .media {
      cursor: pointer;
      display: inline-flex;
      flex-basis: calc(33.33% - 1rem);
      height: 15vw;
      padding: 0.5rem 0;
      position: relative;

      img,
      video {
        filter: drop-shadow(0px 0px 50px #0000008f);
        height: calc(100% - 1rem);
        position: asbolute;
        left: 0;
        top: 0.5rem;
        object-fit: cover;
        position: absolute;
        width: 100%;
      }

      &:nth-child(3n + 2) {
        flex-basis: calc(33.33% + 2rem);
        img,
        video {
          width: calc(100% - 2rem);
          left: 1rem;
        }
      }

      &.active {
        z-index: 2;
        img,
        video {
          // border-radius: 15px;
          // height: 80vh;
          height: auto;
          object-fit: contain;
          max-height: 80vh;
          width: 300%;
          z-index: 1;
        }

        &:nth-child(3n + 1) {
          img,
          video {
            left: 1rem;
            width: calc(300% + 1rem);
          }
        }
        &:nth-child(3n + 2) {
          img,
          video {
            left: 50%;
            @include transform(translateX(-50%));
            width: calc(300% - 8rem);
          }
        }
        &:nth-child(3n + 3) {
          img,
          video {
            left: calc(-200% - 2rem);
            width: calc(300% + 1rem);
          }
        }
      }
    }
  }
}

@media screen and (min-width: $s) {
  .gallery {
    > main {
      .media {
        @include transition($transition);

        img,
        video {
          @include transition(inherit);
        }

        &:hover:not(.active) {
          z-index: 1;
          img,
          video {
            @include transform(scale(1.15));
          }
        }
      }

      &:hover,
      &.open-media {
        & > *:not(:hover):not(.active) {
          opacity: 0.5;

          &:nth-child(3n + 1) {
            transform-origin: right;
            @include transform(scale(0.95));
          }
          > &:nth-child(3n + 2) {
            @include transform(scaleX(0.98) scaleY(0.95));
          }
          &:nth-child(3n + 3) {
            transform-origin: left;
            @include transform(scale(0.95));
          }
        }
      }
    }
  }
}

@media screen and (max-width: $s) {
  .showcases-grand-wrapper {
    overflow: hidden;
  }
  header {
    flex-direction: column;
  }
  aside {
    position: static;
    height: initial;
    margin-bottom: 1rem;
    padding-top: 100px;
    width: 100%;
    .svg {
      width: 50%;
      @include transform(initial);
    }
  }
  .gallery {
    padding: 0 1.5rem 1.5rem;
    gap: 1.5rem;
    flex-direction: column;
    > header {
      p {
        margin-bottom: 10px;
      }
      .gallery__categories {
        display: flex;
        justify-content: flex-start;
        overflow: auto;
        width: 100%;
        white-space: nowrap;
        @include scrollbar(5px, rgba($color: #000, $alpha: 0.5));
      }
    }
    > main {
      .media {
        height: 45vw;
        flex-basis: 100%;

        &:nth-child(3n + 2) {
          flex-basis: 100%;
          img,
          video {
            width: 100%;
            left: 0;
          }
        }

        &.active {
          align-items: center;
          background-color: rgba($color: #000, $alpha: 0.5);
          display: flex;
          height: 100%;
          justify-content: center;
          left: 0;
          padding: 1rem;
          position: fixed;
          top: 0;
          width: 100%;
          z-index: 2;
          img,
          video {
            max-height: 90vh;
            position: static;
            width: 100%;
          }

          &:nth-child(3n + 1) {
            img,
            video {
              left: 0;
              width: 100%;
            }
          }
          &:nth-child(3n + 2) {
            img,
            video {
              left: 0;
              @include transform(initial);
              width: 100%;
            }
          }
          &:nth-child(3n + 3) {
            img,
            video {
              left: 0;
              width: 100%;
            }
          }
        }
      }
    }
  }
}
</style>
