<template>
  <div
    class="showcases-inquiry"
    :class="{ 'massage-inquiry': $route.name === 'spa-services' }"
  >
    <form @submit.prevent="submit">
      <template v-if="mini">
        <div class="row">
          <InquiryInput
            class="big"
            :placeholder="$t('inquiry.name')"
            :invalid="validating && !inquiry.name"
            v-model="inquiry.name"
          />
          <InquiryInput
            :placeholder="$t('inquiry.phone')"
            v-model="inquiry.phone"
          />
          <InquiryInput
            :placeholder="$t('inquiry.email')"
            type="email"
            :invalid="validating && !inquiry.email"
            v-model="inquiry.email"
          />
        </div>
        <div class="row">
          <InquiryInput
            :placeholder="$t('inquiry.message')"
            :invalid="validating && !inquiry.message"
            v-model="inquiry.message"
          />
        </div>
      </template>

      <!-- MASSAGE INQUIRY -->
      <template v-else-if="$route.name === 'spa-services'">
        <div class="row row--3" :class="`row--${$route.name}`">
          <InquiryDropdown
            type="date"
            :placeholder="$t('form-date')"
            :disabledDates="{ to: today, from: inquiry.dateTo }"
            :invalid="validating && !inquiry.dateFrom"
            v-model="inquiry.dateFrom"
          />
          <InquiryInput
            type="time"
            min="09:00"
            max="21:00"
            :placeholder="$t('form-hour')"
            :invalid="validating && !inquiry.hour"
            v-model="inquiry.hour"
          />
          <InquiryInput
            :placeholder="$t('form-name')"
            :invalid="validating && !inquiry.name"
            v-model="inquiry.name"
          />
        </div>
        <div class="row row--4" :class="`row--${$route.name}`">
          <InquiryInput
            :placeholder="$t('form-phone')"
            :invalid="validating && !inquiry.phone"
            v-model="inquiry.phone"
          />
          <InquiryInput
            :placeholder="$t('form-email')"
            :invalid="validating && !inquiry.email"
            v-model="inquiry.email"
          />
        </div>

        <div class="row row--4" :class="`row--${$route.name}`">
          <InquiryDropdown
            :placeholder="$t('form-massage-category')"
            :invalid="validating && !inquiry.massageCategory"
            v-model="inquiry.massageCategory"
            :data="$t('massageTypes')"
          />
          <InquiryDropdown
            :placeholder="$t('form-massage-procedure')"
            :invalid="validating && !inquiry.massageProcedure"
            v-model="inquiry.massageProcedure"
            :disabled="!inquiry.massageCategory"
            :data="procedureTypes"
          />
        </div>
        <div class="row row--4" :class="`row--${$route.name}`">
          <InquiryInput
            :placeholder="$t('form-additional')"
            :invalid="validating && !inquiry.message"
            v-model="inquiry.message"
          />
        </div>
      </template>
      <!-- MASSAGE INQUIRY -->
      <template v-else>
        <div class="row row--1" :class="`row--${$route.name}`">
          <InquiryDropdown
            type="date"
            :placeholder="$t('inquiry.checkin')"
            :disabledDates="{ to: today, from: inquiry.dateTo }"
            :invalid="validating && !inquiry.dateFrom"
            v-model="inquiry.dateFrom"
          />
          <InquiryDropdown
            type="date"
            :placeholder="$t('inquiry.checkout')"
            :disabledDates="{ to: inquiry.dateFrom || today }"
            :invalid="validating && !inquiry.dateTo"
            v-model="inquiry.dateTo"
          />
          <InquiryInput
            class="big"
            :placeholder="$t('inquiry.name')"
            :invalid="validating && !inquiry.name"
            v-model="inquiry.name"
            v-if="$route.name === 'halls'"
          />
          <template v-else>
            <InquiryInput
              :placeholder="$t('inquiry.name')"
              :invalid="validating && !inquiry.name"
              v-model="inquiry.name"
            />
            <InquiryInput
              :placeholder="$t('inquiry.email')"
              type="email"
              :invalid="validating && !inquiry.email"
              v-model="inquiry.email"
            />
          </template>
        </div>
        <div
          class="row row--2"
          :class="`row--${$route.name}`"
          v-if="
            $route.name === 'rooms' ||
            $route.name === 'offers' ||
            $route.name === 'halls'
          "
        >
          <template v-if="$route.name === 'rooms' || $route.name === 'offers'">
            <InquiryDropdown
              :placeholder="$t('inquiry.mountain')"
              :disabled="!showcase.inquiry.mountain.length"
              :data="showcase.inquiry.mountain"
              :invalid="validating && !inquiry.mountain"
              v-model="inquiry.mountain"
            />
            <InquiryDropdown
              :placeholder="$t('inquiry.bedrooms')"
              :disabled="!showcase.inquiry.bedrooms.length"
              :data="showcase.inquiry.bedrooms"
              :invalid="validating && !inquiry.bedrooms"
              v-model="inquiry.bedrooms"
            />
          </template>
          <template v-if="$route.name === 'halls'">
            <InquiryDropdown
              :placeholder="$t('inquiry.event')"
              :data="$t('inquiry.eventData')"
              :invalid="validating && !inquiry.event"
              v-model="inquiry.event"
            />
            <InquiryDropdown
              :placeholder="$t('inquiry.participants')"
              :data="$t('inquiry.participantsData')"
              :invalid="validating && !inquiry.participants"
              v-model="inquiry.participants"
            />
            <InquiryInput
              :placeholder="$t('inquiry.phone')"
              v-model="inquiry.phone"
            />
            <InquiryInput
              :placeholder="$t('inquiry.email')"
              type="email"
              :invalid="validating && !inquiry.email"
              v-model="inquiry.email"
            />
          </template>
          <template v-else>
            <InquiryDropdown
              :placeholder="$t('inquiry.adults')"
              :data="['1', '2', '3', '4', '5', '6']"
              :invalid="validating && !inquiry.adults"
              v-model="inquiry.adults"
            />
            <InquiryDropdown
              :placeholder="$t('inquiry.childrenSmall')"
              :data="['0', '1', '2', '3']"
              :invalid="validating && !inquiry.childrenSmall"
              v-model="inquiry.childrenSmall"
            />
            <InquiryDropdown
              :placeholder="$t('inquiry.children')"
              :data="['0', '1', '2', '3']"
              :invalid="validating && !inquiry.children"
              v-model="inquiry.children"
            />
          </template>
        </div>
        <div class="row row--3" :class="`row--${$route.name}`">
          <InquiryInput
            :placeholder="$t('inquiry.phone')"
            v-model="inquiry.phone"
            v-if="$route.name !== 'halls'"
          />
          <InquiryInput
            :placeholder="$t('inquiry.message')"
            :invalid="validating && !inquiry.message"
            v-model="inquiry.message"
          />
        </div>

        <div class="row" v-if="$route.name !== 'spa-services'">
          <p>{{ $t("inquiry.additional") }}</p>
        </div>

        <div class="row row--check" v-if="$route.name === 'halls'">
          <InquiryCheckbox
            :placeholder="$t('inquiry.tech')"
            :img="require('@/assets/img/showcase/check_8.png')"
            v-model="inquiry.options.tech"
          />
          <InquiryCheckbox
            :placeholder="$t('inquiry.whiteboard')"
            :img="require('@/assets/img/showcase/check_9.png')"
            v-model="inquiry.options.whiteboard"
          />
          <InquiryCheckbox
            :placeholder="$t('inquiry.pa')"
            :img="require('@/assets/img/showcase/check_10.png')"
            v-model="inquiry.options.pa"
          />
          <InquiryCheckbox
            :placeholder="$t('inquiry.writing')"
            :img="require('@/assets/img/showcase/check_11.png')"
            v-model="inquiry.options.writing"
          />
          <InquiryCheckbox
            :placeholder="$t('inquiry.catering')"
            :img="require('@/assets/img/showcase/check_12.png')"
            v-model="inquiry.options.catering"
          />
          <InquiryCheckbox
            :placeholder="$t('inquiry.coffee')"
            :img="require('@/assets/img/showcase/check_13.png')"
            v-model="inquiry.options.coffee"
          />
          <InquiryCheckbox
            :placeholder="$t('inquiry.assistant')"
            :img="require('@/assets/img/showcase/check_14.png')"
            v-model="inquiry.options.assistant"
          />
        </div>
        <div
          class="row row--check"
          v-else-if="$route.name !== 'halls' && $route.name !== 'spa-services'"
        >
          <InquiryCheckbox
            :placeholder="$t('inquiry.banya')"
            :img="require('@/assets/img/showcase/check_2.png')"
            v-model="inquiry.options.banya"
          />
          <InquiryCheckbox
            :placeholder="$t('inquiry.massage')"
            :img="require('@/assets/img/showcase/check_3.png')"
            v-model="inquiry.options.massage"
          />
          <InquiryCheckbox
            :placeholder="$t('inquiry.ski')"
            :img="require('@/assets/img/showcase/check_1.png')"
            v-model="inquiry.options.ski"
          />
          <InquiryCheckbox
            :placeholder="$t('inquiry.menu')"
            :img="require('@/assets/img/showcase/check_4.png')"
            v-model="inquiry.options.menu"
          />
          <!-- <InquiryCheckbox
            :placeholder="$t('inquiry.laundry')"
            :img="require('@/assets/img/showcase/check_5.png')"
            v-model="inquiry.options.laundry"
          /> -->
          <!-- <InquiryCheckbox
            :placeholder="$t('inquiry.garage')"
            :img="require('@/assets/img/showcase/check_6.png')"
            v-model="inquiry.options.garage"
          /> -->
          <InquiryCheckbox
            :placeholder="$t('inquiry.cot')"
            :img="require('@/assets/img/showcase/check_7.png')"
            v-model="inquiry.options.cot"
          />
          <InquiryCheckbox
            :placeholder="$t('inquiry.transfer')"
            :img="require('@/assets/img/showcase/check_15.png')"
            v-model="inquiry.options.transfer"
          />
        </div>
      </template>

      <div class="checkbox-wrapper">
        <input
          class="checkbox"
          type="checkbox"
          id="inquiryTerms"
          required
          v-model="agreed"
        />
        <label class="checkbox-label" for="inquiryTerms">{{
          $t("form-terms")
        }}</label>
      </div>

      <div class="button-wrapper">
        <Button
          class="darker"
          :disabled="loading"
          :success="success"
          type="submit"
        >
          <span>
            {{ $t("inquiry.submit") }}
          </span>
        </Button>
      </div>
    </form>
  </div>
</template>

<script>
import InquiryDropdown from "./InquiryDropdown.vue";
import InquiryInput from "./InquiryInput.vue";
import InquiryCheckbox from "./InquiryCheckbox.vue";
import Button from "@/components/Button.vue";
export default {
  props: ["showcase", "mini"],
  components: {
    InquiryDropdown,
    InquiryInput,
    InquiryCheckbox,
    Button,
  },
  watch: {
    "inquiry.massageCategory"() {
      let index =
        this.$t("massageTypes").indexOf(this.inquiry.massageCategory) + 1;
      if (index) {
        this.procedureTypes = this.$t(`massageProcedures-${index}`);
        this.inquiry.massageProcedure = "";
      }
    },
  },
  data() {
    return {
      loading: false,
      success: false,
      validating: false,
      agreed: false,
      today: new Date(),
      inquiry: {
        hour: "09:00",
        massageCategory: "",
        massageProcedure: "",
        route: this.$route.name,
        dateFrom: null,
        dateTo: null,
        mountain: "",
        bedrooms: "",
        event: "",
        participants: "",
        adults: "",
        children: "",
        name: "",
        phone: "",
        email: "",
        message: "",
        options: {
          ski: false,
          banya: false,
          massage: false,
          menu: false,
          transfer: false,
          cot: false,
          tech: false,
          whiteboard: false,
          pa: false,
          writing: false,
          catering: false,
          coffee: false,
          assistant: false,
        },
      },
      procedureTypes: "",
    };
  },
  methods: {
    isValid() {
      this.validating = true;

      const i = this.inquiry;

      if (!i.name || !i.email || !i.message) return;

      if (!this.mini) {
        if (!i.dateFrom || !i.dateTo && !this.$route.name === "spa-services") return;
        if (!i.dateFrom && this.$route.name === "spa-services") return;

        if (this.$route.name === "rooms" || this.$route.name === "offers") {
          if (
            !i.mountain ||
            !i.bedrooms ||
            !i.adults ||
            !i.childrenSmall ||
            !i.children
          )
            return;
        } else if (this.$route.name === "halls") {
          if (!i.event || !i.participants) return;
        } else if (this.$route.name === "spa-services") {
          if (!i.hour || !i.massageCategory || !i.massageProcedure) return;
        }
      }

      this.validating = false;
      return true;
    },
    submit() {
      if (!this.isValid()) return;
      this.loading = true;
      this.inquiry.from = window.location.href;
      let type = "";
      
      if (this.$route.name === "spa-services") {
        type = "massage";
      } else {
        type = this.mini ? "inquiry" : "room-inquiry";
      }
      fetch(`${process.env.VUE_APP_BASE_URI}${type}`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(this.inquiry),
      })
        .then((response) => response.json())
        .then((data) => {
          this.success = data.success;
          if (data.success) {
            this.inquiry = {
              hour: "09:00",
              massageCategory: "",
              massageProcedure: "",
              route: this.$route.name,
              dateFrom: null,
              dateTo: null,
              mountain: "",
              bedrooms: "",
              event: "",
              participants: "",
              adults: "",
              children: "",
              name: "",
              phone: "",
              email: "",
              message: "",
              options: {
                ski: false,
                banya: false,
                massage: false,
                menu: false,
                laundry: false,
                garage: false,
                cot: false,
                tech: false,
                whiteboard: false,
                pa: false,
                writing: false,
                catering: false,
                coffee: false,
                assistant: false,
              },
            };
            this.agreed = false;

            setTimeout(() => {
              this.success = false;
            }, 2000);
          }
        })
        .catch((err) => console.log(err))
        .then(() => (this.loading = false));
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/variables.scss";
@import "@/scss/mixins.scss";
.showcases-inquiry {
  align-items: center;
  background-color: rgba($color: $mainColor, $alpha: 0.1);
  display: flex;
  justify-content: center;
  margin-top: 2.4rem;
  padding: 1.5rem;
  position: relative;
  z-index: 2;

  // &.massage-inquiry {
    // margin-bottom: 3rem;
    // margin-top: 0;
  // }

  .row {
    align-items: center;
    display: flex;
    width: 1270px;

    > p {
      font-size: 0.6rem;
      font-weight: 500;
      margin: 10px 0;
      text-align: center;
      width: 100%;
    }

    .input,
    .dropdown {
      flex-basis: 100%;
    }

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    @media screen and (min-width: $s) {
      &:not(.row--check) {
        gap: 10px;
      }
    }
    &.row--1 {
      > * {
        flex-basis: 310px;
        max-width: 310px;
        width: initial;
      }

      &.row--halls {
        > * {
          &:last-child {
            flex-basis: 630px;
            max-width: 630px;
          }
        }
      }
    }
    &.row--2 {
      &.row--halls {
        > * {
          flex-basis: 310px;
          max-width: 310px;
          width: initial;
        }
      }
      &:not(.row--halls) {
        > * {
          flex-basis: 310px;
          max-width: 310px;
          width: initial;

          &:nth-child(3),
          &:nth-child(4),
          &:nth-child(5) {
            flex-basis: calc(610px / 3);
            max-width: calc(610px / 3);
          }
        }
      }
    }

    &.row--3 {
      &:not(.row--halls) {
        > * {
          flex-basis: calc(310px);
          max-width: calc(310px);
          min-width: calc(310px);
          width: initial;
          &:last-child {
            flex-basis: 100%;
            max-width: initial;
          }
        }
      }
    }

    &.row--check {
      justify-content: center;
    }
  }
  .checkbox-wrapper {
    align-items: center;
    display: inline-flex;
    font-size: 1rem;
    justify-content: center;
    margin-bottom: 10px;
    width: 100%;
    @include noselect();
    .checkbox {
      margin: 0 0.5rem 0 0;
    }
  }
  .button-wrapper {
    text-align: center;
  }
}

@media screen and (max-width: $sm) {
  .showcases-inquiry {
    .row {
      padding-left: 6rem;
      padding-right: 6rem;
      max-width: 100vw;
      width: initial;

      &.row--spa-services {
        &.row--4 {
          flex-direction: column;

          > * {
            min-height: 70px;
          }
        }
        &.row--3 {
          flex-wrap: wrap;
          > * {
            flex-basis: calc(50% - 5px);
            max-width: calc(50% - 5px);
            min-width: calc(50% - 5px);
            width: initial;
            &:last-child {
              flex-basis: 100%;
              max-width: initial;
            }
          }
        }
      }

      &.row--1 {
        > * {
          flex-basis: calc(25% - 7.5px);
          max-width: calc(25% - 7.5px);
          width: initial;
        }

        &.row--halls {
          > * {
            flex-basis: calc(25% - 20px / 3);
            max-width: calc(25% - 20px / 3);
            &:last-child {
              flex-basis: calc(50% - 20px / 3);
              max-width: calc(50% - 20px / 3);
            }
          }
        }
      }
      &.row--2 {
        &.row--halls {
          > * {
            flex-basis: calc(25% - 7.5px);
            max-width: calc(25% - 7.5px);
            width: initial;
          }
        }
        &:not(.row--halls) {
          > * {
            flex-basis: calc(25% - 8px);
            max-width: calc(25% - 8px);
            width: initial;

            &:nth-child(3),
            &:nth-child(4),
            &:nth-child(5) {
              flex-basis: calc(50% / 3 - 8px);
              max-width: calc(50% / 3 - 8px);
            }
          }
        }
      }

      &.row--3 {
        &:not(.row--halls):not(.row--spa-services) {
          > * {
            flex-basis: calc(25% - 5px);
            max-width: calc(25% - 5px);
            min-width: calc(25% - 5px);
            width: initial;
            &:last-child {
              flex-basis: 100%;
              max-width: initial;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: $s) {
  .showcases-inquiry {
    .row {
      padding-left: calc(1rem - 5px);
      padding-right: calc(1rem - 5px);

      &.row--spa-services {
        justify-content: space-between;

        &:not(.row--check) > * {
          margin: 5px 0;
        }
      }

      &:not(:last-child) {
        margin-bottom: 0;
      }

      &:not(.row--check) > * {
        margin: 5px;
      }

      &.row--1 {
        flex-wrap: wrap;
        &.row--halls > *,
        & > * {
          flex-basis: calc(50% - 10px);
          max-width: calc(50% - 10px);
          &.big {
            flex-basis: 100%;
            max-width: 100%;
          }
        }
      }

      &.row--2 {
        flex-wrap: wrap;
        .dropdown,
        .input {
          flex-basis: calc(50% - 10px);
          max-width: calc(50% - 10px);
        }

        &:not(.row--halls) {
          > * {
            &:nth-child(3),
            &:nth-child(4),
            &:nth-child(5) {
              flex-basis: calc(100% / 3 - 10px);
              max-width: calc(100% / 3 - 10px);
            }
          }
        }
      }

      &.row--check {
        justify-content: flex-start;
        margin-bottom: 10px;
        overflow: auto;
        padding-left: 1rem;
        padding-right: 1rem;
      }

      > p {
        font-size: 0.9rem;
      }
    }
  }
}
</style>