<template>
  <label
    class="input"
    :class="{ value: value || focus, 'input--invalid': invalid }"
    :for="`input-${_uid}`"
  >
    <p>{{ placeholder }}</p>
    <input
      :id="`input-${_uid}`"
      :type="type || 'text'"
      :value="value"
      :min="min"
      :max="max"
      :required="required"
      @focus="focus = true"
      @blur="focus = false"
      @input="$emit('input', $event.target.value)"
    />
  </label>
</template>

<script>
export default {
  props: ["type", "value", "placeholder", "required", "invalid", "min", "max"],
  data() {
    return {
      focus: false,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/variables.scss";
@import "@/scss/mixins.scss";
.input {
  background-color: rgba($color: $mainColor, $alpha: 0.1);
  border: 1px solid rgba($color: $mainColor, $alpha: 0.66);
  cursor: text;
  display: flex;
  flex-direction: column;
  height: 70px;
  justify-content: center;
  position: relative;
  width: 100%;
  @include noselect();
  input {
    background-color: transparent;
    border: none;
    font-size: 1.3rem;
    height: 1.3rem;
    margin-top: 0.6rem;
    outline: none;
    padding: 0 1rem;
    width: 100%;
  }

  p {
    font-size: 0.6rem;
    left: 1rem;
    position: absolute;
    text-transform: uppercase;
    top: 50%;
    @include transform(translateY(-50%));
    @include transition($transition);
  }

  &:focus,
  &.value {
    p {
      top: 10px;
      @include transform(initial);
    }
  }
  &.input--invalid {
    background-color: rgba($color: $mainError, $alpha: 0.1);
    border-color: $mainError;
  }
}

@media screen and (max-width: $s) {
  .input {
    height: 50px;
    p {
      font-size: 0.9rem;
    }
  }
}
</style>