<template>
  <div class="inner-page">
    <header class="inner-page__header">
      <aside class="inner-page__aside">
        <transition name="slide" mode="out-in">
          <img
            :src="require(`@/assets/img/aquapark/svg_${$i18n.locale}.svg`)"
            :alt="$t('aquapark-alt')"
            class="svg"
          />
        </transition>
        <p>
          <img src="@/assets/img/scroll_h.svg" class="scroll-h" />
          {{ $t('aquapark-p') }}
        </p>
      </aside>
      <div class="inner-page__img"></div>
    </header>
    <main class="inner-page__main">
      <About />
      <!-- SECTION 1 -->
      <section class="inner-page__section">
        <Slideshow
          :reversed="true"
          :images="[
            require('@/assets/img/aquapark/s1-1.jpg'),
            require('@/assets/img/aquapark/s1-2.jpg'),
            require('@/assets/img/aquapark/s1-3.jpg'),
          ]"
        />
        <div class="inner-page__texts">
          <h2>{{ $t('aquapark-sc1-ttl') }}</h2>
          <p class="sttl">{{ $t('aquapark-sc1-sttl') }}</p>
          <p v-html="$t('aquapark-sc1-txt')"></p>
          <p v-html="$t('aquapark-sc1-txt4')"></p>
          <p v-html="$t('aquapark-sc1-txt1')"></p>
          <p v-html="$t('aquapark-sc1-txt2')"></p>
        </div>
      </section>
      <!-- SECTION 2 -->
      <section
        class="inner-page__section inner-page__section section--reversed section--top"
      >
        <div class="inner-page__texts">
          <h2>{{ $t('aquapark-sc2-ttl') }}</h2>
          <p class="sttl">{{ $t('aquapark-sc2-sttl') }}</p>
          <p v-html="$t('aquapark-sc2-txt')"></p>
          <p v-html="$t('aquapark-sc1-txt2')"></p>
          <!-- <p v-html="$t('aquapark-sc1-txt3')"></p> -->
        </div>
        <Slideshow
          :images="[
            require('@/assets/img/aquapark/s2-1.jpg'),
            require('@/assets/img/aquapark/s2-2.jpg'),
            require('@/assets/img/aquapark/s2-3.jpg'),
          ]"
        />
      </section>
      <!-- SECTION 3 -->
      <!-- <section class="inner-page__section">
        <Slideshow
          :reversed="true"
          :images="[
            require('@/assets/img/aquapark/s3-1.jpg'),
            require('@/assets/img/aquapark/s3-2.jpg'),
            require('@/assets/img/aquapark/s3-3.jpg'),
          ]"
        />
        <div class="inner-page__texts">
          <h2>{{ $t('aquapark-sc3-ttl') }}</h2>
          <p class="sttl">{{ $t('aquapark-sc3-sttl') }}</p>
          <p v-html="$t('aquapark-sc3-txt')"></p>
          <p v-html="$t('aquapark-sc3-txt1')"></p>
          <p v-html="$t('aquapark-sc3-txt2')"></p>
        </div>
      </section> -->
    </main>
  </div>
</template>

<script>
import About from './About';
import Slideshow from '@/components/Slideshow';
export default {
  components: {
    About,
    Slideshow,
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/inner_page.scss';
.inner-page__img {
  background-image: url('../../assets/img/aquapark/bg.jpg');
}

.inner-page__main {
  background-image: url('../../assets/img/bg.jpg');
}

.inner-page__header {
  background-color: #e8bf21;
}
</style>
