<template>
  <div
    class="slideshow"
    :class="{
      'slideshow--reversed': reversed,
      'slideshow--semi': semi,
     }"
    v-observe-visibility="{ callback: visibilityChanged, throttle: 2000, }"
  >
    <div class="img" :class="{ 'img--visible': isImageVisible }">
      <img v-for="(img, i) of images" :key="`image-${i}`" :src="img" />
    </div>
  </div>
</template>

<script>
export default {
  props: ['images', 'reversed', 'semi'],
  data() {
    return {
      isImageVisible: false,
    };
  },
  methods: {
    visibilityChanged(v) {
      this.isImageVisible = v;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss';
@import '@/scss/mixins.scss';
.slideshow {
  overflow: hidden;
  height: 100%;
  width: 100%;
  .img {
    height: 100%;
    display: flex;
    min-width: 100%;
    width: 100%;
    @include transition(all 6s ease);
    img {
      display: block;
      height: 100%;
      min-width: 100%;
      object-fit: cover;
      object-position: center;
      width: 100%;
      &:not(:first-child) {
        margin-left: 1rem;
      }
    }
    &.img--visible {
      @include transform(translateX(calc(-200% - 2rem)));
    }
  }

  &.slideshow--reversed {
    .img {
      flex-direction: row-reverse;
      img {
        &:not(:first-child) {
          margin-left: 0;
          margin-right: 1rem;
        }
      }
      &.img--visible {
        @include transform(translateX(calc(200% + 2rem)));
      }
    }
  }

  &.slideshow--semi {
    .img {
      min-width: 60%;
      width: 60%;
      &.img--visible {
        @include transform(translateX(calc(-133.5% - 2rem)));
      }
    }
  }
}
</style>