<template>
  <label class="inquiry-checkbox-wrapper" :for="`checkbox-${_uid}`">
    <img :src="img" :alt="$t('alt.checkbox')" />
    <input
      :id="`checkbox-${_uid}`"
      type="checkbox"
      :checked="value"
      @input="$emit('input', $event.target.value)"
    />
    <span class="checkmark"></span>
    <p>{{ placeholder }}</p>
  </label>
</template>

<script>
export default {
  props: ["value", "placeholder", "img"],
  data() {
    return {
      focus: false,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/variables.scss";
@import "@/scss/mixins.scss";
.inquiry-checkbox-wrapper {
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  overflow: hidden;
  justify-content: center;
  // height: 18px;
  position: relative;
  @include noselect();

  img {
    display: block;
    width: 181px;
    height: auto;
    @include transform(scale(1.01));
    @include transition($transition);
  }

  input {
    cursor: pointer;
    height: 0;
    opacity: 0;
    position: absolute;
    width: 0;
  }

  p {
    bottom: 5px;
    color: #fff;
    position: absolute;
    width: 100%;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1rem;
    padding-left: 28px;
    z-index: 1;
    a {
      @include hover();
    }
  }

  &:hover {
    img {
      @include transform(scale(1.1));
    }
  }
}

/* Create a custom checkbox */
.checkmark {
  background-color: transparent;
  border-radius: 2px;
  border: 2px solid #fff;
  height: 18px;
  left: 5px;
  position: absolute;
  bottom: 5px;
  @include transition($transition);
  width: 18px;
  z-index: 1;
}

.inquiry-checkbox-wrapper:hover input:not(:disabled):not(:checked) ~ .checkmark {
  background-color: rgba($color: #fff, $alpha: 0.1);
  border: 2px solid #fff;
}

.inquiry-checkbox-wrapper input:checked ~ .checkmark {
  // background-color: $mainAccent;
  // border: 2px solid $mainAccent;
}

.checkmark:after {
  content: "";
  display: none;
  position: absolute;
}

.inquiry-checkbox-wrapper input:checked ~ .checkmark:after {
  display: block;
}

.inquiry-checkbox-wrapper .checkmark:after {
  border: solid #fff;
  border-width: 0 2px 2px 0;
  height: 10px;
  left: 4px;
  top: 1px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  width: 6px;
}

.checkbox-container--disabled {
  .inquiry-checkbox-wrapper {
    cursor: default;
    input {
      cursor: default;
    }
  }
}

@media screen and (max-width: $s) {
  .inquiry-checkbox-wrapper {
    min-width: 181px;
  }
}
</style>