<template>
  <footer class="footer" :class="{ 'footer--hidden': hidden }">
    <div class="logo-wrapper">
      <img src="@/assets/img/logo.svg" alt="Regnum Bansko" />
      <p> {{ $t("footer-6") }} </p><br>
      <img src="@/assets/img/footer/payment.svg" alt="">
      <p>&copy; {{ today.getFullYear() }} - {{ $t("footer-1") }}</p>
      <a class="cdots" href="http://cdots.bg/" target="_blank">{{
        $t("footer-cdots")
      }}</a>
    </div>
    <main>
      <section>
        <div class="badges">
          <a
            href="https://www.booking.com/hotel/bg/regnum-bansko-aparthotel-and-spa.en-gb.html"
            target="_blank"
          >
            <img src="@/assets/img/footer/booking.svg" alt="Booking" />
          </a>
          <a
            href="https://www.tripadvisor.com/Hotel_Review-g318870-d2486709-Reviews-Regnum_Bansko-Bansko_Blagoevgrad_Province.html"
            target="_blank"
          >
            <img src="@/assets/img/footer/ta.svg" alt="Travelers' Choice" />
          </a>
          <div class="social">
            <a href="https://www.facebook.com/regnumbansko/" target="_blank">
              <img src="@/assets/img/footer/fb.svg" alt="Facebook" />
            </a>
            <a href="https://www.instagram.com/regnumbansko/" target="_blank">
              <img src="@/assets/img/footer/ig.svg" alt="Instagram" />
            </a>
            <a href="https://twitter.com/RegnumBansko" target="_blank">
              <img src="@/assets/img/footer/tw.svg" alt="Twitter" />
            </a>
          </div>
        </div>
        <ul>
          <li>
            <p>
              <a href="tel:+359884493924">+359 884 493 924</a>
            </p>
            <p>{{ $t("footer-2") }}</p>
            <img class="icon" src="@/assets/img/footer/phone.svg" />
          </li>
          <li>
            <p>
              <a href="tel:+359749830240">+359 749 83 024</a>
            </p>
            <p>{{ $t("footer-3") }}</p>
            <img class="icon" src="@/assets/img/footer/phone.svg" />
          </li>
          <li>
            <p>
              <a href="tel:+35974984000">+359 749 84 000</a>
            </p>
            <p>{{ $t("footer-4") }}</p>
            <img class="icon" src="@/assets/img/footer/phone.svg" />
          </li>
          <li>
            <p>
              <a href="mailto:regnum@regnum.bg">regnum@regnum.bg</a>
            </p>
            <p>{{ $t("footer-5") }}</p>
            <img class="icon" src="@/assets/img/footer/mail.svg" />
          </li>
          <li>
            <p>
              <a href="viber://chat/?number=%2B359884493924" target="_blank"
                >+359 884 49 39 24</a
              >
            </p>
            <p>Viber</p>
            <img class="icon" src="@/assets/img/footer/phone.svg" />
          </li>
          <li>
            <p>
              <a href="https://wa.me/359884493924" target="_blank"
                >+359 884 49 39 24</a
              >
            </p>
            <p>WhatsApp</p>
            <img class="icon" src="@/assets/img/footer/phone.svg" />
          </li>
        </ul>
      </section>
      <section class="links">
        <router-link to="/rooms">
          <img
            :src="require(`@/assets/img/footer/f1_${$i18n.locale}.svg`)"
            :alt="$t('footer-rooms')"
          />
        </router-link>
        <router-link to="/halls">
          <img
            :src="require(`@/assets/img/footer/f2_${$i18n.locale}.svg`)"
            :alt="$t('footer-halls')"
          />
        </router-link>
        <router-link to="/restaurant">
          <img
            :src="require(`@/assets/img/footer/f3_${$i18n.locale}.svg`)"
            :alt="$t('footer-restaurant')"
          />
        </router-link>
        <router-link to="/services">
          <img
            :src="require(`@/assets/img/footer/f4_${$i18n.locale}.svg`)"
            :alt="$t('footer-services')"
          />
        </router-link>
        <router-link to="/spa">
          <img
            :src="require(`@/assets/img/footer/f5_${$i18n.locale}.svg`)"
            :alt="$t('footer-spa')"
          />
        </router-link>
        <router-link to="/contacts">
          <img
            :src="require(`@/assets/img/footer/f6_${$i18n.locale}.svg`)"
            :alt="$t('footer-contacts')"
          />
        </router-link>
        <router-link to="/news">
          <img
            :src="require(`@/assets/img/footer/f7_${$i18n.locale}.svg`)"
            :alt="$t('footer-news')"
          />
        </router-link>
        <router-link to="/aem">
          <img
            :src="require(`@/assets/img/footer/f9_${$i18n.locale}.svg`)"
            :alt="$t('footer-terms')"
          />
        </router-link>
      </section>
      <img class="r" src="@/assets/img/footer/r.svg" />
    </main>
  </footer>
</template>

<script>
export default {
  computed: {
    hidden() {
      return (
        !this.$route.params.id &&
        (this.$route.name === "rooms" ||
          this.$route.name === "halls" ||
          this.$route.name === "news" ||
          this.$route.name === "services" ||
          this.$route.name === "offers" ||
          this.$route.name === "spa" ||
          this.$route.name === "menus" ||
          this.$route.name === "gallery" ||
          this.$route.name === "404")
      );
    },
  },
  data() {
    return {
      today: new Date(),
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/variables.scss";
@import "@/scss/mixins.scss";
.footer {
  background: rgb(0, 63, 82);
  background: linear-gradient(
    0deg,
    rgba(0, 63, 82, 1) 0%,
    rgba(0, 0, 0, 1) 100%
  );
  color: #fff;
  display: flex;
  position: relative;
  &.footer--hidden {
    display: none;
  }
}
.logo-wrapper {
  background: rgb(0, 63, 82);
  background: linear-gradient(
    180deg,
    rgba(0, 63, 82, 1) 0%,
    rgba(0, 0, 0, 1) 100%
  );
  padding: 7rem 4vw;
  width: 25vw;
  img {
    margin-bottom: 3rem;
    width: 100%;
  }
  p {
    text-align: center;
    white-space: nowrap;
  }
}

main {
  align-items: center;
  display: flex;
  padding: 2rem 5rem 2rem 2rem;
  justify-content: space-between;
  overflow: hidden;
  position: relative;
  width: 75vw;
  section {
    align-items: center;
    display: flex;
    &:first-of-type {
      width: 55%;
    }
    ul {
      position: relative;
      z-index: 3;
      li {
        margin: 1.5rem 0;
        position: relative;
        a {
          color: #fff;
          @include hover();
        }
        p {
          font-size: 0.8rem;
          &:first-child {
            font-size: 1.2rem;
            font-weight: 700;
          }
        }
        .icon {
          position: absolute;
          top: -0.5rem;
        }
      }
    }
  }
}

.badges {
  display: flex;
  flex-direction: column;
  margin-right: 5rem;
  width: 7.2rem;

  > * {
    width: 100%;
    img {
      width: 1.7rem;
    }
    &:not(:last-child) {
      margin-bottom: 0.5rem;
      img {
        width: 100%;
      }
    }
  }
}

.social {
  display: flex;
  justify-content: space-between;
}

.links {
  flex-wrap: wrap;
  position: relative;
  width: 45%;
  z-index: 1;
  a {
    display: inline-block;
    width: 50%;
    @include hover();
    img {
      display: block;
      width: 11rem;
    }
  }
}

.r {
  height: 120%;
  right: 0;
  position: absolute;
}

.cdots {
  bottom: 10px;
  color: #fff;
  font-size: 10px;
  left: 50%;
  position: absolute;
  text-transform: uppercase;
  @include transform(translateX(-50%));
  @include hover();
  z-index: 1;
}

@media screen and (max-width: $sm) {
  .logo-wrapper {
    p {
      white-space: initial;
    }
  }
  .links {
    padding-left: 5%;
    a {
      img {
        width: 8rem;
      }
    }
  }
  .cdots {
    font-size: 10px;
  }
}
@media screen and (max-width: $s) {
  .footer {
    flex-direction: column-reverse;
  }
  main {
    flex-direction: column;
    overflow: initial;
    padding: 2rem;
    position: relative;
    width: 100%;
    z-index: 2;
    section:first-of-type {
      width: 100%;
    }
  }
  .links {
    margin-top: 1rem;
    padding: 0;
    width: 100%;
    z-index: 3;
    a {
      img {
        width: 10rem;
      }
    }
  }
  .logo-wrapper {
    padding: 2rem 5rem 8rem;
    text-align: center;
    width: 100%;
  }
  .cdots {
    display: block;
    margin-top: 1rem;
    position: initial;
    left: 0;
    @include transform(initial);
  }
  .r {
    height: initial;
    right: 0;
    bottom: -10%;
    width: 100%;
    z-index: 2;
  }
}
</style>