<template>
  <div
    class="reservation-modal"
    v-observe-visibility="{ callback: visibilityChanged, throttle: 300 }"
  >
    <transition name="slide-in">
      <div class="modal" v-if="visible">
        <div class="modal__header">
          <img class="logo" src="@/assets/img/logo.svg" alt="Regnum Bansko" />
          <p>{{ $t("reservation") }}</p>
          <div class="close-wrapper">
            <img src="@/assets/img/close.svg" @click="$emit('close')" />
          </div>
        </div>
        <div class="modal__content">
          <iframe :src="clock" frameborder="0"></iframe>
        </div>
      </div>
    </transition>
  </div>
</template>

<!-- booking engine script  -->
<script src='https://www.thehotelsnetwork.com/js/loader.js?property_id=1037631&account_key=0FD89588406FF92A0B67BF82AE7D154C' async></script>

<script>
export default {
  props: ["room"],
  computed: {
    clock() {
      // // let url = `https://sky-eu1.clock-software.com/81268/11977/wbe/${this.$i18n.locale}/products/new`; // old version
      let url = `https://sky-eu1.clock-software.com/109234/13943/wbe/${this.$i18n.locale}/products/new`;

      // url += `?wbe_product[arrival]=${this.formatDate(this.search.from)}`;
      // url += `&wbe_product[nights]=${this.dayDifference(
      //   this.search.from,
      //   this.search.to
      // )}`;

      // url += `&wbe_product[adult_count]=${this.search.adults}`;
      // url += `&wbe_product[children_count]=${this.search.kids}`;

      // if (this.room.id) {
      //   url += `&wbe_product[room_type_id][]=${this.room.id}`;
      // }

      return url;
    },
  },
  data() {
    return {
      visible: false,
      search: {
        from: new Date(),
        to: undefined,
        adults: 2,
        kids: 0,
      },
    };
  },
  methods: {
    formatDate(dt) {
      const d = new Date(dt);

      return `${d.getFullYear()}-${("0" + (d.getMonth() + 1)).slice(
        -2
      )}-${d.getDate()}`;
    },
    dayDifference(d1, d2) {
      const oneDay = 24 * 60 * 60 * 1000;
      const firstDate = new Date(d1);
      const secondDate = new Date(d2);

      return Math.round(Math.abs((firstDate - secondDate) / oneDay));
    },
    visibilityChanged(v) {
      this.visible = v;
    },
  },
  mounted() {
    document.body.classList.add("stop-scrolling");
  },
  destroyed() {
    document.body.classList.remove("stop-scrolling");
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/variables.scss";
@import "../scss/mixins.scss";
.reservation-modal {
  background-color: #00000069;
  height: 100%;
  left: 0;
  padding: 2rem;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
  .modal {
    background-color: rgba($color: $mainColor, $alpha: 0.8);
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 1rem;
    width: 100%;
    .modal__header {
      align-items: center;
      color: #fff;
      display: flex;
      justify-content: space-between;
      margin-bottom: 1rem;
      .logo,
      .close-wrapper {
        width: 7.3rem;
      }
      > p {
        font-weight: 500;
        text-transform: uppercase;
      }
      .close-wrapper {
        display: flex;
        justify-content: flex-end;
        img {
          @include hover();
          margin-right: 1rem;
        }
      }
    }
    .modal__content {
      background-color: #fff;
      height: 100%;
      iframe {
        height: 100%;
        width: 100%;
      }
    }
  }
}

.slide-in-enter-active,
.slide-in-leave-active {
  @include transition($transition);
  transform-origin: bottom;
}
.slide-in-enter,
.slide-in-leave-to {
  opacity: 0;
  @include transform(translateY(-5%));
}
</style>