<template>
  <div class="showcase">
    <header>
      <video alt="Background" autoplay muted loop ref="video">
        <source src="@/assets/img/nye/nye-b.mp4" />
        Sorry, your browser doesn't support embedded videos.
      </video>
      <div>
        <div class="image">
          <span class="close" @click="back">
            <img src="@/assets/img/showcase/back.svg" alt="Back" />
          </span>
          <img :src="require(`@/assets/img/nye/nye_${$i18n.locale}.svg`)" />
        </div>
        <footer class="desktop">
          <div class="options">
            <div class="option">
              <img src="@/assets/img/nye/fireworks.svg" />
              <p>{{ $t("nye.c.o1") }}</p>
            </div>
            <div class="option">
              <img src="@/assets/img/nye/dinner.svg" />
              <p>{{ $t("nye.c.o2") }}</p>
            </div>
          </div>
          <span></span>
          <div class="buttons">
            <ButtonSmaller @click="scrollTo('programme')">{{
              $t("nye.programme")
            }}</ButtonSmaller>
            <ButtonSmaller @click="scrollTo('dinner')">{{
              $t("nye.dinner")
            }}</ButtonSmaller>
          </div>
        </footer>
        <footer class="mobile">
          <div>
            <span class="close" @click="back">
              <img src="@/assets/img/showcase/back.svg" alt="Back" />
            </span>
          </div>
          <div class="mini-buttons">
            <img :src="require(`@/assets/img/showcase/info_${$i18n.locale}.svg`)" alt="" @click="scrollTo('info')" />
            <img :src="require(`@/assets/img/showcase/inq_${$i18n.locale}.svg`)" alt="" @click="scrollTo('inquiry')" />
            <img :src="require(`@/assets/img/showcase/glr_${$i18n.locale}.svg`)" alt="" @click="scrollTo('gallery')" />
          </div>
          <div></div>
        </footer>
      </div>
    </header>
    <div class="showcase-content">
      <div class="showcase-about">
        <div class="txts">
          <h2>{{ $t("nye.c.title") }}</h2>
          <p class="sttl">{{ $t("nye.c.subtitle") }}</p>
        </div>
        <div class="img-and-features">
          <!-- <p class="mini-title">{{ $t("nye.c.prices-1") }}</p> -->
          <div class="image">
            <img
              :src="
                require(`@/assets/img/nye/price_${$i18n.locale}.svg`)
              "
              alt=""
            />
          </div>
        </div>
      </div>
      <p class="mini-title" ref="programme">{{ $t("nye.c.programme-r-0-h") }}</p>
      <div class="showcase-about showcase-about--strech">
        <div class="txts">
          <p>
            <strong>{{ $t("nye.c.programme-b-0-h") }}</strong>
          </p>
          <p>
            <strong>{{ $t("nye.c.programme-b-1-h") }} - </strong>
            {{ $t("nye.c.programme-b-1") }}
          </p>
          <p>
            <strong>{{ $t("nye.c.programme-b-2-h") }} - </strong>
            {{ $t("nye.c.programme-b-2") }}
          </p>
          <p>
            <strong>{{ $t("nye.c.programme-b-3-h") }} - </strong>
            {{ $t("nye.c.programme-b-3") }}
          </p>
          <p>
            <strong>{{ $t("nye.c.programme-b-4-h") }} - </strong>
            {{ $t("nye.c.programme-b-4") }}
          </p>
          <p>
            <strong>{{ $t("nye.c.programme-b-5-h") }} - </strong>
            {{ $t("nye.c.programme-b-5") }}
          </p>
          <p>
            <strong>{{ $t("nye.c.programme-b-6-h") }} - </strong>
            {{ $t("nye.c.programme-b-6") }}
          </p>
          <p>
            <strong>{{ $t("nye.c.programme-b-7-h") }} - </strong>
            {{ $t("nye.c.programme-b-7") }}
          </p>
          <p>
            <strong>{{ $t("nye.c.programme-b-8-h") }} - </strong>
            {{ $t("nye.c.programme-b-8") }}
          </p>
          <p>
            <strong>{{ $t("nye.c.programme-b-9-h") }} - </strong>
            {{ $t("nye.c.programme-b-9") }}
          </p>
          <p>
            <strong>{{ $t("nye.c.programme-b-10-h") }} - </strong>
            {{ $t("nye.c.programme-b-10") }}
          </p>
          <p>
            <strong>{{ $t("nye.c.programme-b-11-h") }} - </strong>
            {{ $t("nye.c.programme-b-11") }}
          </p>
        </div>
        <div class="img-and-features">
          <NewYearCarousel :images="[
            require('@/assets/img/nye/program-2024/1.jpg'),
            require('@/assets/img/nye/program-2024/2.jpg'),
            require('@/assets/img/nye/program-2024/3.jpg'),
            require('@/assets/img/nye/program-2024/5.jpg'),
            require('@/assets/img/nye/program-2024/6.jpg'),
          ]" />
        </div>
      </div>
      <p class="mini-title" ref="dinner">{{ $t("nye.c.programme-r-0-h") }}</p>
      <div class="showcase-about showcase-about--strech">
        <div class="img-and-features">
          <NewYearCarousel :images="[
            require('@/assets/img/nye/program-2024/6.jpg'),
            require('@/assets/img/nye/program-2024/5.jpg'),
            require('@/assets/img/nye/program-2024/3.jpg'),
            require('@/assets/img/nye/program-2024/2.jpg'),
            require('@/assets/img/nye/program-2024/1.jpg'),
          ]" />
        </div>
        <div class="txts">
      <p>
        <strong>{{ $t("nye.c.programme-r-0-h") }}</strong>
      </p>
      <p>
        <strong>{{ $t("nye.c.programme-r-1-h") }} - </strong>
        {{ $t("nye.c.programme-r-1") }}
      </p>
      <p>
        <strong>{{ $t("nye.c.programme-r-2-h") }} - </strong>
        {{ $t("nye.c.programme-r-2") }}
      </p>
      <p>
        <strong>{{ $t("nye.c.programme-r-3-h") }} - </strong>
        {{ $t("nye.c.programme-r-3") }}
      </p>
      <p>
        <strong>{{ $t("nye.c.programme-r-4-h") }} - </strong>
        {{ $t("nye.c.programme-r-4") }}
      </p>
      <p>
        <strong>{{ $t("nye.c.programme-r-5-h") }} - </strong>
        {{ $t("nye.c.programme-r-5") }}
      </p>
      <p>
        <strong>{{ $t("nye.c.programme-r-6-h") }} - </strong>
        {{ $t("nye.c.programme-r-6") }}
      </p>
      <p>
        <strong>{{ $t("nye.c.programme-r-7-h") }} - </strong>
        {{ $t("nye.c.programme-r-7") }}
      </p>
      <p>
        <strong>{{ $t("nye.c.programme-r-8-h") }} - </strong>
        {{ $t("nye.c.programme-r-8") }}
      </p>
      <p>
        <strong>{{ $t("nye.c.programme-r-9-h") }} - </strong>
        {{ $t("nye.c.programme-r-9") }}
      </p>
      <p>
        <strong>{{ $t("nye.c.programme-r-10-h") }} - </strong>
        {{ $t("nye.c.programme-r-10") }}
      </p>
    </div>
      </div>
      <p class="mini-title">{{ $t(`nye-inquiry`) }}</p>
      <Inquiry mini="true" ref="inquiry" />

      <!-- <p class="last">
        <span>{{ $t("nye.c.last-1") }}</span>
        <span>{{ $t("nye.c.last-2") }}</span>
      </p> -->
    </div>
  </div>
</template>

<script>
import ButtonSmaller from "@/components/ButtonSmaller";
import NewYearCarousel from "./NewYearCarousel";
import Inquiry from "./Inquiry";
export default {
  components: {
    ButtonSmaller,
    NewYearCarousel,
    Inquiry,
  },
  props: ["showcase", "previousShowcase", "nextShowcase"],
  computed: {
    show() {
      return this.$route.name === "rooms" || this.$route.name === "halls";
    },
  },
  methods: {
    back() {
      this.$router.push("/");
    },
    scrollTo(str) {
      const el = this.$refs[str];

      if (el) {
        el.scrollIntoView({ behavior: "smooth" });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/showcase.scss";
</style>
