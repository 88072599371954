<template>
  <div class="gdpr-wrapper">
    <transition name="slide">
      <div class="gdpr" v-if="show">
        <p>
          {{ $t('gdpr-1') }}
          <router-link to="/terms">{{ $t('gdpr-2') }}</router-link>
        </p>
        <button class="accept" @click="accept">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17.997"
            height="12.001"
            viewBox="0 0 17.997 12.001"
          >
            <path
              id="Path_102"
              data-name="Path 102"
              d="M434.21,2487.387l.083-.094a1,1,0,0,1,1.32-.083l.094.083,5,5a1,1,0,0,1,.083,1.319l-.083.095-5,5a1,1,0,0,1-1.5-1.319l.083-.094,3.289-3.295H424a1,1,0,0,1-.993-.883L423,2493a1,1,0,0,1,.883-.993L424,2492h13.584l-3.291-3.293a1,1,0,0,1-.083-1.32l.083-.094Z"
              transform="translate(-423 -2487)"
              fill="#003f52"
            />
          </svg>
          {{ $t('accept') }}
        </button>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false,
    };
  },
  methods: {
    accept() {
      localStorage.setItem('termsAccepted', true);
      this.show = false;
    },
  },
  mounted() {
    setTimeout(() => {
      const accepted = localStorage.getItem('termsAccepted');
      this.show = !accepted;
    }, 5000);
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss';
@import '@/scss/mixins.scss';
.gdpr-wrapper {
  position: relative;
  z-index: 2147483647;
}
.gdpr {
  align-items: flex-end;
  background-color: #fff;
  bottom: 1rem;
  box-shadow: 0px 38px 99px #0046be33;
  display: flex;
  flex-direction: column;
  left: 50%;
  position: fixed;
  width: 414px;
  z-index: 2147483647;
  @include transform(translateX(-50%));
  p {
    padding: 1.5rem;
    text-align: center;
    width: 100%;
    a {
      color: #000;
      font-weight: 400;
      display: block;
      margin-top: 1rem;
      text-decoration: underline;
      &:hover {
        opacity: 0.8;
      }
    }
  }
}

.accept {
  align-items: center;
  background-color: #fff;
  border: 0;
  box-shadow: 0px 0px 99px #003f5266;
  color: $mainColor;
  cursor: pointer;
  display: inline-flex;
  font-size: 0.95rem;
  font-weight: 500;
  height: 4rem;
  outline: none;
  overflow: hidden;
  padding: 0 3rem 0 1rem;
  position: relative;
  text-transform: uppercase;
  @include transition($transition);

  > svg {
    margin-right: 0.5rem;
    width: 0.9rem;
    path {
      @include transition($transition);
    }
  }

  &:hover {
    background-color: $mainColor;
    color: #fff;
    > svg {
      path {
        fill: #fff;
      }
    }
  }
}

.slide-enter-active,
.slide-leave-active {
  @include transition($transition);
}
.slide-enter,
.slide-leave-to {
  @include transform(translateY(100%) translateX(-50%));
  opacity: 0;
}

@media screen and (max-width: $xs) {
  .gdpr {
    width: calc(100% - 2rem);
  }
}
</style>