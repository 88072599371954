<template>
  <form class="contacts__form" @submit.prevent="submit">
    <input
      type="text"
      :placeholder="$t('form-name')"
      v-model="inquiry.name"
      required
    />
    <input
      type="email"
      :placeholder="$t('form-email')"
      v-model="inquiry.email"
      required
    />
    <input
      v-if="$route.name === 'offers'"
      type="text"
      :placeholder="$t('form-phone')"
      v-model="inquiry.phone"
      required
    />
    <textarea
      cols="30"
      rows="10"
      :placeholder="$t('form-message')"
      v-model="inquiry.message"
      required
    ></textarea>
    <div class="contacts__form__footer">
      <div class="checkbox-wrapper">
        <input type="checkbox" id="terms" required v-model="agreed" />
        <label for="terms">{{ $t("form-terms") }}</label>
      </div>
      <Button
        class="darker"
        :disabled="loading"
        :success="success"
        @clear-success="success = false"
        type="submit"
      >
        <span>
          {{ $t("form-submit") }}
        </span>
      </Button>
    </div>
  </form>
</template>

<script>
import Button from "@/components/Button";
export default {
  components: {
    Button,
  },
  data() {
    return {
      inquiry: {
        name: "",
        email: "",
        phone: "",
        message: "",
      },
      agreed: false,
      success: false,
      loading: false,
    };
  },
  methods: {
    submit() {
      this.loading = true;
      this.inquiry.from = window.location.href;
      fetch(`${process.env.VUE_APP_BASE_URI}inquiry`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(this.inquiry),
      })
        .then((response) => response.json())
        .then((data) => {
          this.success = data.success;
          if (data.success) {
            this.inquiry = {
              name: "",
              email: "",
              phone: "",
              message: "",
            };
            this.agreed = false;
          }
        })
        .catch((err) => console.log(err))
        .then(() => (this.loading = false));
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/inner_page.scss";
@import "@/scss/transition.scss";

.contacts__form {
  display: flex;
  flex-direction: column;
  margin: 3rem -1.35rem;
  width: calc(100% + 2.7rem);
  input,
  textarea {
    background-color: rgba($color: #b7d1e2, $alpha: 0.5);
    border: none;
    outline: none;
    padding: 0.65rem 1.35rem;
  }
  input {
    margin-bottom: 0.5rem;
  }
  textarea {
    resize: none;
    height: 8rem;
  }
}

.contacts__form__footer {
  display: flex;
  justify-content: space-between;
  padding-left: 1.35rem;
}

.checkbox-wrapper {
  align-items: center;
  display: inline-flex;
  font-size: 1rem;
  @include noselect();
  input {
    margin: 0 0.5rem 0 0;
  }
}

.submit {
  align-items: center;
  background-color: #fff;
  border: 0;
  box-shadow: 0px 0px 99px #003f5266;
  color: $mainColor;
  cursor: pointer;
  display: inline-flex;
  font-size: 0.95rem;
  font-weight: 500;
  height: 4rem;
  outline: none;
  overflow: hidden;
  padding: 0 3rem 0 1rem;
  position: relative;
  text-transform: uppercase;
  @include transition($transition);

  > svg {
    margin-right: 0.5rem;
    width: 0.9rem;
    path {
      @include transition($transition);
    }
  }

  .success {
    align-items: center;
    background-color: rgb(124, 214, 124);
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    svg {
      width: 2rem;
      path {
        fill: #fff;
      }
    }
  }

  &:hover {
    background-color: $mainColor;
    color: #fff;
    > svg {
      path {
        fill: #fff;
      }
    }
  }
}

@media screen and (max-width: $s) {
  .contacts__form {
    padding: 0 1rem;
  }
  .contacts__form__footer {
    padding-left: 1rem;
  }

  .contacts__form {
    input,
    textarea {
      padding: 1rem;
    }
  }
}
</style>