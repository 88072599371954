<template>
  <div class="inner-page">
    <header class="inner-page__header">
      <aside class="inner-page__aside">
        <transition name="slide" mode="out-in">
          <img
            :src="require(`@/assets/img/aem/aem_${$i18n.locale}.svg`)"
            :alt="$t('aem-alt')"
            class="svg"
          />
        </transition>
        <p>
          <img src="@/assets/img/scroll_h.svg" class="scroll-h" />
          {{ $t('aem-p') }}
          <br />
          <br />
          <strong>{{ $t('aem-p-2') }}</strong>
        </p>
      </aside>
      <div class="inner-page__img"></div>
      <p class="mobile">
        {{ $t('aem-p') }}
        <br />
        {{ $t('aem-p-2') }}
      </p>
    </header>
    <main class="inner-page__main">
      <section class="terms__main">
        <div class="inner-page__texts">
          <h2>{{ $t('aem-ttl') }}</h2>
          <p class="sttl">
            <a :href="`/covid_${this.$i18n.locale}.pdf`">{{ $t('aem-p-4') }}</a>
          </p>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import '@/scss/inner_page.scss';
@import '@/scss/transition.scss';
.inner-page__img {
  background-image: url('../../assets/img/aem/aem.jpg');
}

.inner-page__main {
  background-image: url('../../assets/img/bg.jpg');
}

.inner-page__header {
  background-color: #e5efff;
}

.terms__main {
  display: flex;
  padding: 3rem 5rem 3rem 9rem;
  p {
    margin: 1rem 0;
  }
}

@media screen and (max-width: $sm) {
  .terms__main {
    padding: 3rem 4rem 3rem 6rem;
  }
}

@media screen and (max-width: $s) {
  .inner-page__header {
    height: initial;
    max-height: initial;
    > p {
      padding: 1rem;
    }
  }
  .inner-page__img {
    margin-left: 1rem;
    margin-top: calc(13vh + 5rem);
    width: calc(100% - 1rem);
  }
  .terms__main {
    flex-direction: column;
    padding: 0;
  }
  .inner-page__texts {
    padding: 1rem;
  }
}
</style>
