<template>
  <router-link
    :to="to || '/'"
    class="parallax"
    :class="{ 'parallax--init': init }"
  >
    <img
      class="bg"
      :src="require(`@/${poster}`)"
      alt="Background"
      v-if="video && mobile"
    />
    <video
      alt="Background"
      v-else-if="video"
      :poster="require(`@/${poster}`)"
      muted
      ref="video"
      @ended="$emit('ended')"
    >
      <source :src="require(`@/${bg}`)" />
      Sorry, your browser doesn't support embedded videos.
    </video>
    <img class="bg" :src="require(`@/${bg}`)" alt="Background" v-else />
    <div class="parallax__svg">
      <img
        src="@/assets/img/left_white.svg"
        alt="Previous"
        class="arrow"
        v-if="arrows"
        @click.prevent="$emit('prev')"
      />
      <transition name="svg">
        <div
          class="parallax__svg__image"
          :style="{
            background: `radial-gradient(circle,
              ${gradient ? gradient : 'rgba(0, 63, 82, 1)'} 0%,
              rgba(255, 255, 255, 0) 50%
            )`,
          }"
          v-if="init"
        >
          <img :src="require(`@/${svg}`)" :alt="alt" />
        </div>
      </transition>
      <img
        src="@/assets/img/right_white.svg"
        alt="Next"
        class="arrow"
        v-if="arrows"
        @click.prevent="$emit('next')"
      />
    </div>
  </router-link>
</template>

<script>
export default {
  props: [
    "bg",
    "svg",
    "alt",
    "gradient",
    "to",
    "arrows",
    "video",
    "visible",
    "poster",
    "mobile",
  ],
  data() {
    return {
      init: false,
    };
  },
  watch: {
    visible(to) {
      if (this.video && !this.mobile) {
        if (to) this.$refs.video.play();
        else this.$refs.video.pause();
      }
    },
  },
  mounted() {
    setTimeout(() => {
      this.init = true;

      if (this.video && !this.mobile) {
        if (this.$refs.video) {
          this.$refs.video.play();
        }
      }
    }, 1000);
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/variables.scss";
@import "@/scss/mixins.scss";
.parallax {
  height: 100vh;
  max-height: 100vh;
  min-height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  max-height: calc(var(--vh, 1vh) * 100);
  min-height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;
  position: relative;
  width: 100%;
}
.bg {
  display: block;
  height: 100%;
  object-fit: cover;
  object-position: 50% 0%;
  @include transition($transitionLong);
  width: 100%;
}
video {
  object-fit: cover;
  height: 100%;
  width: 100%;
}
.parallax__svg {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0%;
  position: absolute;
  top: 0%;
  width: 100%;
  // @include transform(translateX(-50%));
}
.parallax__svg__image {
  // background: radial-gradient(
  //   circle,
  //   rgba(0, 63, 82, 1) 0%,
  //   rgba(255, 255, 255, 0) 100%
  // );
  display: flex;
  opacity: 1;
  padding: 2rem;
  width: 33vw;
  // height: 20vw;
  img {
    width: 100%;
  }
}
.parallax--init {
  .bg {
    object-position: 50% 90%;
  }
}
.arrow {
  display: none;
}
.svg-enter-active,
.svg-leave-active {
  @include transition($transitionLong);
}
.svg-enter,
.svg-leave-to {
  width: 100vw;
  opacity: 0;
}
@media screen and (max-width: $s) {
  .parallax__svg__image {
    padding: 0;
    width: 70vw;
  }
  .arrow {
    display: block;
    width: 10vw;
    position: absolute;
    padding: 1vw;
    left: 2vw;
    &:last-child {
      left: initial;
      right: 2vw;
    }
  }
}
</style>