<template>
  <div
    class="header"
    :class="{
      'header--init': init && $route.name === 'home',
      'header--left': this.$route.name !== 'home',
    }"
  >
    <router-link to="/">
     <img src="@/assets/img/logo.svg" alt="Regnum" class="logo"/> 
      <!-- <img
        src="@/assets/img/logo_black.svg"
        alt="Regnum"
        class="logo"
        v-if="
          this.$route.name !== 'home' &&
          this.$route.name !== 'loyalty' &&
          this.$route.name !== 'banya'
        "
      />
      <img src="@/assets/img/logo.svg" alt="Regnum" class="logo" v-else /> -->
      <!-- <span
        v-if="this.$route.name !== 'home'"
        :class="{
          white: this.$route.name === 'loyalty' || this.$route.name === 'banya',
        }"
        >{{ $t('logo-home') }}</span
      > -->
    </router-link>
  </div>
</template>

<script>
export default {
  data() {
    return {
      init: true,
    };
  },
  mounted() {
    setTimeout(() => {
      this.init = false;
    }, 1000);
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss';
@import '@/scss/mixins.scss';
.header {
  align-items: center;
  display: flex;
  height: 5rem;
  justify-content: center;
  // mix-blend-mode: difference;
  position: absolute;
  width: 100%;
  padding: 0 1.25rem;
  z-index: 10;
  @include transition($transitionLong);

  a {
    align-items: center;
    display: inline-flex;
    > span {
      border-left: 1px solid #fff;
      color: #fff;
      font-size: 1rem;
      font-weight: 500;
      margin-top: 0.7rem;
      margin-left: 0.5rem;
      padding-left: 0.5rem;
      position: relative;
      z-index: 1;
      &.white {
        border-left: 1px solid #fff;
        color: #fff;
      }
    }
  }

  &:not(.header--left)::before,
  &:not(.header--left)::after {
    background: rgb(0, 63, 82);
    background: -moz-linear-gradient(
      0deg,
      rgba(0, 63, 82, 0) 0%,
      rgba(0, 63, 82, 0.2049194677871149) 100%
    );
    background: -webkit-linear-gradient(
      0deg,
      rgba(0, 63, 82, 0) 0%,
      rgba(0, 63, 82, 0.2049194677871149) 100%
    );
    background: linear-gradient(
      0deg,
      rgba(0, 63, 82, 0) 0%,
      rgba(0, 63, 82, 0.2049194677871149) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#003f52",endColorstr="#003f52",GradientType=1);
    content: '';
    height: 100%;
    left: 0;
    opacity: 1;
    position: absolute;
    top: 0;
    width: 100%;
    @include transition($transitionLong);
  }
  &:not(.header--left)::after {
    background: rgb(0, 63, 82);
    background: -moz-linear-gradient(
      0deg,
      rgba(0, 63, 82, 0) 0%,
      rgba(0, 63, 82, 1) 100%
    );
    background: -webkit-linear-gradient(
      0deg,
      rgba(0, 63, 82, 0) 0%,
      rgba(0, 63, 82, 1) 100%
    );
    background: linear-gradient(
      0deg,
      rgba(0, 63, 82, 0) 0%,
      rgba(0, 63, 82, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#003f52",endColorstr="#003f52",GradientType=1);
    opacity: 0;
  }
}
.logo {
  position: relative;
  width: 7.3rem;
  z-index: 1;
  filter: drop-shadow(0 0 0.75rem black);
  @include transition($transitionLong);
}

// .header--left {
//   a {
//     > span {
//       border-left: 1px solid rgb(255, 255, 255);
//       color: rgb(255, 255, 255);
//       filter: drop-shadow(0 0 0.75rem black);
//     }
//   }
// }

// .header--init {
//   height: 100%;
//   &:not(.header--left)::before {
//     opacity: 0;
//   }
//   &:not(.header--left)::after {
//     opacity: 1;
//   }
//   .logo {
//     width: 30vw;
//   }
// }

// @media screen and (min-width: $s) {
//   .header--left {
//     justify-content: flex-start;
//     width: initial;
//   }
// }
</style>