<template>
  <div id="app">
    <Info />
    <!-- <Header /> -->
    <router-view :key="`rv-${$i18n.locale}`" />
    <Nav @open-reservation="isReservationOpen = true" />
    <FooterAdditional />
    <Footer />
    <GDPR />
    <transition name="fade">
      <Reservation
        v-if="isReservationOpen"
        @close="isReservationOpen = false"
      />
    </transition>
    <!-- <HomeModal v-if="!viewedNyeModal" @close="closeNyeModal" /> -->
  </div>
</template>

<script>
import Info from "./components/Info";
// import Header from "./components/Header";
import Nav from "./components/Nav";
import FooterAdditional from "./components/FooterAdditional";
import Footer from "./components/Footer";
import GDPR from "./components/GDPR";
import Reservation from "./components/Reservation.vue";
// import HomeModal from "./views/home/HomeModal.vue";
export default {
  components: {
    Info,
    // Header,
    Nav,
    FooterAdditional,
    Footer,
    GDPR,
    Reservation,
    // HomeModal,
  },
  watch: {
    $route() {
      window.scroll(0, 0);
      this.setTitle();
    },
  },
  data() {
    return {
      isReservationOpen: false,
      viewedNyeModal: true,
    };
  },
  methods: {
    closeNyeModal() {
      this.viewedNyeModal = true;
      localStorage.setItem("viewedNyeModal", true);
    },
    handleResize() {
      // We execute the same script as before
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    },
    setTitle() {
      this.titles = {
        home: this.$t("titles.home"),
        offers: this.$t("titles.offers"),
        rooms: this.$t("titles.rooms"),
        restaurant: this.$t("titles.restaurant"),
        halls: this.$t("titles.halls"),
        services: this.$t("titles.services"),
        contacts: this.$t("titles.contacts"),
        news: this.$t("titles.news"),
        aem: this.$t("titles.aem"),
      };
      if (!this.$route.params.id) {
        if (this.titles[this.$route.name]) {
          document.title = this.titles[this.$route.name];
        } else {
          document.title = this.titles.home;
        }
      }
    },
    disableCopying() {
      document.addEventListener("copy", (e) => {
        e.preventDefault();
        return;
      });
    },
  },
  mounted() {
    // We listen to the resize event
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    this.setTitle();
    if (process.env.VUE_APP_ENV === "prod") {
      document.body.classList = "noselect";
      this.disableCopying();
    }
    setTimeout(() => {
      let viewed = localStorage.getItem("viewedNyeModal");
      this.viewedNyeModal = viewed;
    }, 1000);
  },
};
</script>

<style lang="scss">
@import "@/scss/variables.scss";
@import "@/scss/vdp_datepicker.scss";
@font-face {
  font-family: "Gill Sans Pro Cyrillic";
  src: url("./assets/fonts/GillSansProCyrillic-Bold.woff2") format("woff2"),
    url("./assets/fonts/GillSansProCyrillic-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gill Sans Pro Cyrillic";
  src: url("./assets/fonts/GillSansProCyrillic-Medium.woff2") format("woff2"),
    url("./assets/fonts/GillSansProCyrillic-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gill Sans Pro Cyrillic";
  src: url("./assets/fonts/GillSansProCyrillic-Light.woff2") format("woff2"),
    url("./assets/fonts/GillSansProCyrillic-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Oranienbaum";
  src: url("./assets/fonts/Oranienbaum.woff2") format("woff2"),
    url("./assets/fonts/Oranienbaum.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

html {
  background: #fff;
  box-sizing: border-box;
  color: #000;
  font-size: 20px;
  font-weight: 300;
  font-family: "Gill Sans Pro Cyrillic", sans-serif;

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }
}

body {
  margin: 0;
  min-width: 320px;
  padding: 0;

  &.noscroll {
    overflow: hidden;
  }
}

html,
body {
  overscroll-behavior-y: none;
}

button {
  font-family: "Gill Sans Pro Cyrillic", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Oranienbaum", serif;
  font-weight: 300;
  margin: 0px;
}

h1 {
  font-size: 6.65rem;
  line-height: 7.75rem;
  text-shadow: 2px 2px 50px black;
}

h2 {
  font-size: 3.6rem;
  line-height: 3.5rem;
  text-shadow: 2px 2px 50px black;
}

p {
  margin: 0px;
  &.sttl {
    font-size: 1.6rem;
  }
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
}

input,
textarea {
  color: #000;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;

  &::placeholder {
    color: inherit;
  }
}

.stop-scrolling {
  overflow: hidden;
}

.mobile {
  display: none;
}

.fb_dialog_content > iframe {
  left: -100vw;
}

.noselect * {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome and Opera */
}

// /* The following is for the chat bubble */
// .fb_dialog_content > iframe {
//   left: 18pt !important;
//   right: initial !important;
// }

// .fb_iframe_widget iframe {
//   left: 9pt !important;
//   right: initial !important;
// }

// /* The following are for the chat box, on display and on hide */
// iframe.fb_customer_chat_bounce_in_v2 {
//   left: 9pt !important;
//   right: initial !important;
//   transform-origin: bottom left !important;
// }

// iframe.fb_customer_chat_bounce_out_v2 {
//   left: 9pt !important;
//   right: initial !important;
//   transform-origin: bottom left !important;
// }

// /* The following are for the bounce in/out animations */
// @keyframes fb_bounce_in_v2 {
//   0% {
//     opacity: 0;
//     transform: scale(0, 0);
//   }

//   50% {
//     transform: scale(1.03, 1.03);
//   }

//   100% {
//     opacity: 1;
//     transform: scale(1, 1);
//   }
// }

// @keyframes fb_bounce_out_v2 {
//   0% {
//     opacity: 1;
//     transform: scale(1, 1);
//   }

//   100% {
//     opacity: 0;
//     transform: scale(0, 0);
//   }
// }

@media screen and (max-width: $xl) {
  html {
    font-size: 16px;
  }
}

@media screen and (max-width: $l) {
  html {
    font-size: 14px;
  }
}
@media screen and (max-width: $m) {
  html {
    font-size: 13px;
  }
}
@media screen and (max-width: $sm) {
  html {
    font-size: 12px;
  }
}

@media screen and (max-width: $s) {
  .desktop {
    display: none;
  }
  .mobile {
    display: block;
  }
  .fb_dialog_content > iframe {
    bottom: 1.5rem !important;
    left: 0.5rem !important;
    right: initial !important;
  }
}
@media screen and (max-width: $xxs) {
  .fb_dialog_content > iframe {
    bottom: 1rem !important;
    left: 1rem !important;
    right: initial !important;
  }
}
</style>
