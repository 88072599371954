<template>
  <div
    class="nav"
    :class="{
      'nav--shadow': shadow,
      'nav--scrolled': y,
      'nav--hidden': scrollingDown && y,
    }"
  >
    <MobileDock
      :hidden="(y && scrollingDown) || isMenuOpen || pageBottom"
      @open-reservation="$emit('open-reservation')"
    />
    <Tawkto :y="y" :hidden="isMenuOpen" />

    <router-link to="/">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="146.444"
        height="68.425"
        viewBox="0 0 146.444 68.425"
        :alt="$t('alt.regnum-bansko')"
      >
        <g id="white" transform="translate(-189.112 -189.315)">
          <g
            id="Group_50"
            data-name="Group 50"
            transform="translate(189.112 189.315)"
          >
            <g
              id="Group_48"
              data-name="Group 48"
              transform="translate(0 31.605)"
            >
              <path
                id="Path_351"
                data-name="Path 351"
                d="M194.553,292.87l.655-.029c.83-.039,1.547-.071,2.253-.071,4.463,0,6.285.91,6.285,3.142,0,2.167-2.127,3.41-5.833,3.41-1.008,0-2.507,0-3.4-.065Zm12.342,15.079a28.534,28.534,0,0,1-4.248-6.685c3.128-.791,5.138-2.971,5.138-5.594,0-3.829-3.292-5.611-10.359-5.611-1.1,0-3.09.052-4.847.1-1.4.037-2.6.068-3.129.068l-.25,0-.088.673h.167a1.074,1.074,0,0,1,1.221,1.135V307.4c0,.811-.3,1.142-1.02,1.142-.046,0-.093,0-.142,0l-.136-.009-.089.679,6.727.051.089-.672h-.167c-.88,0-1.255-.355-1.255-1.187l.048-5.526h2.421l1.678.005a37.856,37.856,0,0,0,4.028,7.267l.044.062H208.4l.081-.622h-.167a1.7,1.7,0,0,1-1.417-.634"
                transform="translate(-189.112 -289.845)"
                fill="#fff"
              />
              <path
                id="Path_352"
                data-name="Path 352"
                d="M281.4,305.691a1.946,1.946,0,0,1-1.975.916l-9.367-.049.047-6.343h8.558a1.518,1.518,0,0,1,1.135.362.527.527,0,0,1,.141.413l-.017.148.812.079.053-4.581-.8-.078v.161c0,.38-.348.787-1.325.787l-8.605-.049.047-4.336h9.009c1.425,0,1.7.221,2.189.9l.054.076.777-.145-1.178-3.7-.123.017a11.145,11.145,0,0,1-1.408.134l-14.674-.05-.088.671h.166a1.066,1.066,0,0,1,1.221,1.161v15.366A.979.979,0,0,1,265,308.675c-.036,0-.072,0-.11,0l-.135-.007-.089.679h14.554a12.939,12.939,0,0,1,1.609.127l.12.017,1.295-3.7-.785-.169Z"
                transform="translate(-240.801 -289.983)"
                fill="#fff"
              />
              <path
                id="Path_353"
                data-name="Path 353"
                d="M348.165,299.505h.167c.879,0,1.222.341,1.222,1.215l-.027,4.9a9.7,9.7,0,0,1-4.14.755c-4.781,0-7.635-2.686-7.635-7.185,0-4.381,2.789-7,7.461-7,3.878,0,5.389,1.093,5.944,1.744l.054.063.825-.141-1.054-4.276-.143.037a5,5,0,0,1-1.223.158,7.46,7.46,0,0,1-.965-.114,19.824,19.824,0,0,0-3.265-.288c-5.694,0-11.746,3.439-11.746,9.814,0,6.136,4.367,9.948,11.4,9.948a26.178,26.178,0,0,0,8.642-1.7l.149-.057-.07-.144a3.255,3.255,0,0,1-.269-1.484v-5.032c0-.792.368-1.21,1.058-1.166l.136.008.089-.677-6.519-.051Z"
                transform="translate(-287.993 -289.381)"
                fill="#fff"
              />
              <path
                id="Path_354"
                data-name="Path 354"
                d="M427.993,291.25h.166a1.165,1.165,0,0,1,1.255,1.134l-.048,10.417L419.179,292a2.587,2.587,0,0,1-.724-1.253l-.025-.113-5.4-.052-.089.672h.167a1.1,1.1,0,0,1,1.221,1.108V307.7c0,.8-.342,1.2-1.046,1.2-.039,0-.079,0-.118,0l-.135-.007-.088.677,6.346.052.089-.672h-.167c-.869,0-1.256-.383-1.256-1.241l.049-11.583L429.369,308.3a2.692,2.692,0,0,1,.622,1.14l.018.125h3V292.412a.993.993,0,0,1,1.085-1.117c.037,0,.074,0,.112,0l.137.009.09-.678-6.346-.052Z"
                transform="translate(-342.25 -290.2)"
                fill="#fff"
              />
              <path
                id="Path_355"
                data-name="Path 355"
                d="M509.372,291.25h.167c.834,0,1.256.319,1.256.947v12.046c0,1.929-1.9,3.035-5.209,3.035-3.639,0-5.485-1.039-5.485-3.089V292.277c0-.714.383-1.027,1.2-.978l.135.008.09-.677-6.728-.052-.088.672h.167c.833,0,1.255.345,1.255,1.027v12.716a4.106,4.106,0,0,1-.321,1.772l-.052.112.1.07a15.279,15.279,0,0,0,9.624,3.068,15.775,15.775,0,0,0,9.434-3.034l.1-.068-.048-.111a4.425,4.425,0,0,1-.341-1.835V292.117c0-.715.641-.822,1.024-.822.047,0,.092,0,.14,0l.136.008.089-.677-6.555-.052Z"
                transform="translate(-398.188 -290.2)"
                fill="#fff"
              />
              <path
                id="Path_356"
                data-name="Path 356"
                d="M598.945,308.938c-.557,0-1.221-.188-1.221-1.081V292.412c0-.731.363-1.117,1.049-1.117.037,0,.075,0,.113,0l.137.009.088-.679-5.363-.039-.028.112a3.9,3.9,0,0,1-.644,1.3l-6.208,8.514-5.943-8.512a3.239,3.239,0,0,1-.593-1.255l-.017-.125-5.892-.052-.088.672h.167a1.123,1.123,0,0,1,1.221,1.188V307.83c0,.735-.315,1.063-1.023,1.063-.045,0-.092,0-.139,0l-.136-.008-.089.678,6.658.052.087-.672h-.166c-.892,0-1.289-.341-1.289-1.107l.049-11.53,6.907,9.85.861-1.1,6.378-8.788v11.6a.942.942,0,0,1-1.072,1.035c-.041,0-.083,0-.126,0l-.135-.007-.089.678h6.631l.081-.622Z"
                transform="translate(-452.667 -290.2)"
                fill="#fff"
              />
            </g>
            <g id="Group_49" data-name="Group 49" transform="translate(47.461)">
              <path
                id="Path_357"
                data-name="Path 357"
                d="M417.755,189.315a1.824,1.824,0,1,0,1.824,1.824,1.824,1.824,0,0,0-1.824-1.824"
                transform="translate(-391.753 -189.315)"
                fill="#fff"
              />
              <path
                id="Path_358"
                data-name="Path 358"
                d="M347.968,232.388c-.314-.665-.782-1.581-1.314-2.624-2.073-4.058-3.64-7.23-3.409-8.075a.873.873,0,0,1,.461-.568c.877-.458,4.255.7,5.036,1.005l-.012-.425c-1.271-1.189-3.726-3.552-6.774-3.536a3.449,3.449,0,0,0-.977.133,2.209,2.209,0,0,0-1.533,1.634c-.842,2.97,6.72,11.175,8.052,12.589q.233-.068.471-.133"
                transform="translate(-339.38 -209.053)"
                fill="#fff"
              />
              <path
                id="Path_359"
                data-name="Path 359"
                d="M381.435,225.668c-.2-.663-.407-1.408-.76-2.459-1.492-4.449-2.605-7.916-2.257-8.714h0a.81.81,0,0,1,.49-.447c1.046-.381,4.307,1.228,5.164,1.672l-.015-.484c-1.246-1.547-4.909-4.464-6.9-4.464a3.362,3.362,0,0,0-.442.027,2.157,2.157,0,0,0-1.8,1.316c-1.262,2.807,5,12.075,6.046,13.6l.471-.05"
                transform="translate(-363.579 -203.995)"
                fill="#fff"
              />
              <path
                id="Path_360"
                data-name="Path 360"
                d="M417.186,220.916h.436c.012-.08.118-.7.135-.806.009-.056.02-.122.03-.185.052-.325.12-.729.2-1.216a79.268,79.268,0,0,0,1.391-12.64,5.166,5.166,0,0,0-.062-.728c-.054-.307-.154-.481-.258-.534a3.36,3.36,0,0,0-1.671-.407h.012a3.359,3.359,0,0,0-1.671.407c-.1.054-.2.228-.258.534a5.274,5.274,0,0,0-.062.728,79.33,79.33,0,0,0,1.391,12.64c.081.488.149.891.2,1.216.01.063.021.129.03.185.016.1.123.726.135.806h.022Z"
                transform="translate(-391.391 -199.634)"
                fill="#fff"
              />
              <path
                id="Path_361"
                data-name="Path 361"
                d="M477.906,221.12a.876.876,0,0,1,.46.568c.231.846-1.336,4.017-3.409,8.075-.532,1.042-1,1.958-1.314,2.622q.238.066.472.133c1.336-1.419,8.892-9.618,8.05-12.587a2.211,2.211,0,0,0-1.533-1.634,3.457,3.457,0,0,0-.977-.133c-3.048-.015-5.5,2.348-6.774,3.536l-.012.425c.781-.3,4.159-1.463,5.037-1.005"
                transform="translate(-430.708 -209.052)"
                fill="#fff"
              />
              <path
                id="Path_362"
                data-name="Path 362"
                d="M437.627,215.719l.014-.484c1.247-1.547,4.909-4.464,6.9-4.464a3.354,3.354,0,0,1,.441.027,2.158,2.158,0,0,1,1.8,1.316c1.261,2.807-4.994,12.072-6.046,13.6l-.471-.049c.2-.663.407-1.408.759-2.459,1.492-4.449,2.605-7.916,2.257-8.714h0a.808.808,0,0,0-.489-.447c-1.046-.381-4.307,1.228-5.164,1.672"
                transform="translate(-406.597 -203.995)"
                fill="#fff"
              />
            </g>
          </g>
          <g
            id="Group_51"
            data-name="Group 51"
            transform="translate(228.41 245.873)"
          >
            <path
              id="Path_363"
              data-name="Path 363"
              d="M319.555,374.51a3.022,3.022,0,0,0,1.047-2.133c0-1.88-1.239-3-3.314-3h-3.753v11.244h3.937c2.327,0,3.717-1.253,3.717-3.351A3.128,3.128,0,0,0,319.555,374.51Zm-2.267-.592h-2.035v-3.063h2.035c1.379,0,1.669.828,1.669,1.522C318.957,372.953,318.74,373.918,317.289,373.918Zm-2.035,1.48h2.218c1.755,0,2.017,1.172,2.017,1.87,0,.854-.35,1.87-2.017,1.87h-2.218Z"
              transform="translate(-313.535 -369.064)"
              fill="#fff"
            />
            <path
              id="Path_364"
              data-name="Path 364"
              d="M348.727,369.375l-4.835,11.244h1.7l1.319-3.078h4.669l1.319,3.078h1.778l-4.835-11.244Zm-1.21,6.723,1.732-4.075,1.733,4.075Z"
              transform="translate(-334.304 -369.064)"
              fill="#fff"
            />
            <path
              id="Path_365"
              data-name="Path 365"
              d="M393.591,377.329l-6.353-7.954h-1.03v11.244h1.59v-7.954l6.353,7.954h1.03V369.375h-1.59Z"
              transform="translate(-363.256 -369.063)"
              fill="#fff"
            />
            <path
              id="Path_366"
              data-name="Path 366"
              d="M428.579,373.386c-1.881-.5-2.122-1.216-2.122-1.783,0-.965.761-1.54,2.035-1.54a4.791,4.791,0,0,1,2.582.947l.146.106.4-1.407-.064-.053a5.1,5.1,0,0,0-3.066-1.036c-2.156,0-3.662,1.227-3.662,2.984,0,1.629,1.091,2.78,3.153,3.328,2.108.565,2.507,1.2,2.507,2.168,0,1.075-.877,1.8-2.182,1.8a4.35,4.35,0,0,1-3.241-1.461l-.16-.173-.4,1.652.048.05a5.314,5.314,0,0,0,3.754,1.376c1.834,0,3.808-1.054,3.808-3.369C432.117,375.136,431.025,374.031,428.579,373.386Z"
              transform="translate(-389.457 -368.546)"
              fill="#fff"
            />
            <path
              id="Path_367"
              data-name="Path 367"
              d="M467.324,369.375h-2.042l-4.913,5.019v-5.019H458.65v11.244h1.718v-5.232l5.391,5.232h2.305l-6.086-5.88Z"
              transform="translate(-412.817 -369.063)"
              fill="#fff"
            />
            <path
              id="Path_368"
              data-name="Path 368"
              d="M496.58,368.389a5.933,5.933,0,1,0,5.951,5.933A5.757,5.757,0,0,0,496.58,368.389Zm0,10.386a4.188,4.188,0,0,1-4.2-4.453,4.2,4.2,0,1,1,4.2,4.453Z"
              transform="translate(-434.683 -368.389)"
              fill="#fff"
            />
          </g>
        </g>
      </svg>
    </router-link>
    <div
      class="menu"
      :class="{ 'menu--opened': isMenuOpen }"
      @click="!isMenuOpen ? (isMenuOpen = true) : null"
      v-click-outside="clickedOutsideMenu"
    >
      <!-- <img src="@/assets/img/logo.svg" alt="Regnum" class="logo mobile" /> -->
      <div class="languages">
        <span
          :class="{ active: $i18n.locale === 'bg' }"
          @click="setLang('bg')"
          >{{ $t("nav-bg") }}</span
        >
        <span
          :class="{ active: $i18n.locale === 'en' }"
          @click="setLang('en')"
          >{{ $t("nav-en") }}</span
        >
        <!-- <span
          :class="{ active: $i18n.locale === 'gr' }"
          @click="setLang('gr')"
          >{{ $t("nav-gr") }}</span
        >
        <span
          :class="{ active: $i18n.locale === 'ru' }"
          @click="setLang('ru')"
          >{{ $t("nav-ru") }}</span
        >
        <span
          :class="{ active: $i18n.locale === 'tr' }"
          @click="setLang('tr')"
          >{{ $t("nav-tr") }}</span
        > -->
      </div>
      <div class="menu__content">
        <ul>
          <li @click.stop="open('/offers')">
            <a>{{ $t("nav-offers") }}</a>
          </li>
          <li @click.stop="open('/rooms')">
            <a>{{ $t("nav-rooms") }}</a>
          </li>
          <li @click.stop="open('/restaurant')">
            <a>{{ $t("nav-restaurant") }}</a>
          </li>
          <li @click.stop="open('/spa')">
            <a>{{ $t("nav-spa") }}</a>
          </li>
          <li @click.stop="open('/halls')">
            <a>{{ $t("nav-halls") }}</a>
          </li>
          <li @click.stop="open('/services')">
            <a>{{ $t("nav-services") }}</a>
          </li>
          <li @click.stop="open('/contacts')">
            <a>{{ $t("nav-contacts") }}</a>
          </li>
          <li @click.stop="open('/gallery')">
            <a>{{ $t("nav-gallery") }}</a>
          </li>
          <li @click.stop="open('/news')">
            <a>{{ $t("nav-news") }}</a>
          </li>
          <li @click.stop="open('/terms')">
            <a>{{ $t("nav-terms") }}</a>
          </li>
          <li @click.stop="isMenuOpen = false" class="mobile">
            <a>{{ $t("back") }}</a>
          </li>
          <!-- <li>
            <router-link to="/contacts?bansko=true">{{
              $t("nav-bansko")
            }}</router-link>
          </li> -->
          <!-- <li>
            <router-link to="/aem">{{ $t("nav-covid") }}</router-link>
          </li> -->
        </ul>
        <span class="menu__toggle">{{ $t("nav-menu") }}</span>
      </div>
      <!-- <transition name="close">
        <footer class="mobile" v-if="isMenuOpen">
          <span class="close" @click.stop="isMenuOpen = false"></span>
        </footer>
      </transition> -->
    </div>

    <button class="book-now" type = "button" data-clock-pms-wbe-button> {{ $t("book-now") }} </button>

    <!-- <div class="book-now" @click="$emit('open-reservation')">
      {{ $t("book-now") }}
    </div> -->
    <!-- <div
      class="booking"
      :class="{
        'booking--opened': isBookingOpen,
        'booking--transparent': isMenuOpen,
      }"
      v-click-outside="clickedOutsideBooking"
    >
      <div class="booking__dropdowns">
        <div
          class="dropdown dropdown--date"
          :class="{ 'dropdown--active': dropdown[0] }"
          @click.stop="openDropdown(0)"
        >
          <p>{{ $t("booking-checkin") }}</p>
          <p>{{ search.from | formatDate }}</p>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="8.25"
            viewBox="0 0 15 8.25"
          >
            <path
              id="dd"
              d="M1498.72,3513.97a.749.749,0,0,1,1.06,0l6.22,6.219,6.22-6.219a.75.75,0,0,1,1.06,1.06l-6.75,6.75a.749.749,0,0,1-1.06,0l-6.75-6.75A.749.749,0,0,1,1498.72,3513.97Z"
              transform="translate(-1498.5 -3513.75)"
              fill="#003f52"
              fill-rule="evenodd"
            />
          </svg>
          <transition name="scale-up">
            <div class="dropdown__content" v-if="dropdown[0]" @click.stop>
              <Datepicker
                :language="getLang"
                :inline="true"
                :monday-first="true"
                :minimumView="'day'"
                :maximumView="'day'"
                :full-month-name="true"
                :disabled-dates="{ to: today, from: search.to }"
                v-model="search.from"
                @selected="closeDropdown(0)"
              />
            </div>
          </transition>
        </div>
        <div
          class="dropdown dropdown--date"
          :class="{ 'dropdown--active': dropdown[1] }"
          @click.stop="openDropdown(1)"
        >
          <p>{{ $t("booking-checkout") }}</p>
          <p>{{ search.to | formatDate }}</p>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="8.25"
            viewBox="0 0 15 8.25"
          >
            <path
              id="dd"
              d="M1498.72,3513.97a.749.749,0,0,1,1.06,0l6.22,6.219,6.22-6.219a.75.75,0,0,1,1.06,1.06l-6.75,6.75a.749.749,0,0,1-1.06,0l-6.75-6.75A.749.749,0,0,1,1498.72,3513.97Z"
              transform="translate(-1498.5 -3513.75)"
              fill="#003f52"
              fill-rule="evenodd"
            />
          </svg>
          <transition name="scale-up">
            <div class="dropdown__content" v-if="dropdown[1]" @click.stop>
              <Datepicker
                :language="getLang"
                :inline="true"
                :monday-first="true"
                :minimumView="'day'"
                :maximumView="'day'"
                :full-month-name="true"
                :disabled-dates="{ to: search.from }"
                v-model="search.to"
                @selected="closeDropdown(1)"
              />
            </div>
          </transition>
        </div>
        <div
          class="dropdown"
          :class="{ 'dropdown--active': dropdown[2] }"
          @click.stop="openDropdown(2)"
        >
          <p>{{ $t("booking-adults") }}</p>
          <p>{{ search.adults }}</p>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="8.25"
            viewBox="0 0 15 8.25"
          >
            <path
              id="dd"
              d="M1498.72,3513.97a.749.749,0,0,1,1.06,0l6.22,6.219,6.22-6.219a.75.75,0,0,1,1.06,1.06l-6.75,6.75a.749.749,0,0,1-1.06,0l-6.75-6.75A.749.749,0,0,1,1498.72,3513.97Z"
              transform="translate(-1498.5 -3513.75)"
              fill="#003f52"
              fill-rule="evenodd"
            />
          </svg>
          <transition name="scale-up">
            <div class="dropdown__content" v-if="dropdown[2]">
              <p @click="search.adults = 1">1</p>
              <p @click="search.adults = 2">2</p>
              <p @click="search.adults = 3">3</p>
              <p @click="search.adults = 4">4</p>
              <p @click="search.adults = 5">5</p>
              <p @click="search.adults = 6">6</p>
            </div>
          </transition>
        </div>
        <div
          class="dropdown"
          :class="{ 'dropdown--active': dropdown[3] }"
          @click.stop="openDropdown(3)"
        >
          <p>{{ $t("booking-kids") }}</p>
          <p>{{ search.kids }}</p>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="8.25"
            viewBox="0 0 15 8.25"
          >
            <path
              id="dd"
              d="M1498.72,3513.97a.749.749,0,0,1,1.06,0l6.22,6.219,6.22-6.219a.75.75,0,0,1,1.06,1.06l-6.75,6.75a.749.749,0,0,1-1.06,0l-6.75-6.75A.749.749,0,0,1,1498.72,3513.97Z"
              transform="translate(-1498.5 -3513.75)"
              fill="#003f52"
              fill-rule="evenodd"
            />
          </svg>
          <transition name="scale-up">
            <div class="dropdown__content" v-if="dropdown[3]">
              <p @click="search.kids = 0">0</p>
              <p @click="search.kids = 1">1</p>
              <p @click="search.kids = 2">2</p>
              <p @click="search.kids = 3">3</p>
            </div>
          </transition>
        </div>
        <div
          class="dropdown dropdown--room"
          :class="{ 'dropdown--active': dropdown[4] }"
          @click.stop="openDropdown(4)"
        >
          <p>{{ $t("booking-rooms") }}</p>
          <p>{{ rooms[selectedRoom].name }}</p>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="8.25"
            viewBox="0 0 15 8.25"
          >
            <path
              id="dd"
              d="M1498.72,3513.97a.749.749,0,0,1,1.06,0l6.22,6.219,6.22-6.219a.75.75,0,0,1,1.06,1.06l-6.75,6.75a.749.749,0,0,1-1.06,0l-6.75-6.75A.749.749,0,0,1,1498.72,3513.97Z"
              transform="translate(-1498.5 -3513.75)"
              fill="#003f52"
              fill-rule="evenodd"
            />
          </svg>
          <transition name="scale-up">
            <div class="dropdown__content" v-if="dropdown[4]">
              <p
                v-for="(r, i) in rooms"
                :key="`brl-${i}`"
                @click="selectedRoom = i"
              >
                {{ r.name }}
              </p>
            </div>
          </transition>
        </div>
      </div>
      <div
        class="booking__toggle booking__toggle--center"
        @click="toggleBooking"
      >
        <template>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="33.076"
            height="33.147"
            viewBox="0 0 33.076 33.147"
            v-if="isBookingOpen"
          >
            <g id="Group_304" data-name="Group 304" transform="translate(0)">
              <path
                id="Subtraction_3"
                data-name="Subtraction 3"
                d="M8.977,17.956a8.978,8.978,0,1,1,8.978-8.977A8.988,8.988,0,0,1,8.977,17.956ZM7.383,3.124a.723.723,0,0,0-.512,1.234l4.55,4.55-4.55,4.549a.723.723,0,0,0,1.023,1.022L12.955,9.42a.724.724,0,0,0,0-1.023L7.895,3.336A.719.719,0,0,0,7.383,3.124Z"
                transform="translate(14.62 14.691)"
                fill="#003f52"
                stroke="rgba(0,0,0,0)"
                stroke-miterlimit="10"
                stroke-width="1"
              />
              <path
                id="Path_1365"
                data-name="Path 1365"
                d="M1487.078,564a5.305,5.305,0,0,1,5.305,5.305v1.224H1463v-1.224a5.3,5.3,0,0,1,5.3-5.305Zm5.305,8.978,0,5.751a10.612,10.612,0,0,0-14.656,14.656l-9.424,0a5.3,5.3,0,0,1-5.3-5.305v-15.1Z"
                transform="translate(-1463 -564)"
                fill="#003f52"
              />
            </g>
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32.648"
            height="32.648"
            viewBox="0 0 32.648 32.648"
            v-else
          >
            <g id="Group_305" data-name="Group 305" transform="translate(0)">
              <path
                id="Path_1506"
                data-name="Path 1506"
                d="M8.978,3.265l-.147.013a.816.816,0,0,0-.656.656l-.013.147V8.162H4.081l-.147.013a.817.817,0,0,0-.656.656l-.013.147.013.147a.817.817,0,0,0,.656.656l.147.013H8.162v4.081l.013.147a.816.816,0,0,0,.656.656l.147.013.147-.013a.817.817,0,0,0,.656-.656l.013-.147V9.795h4.081l.147-.013a.817.817,0,0,0,.656-.656l.013-.147-.013-.147a.817.817,0,0,0-.656-.656l-.147-.013H9.795V4.081l-.013-.147a.817.817,0,0,0-.656-.656ZM8.978,0A8.978,8.978,0,1,1,0,8.978,8.98,8.98,0,0,1,8.978,0Z"
                transform="translate(14.692 32.648) rotate(-90)"
                fill="#003f52"
              />
              <path
                id="Path_1505"
                data-name="Path 1505"
                d="M1487.078,564a5.305,5.305,0,0,1,5.305,5.305v1.224H1463v-1.224a5.305,5.305,0,0,1,5.305-5.305Zm5.305,8.978,0,5.751a10.612,10.612,0,0,0-14.656,14.656l-9.424,0a5.305,5.305,0,0,1-5.305-5.305v-15.1Z"
                transform="translate(-1463 -564)"
                fill="#003f52"
              />
            </g>
          </svg>
          <div class="book-text">
            <p>{{ $t("book") }}</p>
            <p>{{ $t("now") }}</p>
          </div>
        </template>
        <div
          class="menu-toggle--mobile"
          :class="{ active: isMenuOpen }"
          @click.stop="isMenuOpen = !isMenuOpen"
          v-if="!isBookingOpen"
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </div> -->
    <!-- <div class="chat-toggle" id="chatToggle">{{ $t("chat-now") }}</div> -->
    <!-- <transition name="fade">
      <Reservation
        v-if="isReservationOpen"
        :search="search"
        :room="rooms[selectedRoom]"
        @close="isReservationOpen = false"
      />
    </transition> -->
  </div>
</template>

<script>
// import Datepicker from "vuejs-datepicker";
import Tawkto from "./Tawkto.vue";
import MobileDock from "./MobileDock.vue";
import ClickOutside from "vue-click-outside";
// eslint-disable-next-line
import { bg, en, ru, tr, el } from "vuejs-datepicker/dist/locale";
// import Reservation from "./Reservation";
export default {
  components: {
    Tawkto,
    MobileDock,
    // Datepicker,
    // Reservation,
  },
  directives: {
    ClickOutside,
  },
  computed: {
    getLang() {
      return en;
    },
    shadow() {
      return (
        this.$route.name === "rooms" ||
        this.$route.name === "offers" ||
        this.$route.name === "halls" ||
        this.$route.name === "news" ||
        this.$route.name === "services" ||
        this.$route.name === "spa"
      );
    },
  },
  data() {
    return {
      bg,
      isMenuOpen: false,
      isBookingOpen: false,
      isReservationOpen: false,
      dropdown: [false, false, false, false, false],
      today: new Date(),
      selectedRoom: 0,
      y: 0,
      scrollingDown: false,
      pageBottom: false,
      rooms: [
        {
          name: this.$t("booking-rooms-1"),
          id: 27768,
        },
        {
          name: this.$t("booking-rooms-2"),
          id: 27769,
        },
        {
          name: this.$t("booking-rooms-3"),
          id: 27770,
        },
        {
          name: this.$t("booking-rooms-4"),
          id: 27771,
        },
        {
          name: this.$t("booking-rooms-5"),
          id: 27772,
        },
        {
          name: this.$t("booking-rooms-6"),
          id: 27773,
        },
        {
          name: this.$t("booking-rooms-7"),
          id: 27774,
        },
        {
          name: this.$t("booking-rooms-8"),
          id: 0,
        },
        {
          name: this.$t("booking-rooms-9"),
          id: 0,
        },
        {
          name: this.$t("booking-rooms-10"),
          id: 27775,
        },
      ],
      search: {
        from: new Date(),
        to: undefined,
        adults: 2,
        kids: 0,
      },
    };
  },
  watch: {
    $route(to) {
      this.isMenuOpen = false;
      // this.isBookingOpen = false;
      // this.dropdown = [false, false, false, false, false];

      if (to.name === "rooms" && to.params.id) {
        this.selectedRoom = Number.parseInt(to.params.id) - 1;
      } else {
        this.selectedRoom = 0;
      }
    },
  },
  methods: {
    setLang(l) {
      localStorage.removeItem("viewedNyeModal");
      location.reload()

      if (l === "en" || l === "bg") {
        this.$i18n.locale = l;
        localStorage.setItem("lang", l);
      } else {
        this.$i18n.locale = "en";
        localStorage.setItem("lang", "en");
      }
      this.isMenuOpen = false;
    },
    open(r) {
      if (this.isMenuOpen) {
        this.$router
          .push(r)
          .then(() => (this.isMenuOpen = false))
          .catch((err) => err);
      } else {
        this.isMenuOpen = true;
      }
    },
    clickedOutsideMenu() {
      if (this.isMenuOpen && window.innerWidth > 768) {
        this.isMenuOpen = false;
      }
    },
    clickedOutsideBooking() {
      if (this.isBookingOpen) {
        if (this.dropdown.some((d) => d)) {
          this.dropdown = [false, false, false, false, false];
        } else {
          this.isBookingOpen = false;
        }
      }
    },
    toggleBooking() {
      if (this.isBookingOpen) {
        if (!this.search.to) {
          this.search.to = new Date(this.search.from);
          this.search.to.setDate(this.search.to.getDate() + 1);
        }
        this.isReservationOpen = true;
        this.dropdown = [false, false, false, false, false];
        this.isBookingOpen = false;
      } else {
        if (!this.isMenuOpen || window.innerWidth > 768) {
          this.isBookingOpen = true;
        }
      }
    },
    openDropdown(n) {
      if (!this.dropdown[n]) {
        this.dropdown = [false, false, false, false, false];
        this.$set(this.dropdown, n, true);
      } else {
        this.$set(this.dropdown, n, false);
      }
    },
    closeDropdown(n) {
      this.$set(this.dropdown, n, false);
    },
    handleScroll() {
      this.scrollingDown = this.y < window.scrollY;
      this.y = window.scrollY;
      this.pageBottom = (window.innerHeight + window.pageYOffset) >= document.body.offsetHeight
    },
  },
  mounted() {
    document.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    document.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/variables.scss";
@import "@/scss/mixins.scss";
.nav {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 20;
  height: 100px;
  @include transition($transition);
  > a svg {
    height: 70px;
    &,
    path {
      @include transition($transition);
    }
  }
}
.menu {
  border: 1px solid #fff;
  border-right: 0;
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  padding: 9px 10px;
  position: absolute;
  right: 0;
  top: 30px;
  width: 90px;
  @include transition($transition);
  @include noselect();
  z-index: 1;
  .menu__content {
    align-items: center;
    display: flex;
    position: relative;
    width: 100%;
  }
  ul {
    justify-content: space-around;
    display: flex;
    flex-direction: column;
    min-height: 20px;
    // width: 100%;
    width: 20px;
    @include transition($transition);

    li {
      align-items: center;
      color: $mainColor;
      display: flex;
      max-height: 1px;
      overflow: hidden;
      position: relative;
      width: 100%;
      @include transition($transition);

      &.mobile {
        display: none;
      }

      &::before {
        background-color: #fff;
        content: "";
        height: 1px;
        min-width: 20px;
        width: 20px;
        @include transition($transition);
      }
      a {
        color: inherit;
        opacity: 0;
        white-space: nowrap;
        position: absolute;
        left: 40px;
        @include transform(translateX(100%));
        @include transition($transition);
      }
    }
  }
  .menu__toggle {
    color: #fff;
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;
    left: 30px;
    position: absolute;
    @include transition($transition);
  }
  .languages {
    display: flex;
    height: 0;
    justify-content: center;
    margin-top: 0;
    opacity: 0;
    overflow: hidden;
    width: 100%;
    @include transition($transition);
    span {
      align-items: center;
      border-radius: 2px;
      color: $mainColor;
      display: inline-flex;
      font-weight: 500;
      margin: 0 2px;
      padding: 0 7px;
      &.active {
        background-color: $mainColor;
        color: #fff;
      }
    }
  }
  &.menu--opened {
    background-color: rgba($color: #fff, $alpha: 0.99);
    padding-left: 1rem;
    width: 330px;
    ul {
      width: 100%;
      li {
        max-height: 60px;
        opacity: 1;
        padding: 20px 0;
        &::before {
          background-color: $mainColor;
          margin-right: 20px;
        }
        a {
          opacity: 1;
          width: 100%;
          @include transform(translateX(0));
        }
      }
    }
    .menu__toggle {
      opacity: 0;
      // transition-delay: $duration;
    }
    .languages {
      height: 32px;
      margin-bottom: 20px;
      opacity: 1;
    }
  }
}

// .booking {
//   background-color: #fff;
//   color: $mainColor;
//   height: 80px;
//   margin-top: 1rem;
//   position: relative;
//   width: 135px;
//   @include transition($transition);
//   .booking__toggle {
//     align-items: center;
//     cursor: pointer;
//     display: inline-flex;
//     font-weight: 500;
//     height: 100%;
//     padding: 0 0 0 1.5rem;
//     width: 100%;
//     @include transition($transition);
//     svg {
//       margin-right: 0.5rem;
//       width: 1.5rem;
//       @include transition($transition);
//     }
//   }
//   .booking__dropdowns {
//     background-color: #e5ebed;
//     display: flex;
//     height: 100%;
//     position: absolute;
//     right: 100%;
//     transform-origin: right;
//     @include transition($transition);
//     @include transform(scaleX(0));
//     .dropdown {
//       cursor: pointer;
//       display: flex;
//       flex-direction: column;
//       height: calc(100% - 10px);
//       justify-content: center;
//       margin: 5px 0;
//       padding: 0 2.5rem 0 1rem;
//       position: relative;
//       &:not(:first-of-type) {
//         border-left: 1px solid rgba($color: $mainColor, $alpha: 0.66);
//       }
//       > svg {
//         position: absolute;
//         right: 0.5rem;
//         top: 50%;
//         @include transform(translateY(-50%));
//       }
//       p {
//         font-size: 1.3rem;
//         @include noselect();
//       }
//       > p {
//         &:first-of-type {
//           font-size: 0.6rem;
//         }
//       }
//       .dropdown__content {
//         background-color: #e5ebed;
//         // display: none;
//         bottom: calc(100% + 5px);
//         left: 0;
//         padding: 1rem;
//         position: absolute;
//         transform-origin: bottom;
//         width: 100%;
//         p {
//           margin: 0.5rem 0;
//         }
//       }
//       &:not(.dropdown--date) {
//         white-space: nowrap;
//       }
//       &.dropdown--date {
//         width: 15rem;
//         .dropdown__content {
//           padding: 0.5rem;
//         }
//       }
//       &.dropdown--room {
//         width: 25rem;
//       }
//     }
//   }
//   &.booking--opened {
//     width: 135px;
//     .booking__toggle {
//       background-color: $mainColor;
//       color: #fff;
//       svg {
//         path {
//           fill: #fff;
//         }
//       }
//     }
//     .booking__dropdowns {
//       @include transform(scaleX(1));
//     }
//   }
// }

.chat-toggle {
  background-color: #fff;
  cursor: pointer;
  color: $mainColor;
  display: flex;
  font-weight: 500;
  flex-wrap: wrap;
  margin-top: 1rem;
  min-height: 50px;
  padding: 14px 1rem 14px 1.5rem;
  opacity: 0;
  width: 135px;
  white-space: nowrap;
  @include noselect();
  @include transform(translateY(100%));
  @include transition($transition);
}

.book-now {
  align-items: center;
  background-color: #e2a151;
  border: 1px solid #fff;
  color: #fff;
  cursor: pointer;
  font-size: 18px;
  font-weight: 500;
  display: flex;
  height: 40px;
  justify-content: center;
  line-height: 18px;
  padding: 9px 10px;
  position: absolute;
  right: 100px;
  top: 50%;
  @include transform(translateY(-50%));
  @include transition($transition);

  &:hover {
    opacity: 0.9;
  }
}

.nav--scrolled {
  background-color: #fff;
  height: 70px;
  > a svg {
    height: 50px;
    path {
      fill: #000;
    }
  }
  .menu {
    border-color: $mainColor;
    top: 15px;

    ul {
      li {
        color: $mainColor;
        &::before {
          background-color: $mainColor;
        }
      }
    }
    .menu__toggle {
      color: $mainColor;
    }
  }

  .book-now {
    border-color: #e2a151;
  }
}

.nav--shadow {
  .menu,
  .booking,
  .chat-toggle,
  .booking__dropdowns {
    box-shadow: 0px 0px 99px #003f5266;
  }
}

.scale-up-enter-active,
.scale-up-leave-active {
  @include transition($transition);
  transform-origin: bottom;
}
.scale-up-enter,
.scale-up-leave-to {
  @include transform(scaleY(0));
}
.fade-enter-active,
.fade-leave-active {
  @include transition($transition);
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

@media screen and (min-width: $s) {
  .menu {
    &:not(.menu--opened):hover {
      width: 142px;

      & + .book-now {
        right: 152px;
      }
    }
    &.menu--opened {
      ul {
        li {
          &:hover {
            &::before {
              width: 40px;
            }
            a {
              left: 60px;
            }
          }
        }
      }
    }
  }
  // .booking {
  //   .booking__toggle {
  //     &:hover {
  //       background-color: rgba($color: $mainColor, $alpha: 0.1);
  //     }
  //   }
  //   .booking__dropdowns {
  //     .dropdown {
  //       .dropdown__content {
  //         p {
  //           &:hover {
  //             opacity: 0.5;
  //           }
  //         }
  //       }
  //       &:hover {
  //         background-color: rgba($color: $mainColor, $alpha: 0.16);
  //       }
  //     }
  //   }
  //   &:hover {
  //     width: 330px;
  //   }
  // }

  // .chat-toggle {
  //   &:hover {
  //     width: 330px;
  //   }
  // }
}

@media screen and (max-width: $s) {
  .nav {
    top: 0;
    width: 100%;
    &.nav--hidden {
      top: -100px;
      opacity: 0;
    }
  }
  .logo {
    left: 50%;
    position: absolute;
    top: 10px;
    width: 7.3rem;
    @include transform(translateX(-50%));
  }
  .book-now {
    display: none;
  }
  .menu {
    .languages {
      font-size: 1.5rem;
    }
  }
  // .booking {
  //   border-radius: 20px;
  //   height: 60px;
  //   margin: 0 2.5rem;
  //   width: calc(100% - 5rem);
  //   &:hover,
  //   &.booking--opened {
  //     width: calc(100% - 5rem);
  //   }
  //   &.booking--transparent {
  //     background-color: transparent;
  //   }
  //   .booking__toggle {
  //     border-radius: inherit;
  //     position: relative;
  //     z-index: 1;
  //     &.booking__toggle--center {
  //       justify-content: center;
  //     }
  //     svg {
  //       width: 2rem;
  //     }
  //     .book-text {
  //       p {
  //         font-size: 2rem;
  //         font-weight: 500;
  //         display: inline-block;
  //         &:not(:first-child) {
  //           margin-left: 0.5rem;
  //         }
  //       }
  //     }
  //   }
  //   .booking__dropdowns {
  //     background-color: #fff;
  //     border-radius: 20px;
  //     bottom: 1rem;
  //     flex-wrap: wrap;
  //     height: initial;
  //     max-width: calc(100% - 4rem);
  //     padding: 0.5rem 0.5rem calc(1rem + 60px);
  //     position: fixed;
  //     right: 2rem;
  //     transform-origin: bottom;
  //     width: calc(100% - 4rem);
  //     @include transform(scaleY(0));
  //     .dropdown {
  //       background-color: #e5ebed;
  //       border-radius: 10px;
  //       margin: 0 0 0.5rem;
  //       min-height: 50px;
  //       padding-bottom: 0.5rem;
  //       padding-top: 0.5rem;
  //       width: calc(50% - 0.25rem);
  //       &:last-of-type {
  //         margin: 0;
  //       }
  //       @include transition($transition);
  //       p {
  //         font-size: 1.7rem;
  //         line-height: 1.7rem;
  //       }
  //       > p {
  //         font-size: 2rem;
  //         line-height: 2rem;
  //         &:first-of-type {
  //           font-size: 1rem;
  //           line-height: 1rem;
  //         }
  //       }
  //       &:nth-child(3) {
  //         margin-right: 0.5rem;
  //       }
  //       &:not(:first-of-type) {
  //         border: none;
  //       }
  //       &.dropdown--date {
  //         width: 100%;
  //       }
  //       &.dropdown--room {
  //         width: 100%;
  //       }
  //       .dropdown__content {
  //         background-color: $mainColor;
  //         color: #fff;
  //         border-radius: 10px;
  //         p {
  //           margin: 0.7rem 0;
  //         }
  //       }
  //       &:not(.dropdown--date) {
  //         white-space: initial;
  //       }
  //       &.dropdown--active {
  //         background-color: $mainColor;
  //         color: #fff;
  //         svg {
  //           path {
  //             fill: #fff;
  //           }
  //         }
  //       }
  //     }
  //   }
  //   &.booking--opened .booking__dropdowns {
  //     @include transform(scaleY(1));
  //   }
  // }
  // .menu-toggle--mobile {
  //   background-color: $mainColor;
  //   border-radius: 14px;
  //   display: flex;
  //   flex-direction: column;
  //   justify-content: space-between;
  //   height: 48px;
  //   padding: 14px;
  //   position: absolute;
  //   right: 6px;
  //   top: 6px;
  //   width: 48px;
  //   @include transition($transition);
  //   &::before {
  //     border: 6px solid #fff;
  //     border-radius: 14px;
  //     content: "";
  //     height: calc(100% + 12px);
  //     left: -6px;
  //     position: absolute;
  //     top: -6px;
  //     width: calc(100% + 12px);
  //   }
  //   span {
  //     background-color: #fff;
  //     border-radius: 20px;
  //     display: block;
  //     height: 4px;
  //     width: 100%;
  //     @include transition($transition);
  //   }
  //   &.active {
  //     span {
  //       &:first-child {
  //         @include transform(rotate(45deg) translateY(130%) translateX(30%));
  //       }
  //       &:nth-child(2) {
  //         opacity: 0;
  //       }
  //       &:last-child {
  //         @include transform(rotate(-45deg) translateY(-130%) translateX(30%));
  //       }
  //     }
  //   }
  // }
  .menu {
    // align-items: space-between;
    .menu__content {
      height: 100%;
    }
    &.menu--opened {
      border: 0;
      height: 100vh;
      max-height: 100vh;
      min-height: 100vh;
      height: calc(var(--vh, 1vh) * 100);
      max-height: calc(var(--vh, 1vh) * 100);
      min-height: calc(var(--vh, 1vh) * 100);
      padding: 2rem 2rem 100px;
      top: 0;
      width: 100%;
      ul {
        li {
          max-height: initial;
          opacity: 1;
        }
      }
      .languages {
        margin: 0 0 20px;
      }
    }
    ul {
      height: 85%;
      justify-content: space-around;
      li {
        &.mobile {
          display: flex;
        }
        font-size: 24px;
        font-weight: 500;
        max-height: initial;
      }
    }
    .lines {
      display: none;
    }
  }

  footer {
    bottom: 16px;
    display: flex;
    justify-content: flex-end;
    position: absolute;
    right: 12px;
    width: 100%;
    @include transition($transition);
    .close {
      background-color: #003e50;
      border: 4px solid #fff;
      border-radius: 18px;
      height: 70px;
      position: relative;
      width: 70px;
      &::before,
      &::after {
        background-color: #fff;
        border-radius: 18px;
        content: "";
        position: absolute;
        height: 8px;
        top: 50%;
        left: 50%;
        width: 30px;
        @include transform(translateX(-50%) translateY(-50%) rotate(45deg));
      }
      &::after {
        @include transform(translateX(-50%) translateY(-50%) rotate(-45deg));
      }
    }
  }
  .chat-toggle {
    display: none;
  }
}

@media screen and (max-width: $xxs) {
  .nav {
    > a svg {
      width: 100px;
    }
  }
  .booking {
    height: 50px;
    .booking__toggle {
      svg {
        width: 1.5rem;
      }
      .book-text {
        p {
          font-size: 1.5rem;
        }
      }
    }
    .booking__dropdowns {
      padding: 0.5rem 0.5rem calc(1rem + 50px);
      .dropdown {
        min-height: 35px;
        p {
          font-size: 1.5rem;
          line-height: 1.5rem;
        }
        > p {
          font-size: 1.4rem;
          line-height: 1.4rem;
          &:first-of-type {
            font-size: 0.9rem;
            line-height: 0.9rem;
          }
        }
      }
    }
  }
  .menu-toggle--mobile {
    height: 40px;
    padding: 10px;
    right: 5px;
    top: 5px;
    width: 40px;
    &::before {
      border: 5px solid #fff;
      height: calc(100% + 10px);
      left: -5px;
      top: -5px;
      width: calc(100% + 10px);
    }
    span {
      height: 4px;
    }
  }
  .menu {
    font-size: 1.5rem;
  }
}

.close-enter-active,
.close-leave-active {
  @include transition($transition);
}
.close-enter,
.close-leave-to {
  opacity: 0;
  @include transform(translateX(100%));
}
</style>
