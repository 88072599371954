<template>
  <div class="showcase">
    <header>
      <video alt="Background" autoplay muted loop ref="video">
        <source src="@/assets/img/nye/nye-b.mp4" />
        Sorry, your browser doesn't support embedded videos.
      </video>
      <div>
        <div class="image">
          <span class="close" @click="back">
            <img src="@/assets/img/showcase/back.svg" alt="Back" />
          </span>
          <img :src="require(`@/assets/img/nye/bosfor_${$i18n.locale}.svg`)" />
        </div>
        <footer class="desktop">
          <div class="options">
            <div class="option">
              <img src="@/assets/img/nye/fireworks.svg" />
              <p>{{ $t("nye.b.o1") }}</p>
            </div>
            <div class="option">
              <img src="@/assets/img/nye/dinner.svg" />
              <p>{{ $t("nye.b.o2") }}</p>
            </div>
          </div>
          <span></span>
          <div class="buttons">
            <ButtonSmaller @click="scrollTo('programme')">{{
              $t("nye.programme")
            }}</ButtonSmaller>
            <ButtonSmaller @click="scrollTo('dinner')">{{
              $t("nye.dinner")
            }}</ButtonSmaller>
          </div>
        </footer>
        <footer class="mobile">
          <div>
            <span class="close" @click="back">
              <img src="@/assets/img/showcase/back.svg" alt="Back" />
            </span>
          </div>
          <div class="mini-buttons">
            <img
              :src="require(`@/assets/img/showcase/info_${$i18n.locale}.svg`)"
              alt=""
              @click="scrollTo('info')"
            />
            <img
              :src="require(`@/assets/img/showcase/inq_${$i18n.locale}.svg`)"
              alt=""
              @click="scrollTo('inquiry')"
            />
            <img
              :src="require(`@/assets/img/showcase/glr_${$i18n.locale}.svg`)"
              alt=""
              @click="scrollTo('gallery')"
            />
          </div>
          <div></div>
        </footer>
      </div>
    </header>
    <div class="showcase-content">
      <div class="showcase-about">
        <div class="txts">
          <h2>{{ $t("nye.b.title") }}</h2>
          <p class="sttl">{{ $t("nye.b.subtitle") }}</p>
        </div>
        <div class="img-and-features">
          <p class="mini-title">{{ $t("nye.b.prices-1") }}</p>
          <p class="mini-title">{{ $t("nye.b.prices-2") }}</p>
          <div class="image">
            <img
              :src="
                require(`@/assets/img/nye/bosfor-price_${$i18n.locale}.svg`)
              "
              alt=""
            />
          </div>
        </div>
      </div>
      <p class="mini-title" ref="programme">{{ $t("nye.b.programme") }}</p>
      <div class="showcase-about showcase-about--strech">
        <div class="txts">
          <p>
            <strong>{{ $t("nye.b.programme-1-h") }} - </strong>
            {{ $t("nye.b.programme-1") }}
          </p>
          <p>
            <strong>{{ $t("nye.b.programme-2-h") }} - </strong>
            {{ $t("nye.b.programme-2") }}
          </p>
          <p>
            <strong>{{ $t("nye.b.programme-3-h") }} - </strong>
            {{ $t("nye.b.programme-3") }}
          </p>
          <p>
            <strong>{{ $t("nye.b.programme-4-h") }} - </strong>
            {{ $t("nye.b.programme-4") }}
          </p>
          <p>
            <strong>{{ $t("nye.b.programme-5-h") }} - </strong>
            {{ $t("nye.b.programme-5") }}
          </p>
          <p>
            <strong>{{ $t("nye.b.programme-6-h") }} - </strong>
            {{ $t("nye.b.programme-6") }}
          </p>
          <p>
            <strong>{{ $t("nye.b.programme-7-h") }} - </strong>
            {{ $t("nye.b.programme-7") }}
          </p>
          <p>
            <strong>{{ $t("nye.b.programme-8-h") }} - </strong>
            {{ $t("nye.b.programme-8") }}
          </p>
          <p>
            <strong>{{ $t("nye.b.programme-9-h") }} - </strong>
            {{ $t("nye.b.programme-9") }}
          </p>
          <!-- <p>
            <strong>{{ $t("nye.b.programme-10-h") }} - </strong>
            {{ $t("nye.b.programme-10") }}
          </p>
          <p>
            <strong>{{ $t("nye.b.programme-11-h") }} - </strong>
            {{ $t("nye.b.programme-11") }}
          </p> -->
        </div>
        <div class="img-and-features">
          <NewYearCarousel
            :images="[
              require('@/assets/img/nye/program/1.jpg'),
              require('@/assets/img/nye/program/2.jpg'),
              require('@/assets/img/nye/program/3.jpg'),
              require('@/assets/img/nye/program/4.jpg'),
              require('@/assets/img/nye/program/5.jpg'),
              require('@/assets/img/nye/program/6.jpg'),
              require('@/assets/img/nye/program/7.jpg'),
              require('@/assets/img/nye/program/8.jpg'),
              require('@/assets/img/nye/program/9.jpg'),
              require('@/assets/img/nye/program/10.jpg'),
              require('@/assets/img/nye/program/11.jpg'),
              require('@/assets/img/nye/program/12.jpg'),
              require('@/assets/img/nye/program/13.jpg'),
              require('@/assets/img/nye/program/14.jpg'),
              require('@/assets/img/nye/program/15.jpg'),
              require('@/assets/img/nye/program/16.jpg'),
              require('@/assets/img/nye/program/17.jpg'),
              require('@/assets/img/nye/program/18.jpg'),
              require('@/assets/img/nye/program/19.jpg'),
              require('@/assets/img/nye/program/20.jpg'),
              require('@/assets/img/nye/program/21.jpg'),
              require('@/assets/img/nye/program/22.jpg'),
              require('@/assets/img/nye/program/23.jpg'),
              require('@/assets/img/nye/program/24.jpg'),
            ]"
          />
        </div>
      </div>
       <p class="mini-title" ref="dinner">{{ $t("nye.b.menu") }}</p>
      <div class="showcase-about showcase-about--strech">
        <div class="img-and-features">
          <NewYearCarousel
            :images="[
              require('@/assets/img/nye/food/1.jpg'),
              require('@/assets/img/nye/food/2.jpg'),
              require('@/assets/img/nye/food/3.jpg'),
              require('@/assets/img/nye/food/4.jpg'),
              require('@/assets/img/nye/food/5.jpg'),
              require('@/assets/img/nye/food/6.jpg'),
              require('@/assets/img/nye/food/7.jpg'),
            ]"
          />
        </div>
        <div class="txts">
          <p v-for="(m, i) in $t('nye.b.menus.main')" :key="`menu-main-${i}`">
            <strong>{{ i + 1 }}. {{ m.name }}</strong>
            {{ m.content }}
          </p>
          <p class="mini-title">{{ $t("nye.b.menu-children") }}</p>
          <p
            v-for="(m, i) in $t('nye.b.menus.children')"
            :key="`menu-children-${i}`"
          >
            <strong>{{ i + 1 }}. {{ m.name }}</strong>
            {{ m.content }}
          </p>
          <p class="mini-title">{{ $t("nye.b.menu-additional") }}</p>
          <p
            v-for="(m, i) in $t('nye.b.menus.additional')"
            :key="`menu-additional-${i}`"
          >
            ▪️ {{ m }}
          </p>
        </div>
      </div> 
      <p class="mini-title">{{ $t(`nye-inquiry`) }}</p>
      <Inquiry mini="true" ref="inquiry" />

      <p class="last">
        <span>{{ $t("nye.b.last-1") }}</span>
        <span>{{ $t("nye.b.last-2") }}</span>
      </p>
    </div>
  </div>
</template>

<script>
import ButtonSmaller from "@/components/ButtonSmaller";
import NewYearCarousel from "./NewYearCarousel";
import Inquiry from "./Inquiry";
export default {
  components: {
    ButtonSmaller,
    NewYearCarousel,
    Inquiry,
  },
  props: ["showcase", "previousShowcase", "nextShowcase"],
  computed: {
    show() {
      return this.$route.name === "rooms" || this.$route.name === "halls";
    },
  },
  methods: {
    back() {
      this.$router.push("/");
    },
    scrollTo(str) {
      const el = this.$refs[str];

      if (el) {
        el.scrollIntoView({ behavior: "smooth" });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/showcase.scss";
</style>
