<template>
  <div
    class="preview"
    :class="{
      'preview--inverted': invert,
      'preview--visible': visible || show,
    }"
  >
    <div class="bg" :style="{ background: bg || '#cfced3' }"></div>
    <div class="texts" v-observe-visibility="visibilityChanged">
      <img :src="require(`@/${svg}`)" :alt="alt" class="svg desktop" />
      <p>{{ p }}</p>
    </div>
    <Button :to="linkTo || '/'">
      <span v-for="(l, i) in linkTexts" :key="`link-${i}`">{{ l }}</span>
    </Button>
    <img :src="require(`@/${img}`)" class="img" />
    <img :src="require(`@/${svg}`)" class="svg mobile" />
  </div>
</template>

<script>
import Button from "@/components/Button";
export default {
  props: ["invert", "bg", "img", "svg", "alt", "p", "linkTo", "linkTexts", "show"],
  components: {
    Button,
  },
  data() {
    return {
      visible: false,
    };
  },
  methods: {
    visibilityChanged(v) {
      this.visible = v;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/variables.scss";
@import "@/scss/mixins.scss";
.preview {
  display: flex;
  margin: 2rem 1.5rem;
  overflow: hidden;
  position: relative;
  width: calc(100% - 3rem);
}
.bg {
  height: calc(100% - 3rem);
  position: absolute;
  right: -1.5rem;
  top: 1.5rem;
  width: calc(100% + 1.5rem);
  transform-origin: right;
  opacity: 0;
  @include transition($transitionMed);
  @include transform(scaleX(0.5));
}
.texts {
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  mix-blend-mode: difference;
  width: 35%;
  left: 0;
  z-index: 2;
  @include transition($transitionMed);
  @include transform(translateX(-2rem));
  .svg {
    left: 22%;
    position: relative;
    width: 100%;
  }
  p {
    font-size: 1.6rem;
    line-height: 1.6rem;
    margin-top: 1rem;
    padding: 0 10% 0 7.5rem;
    width: 100%;
  }
}
.button {
  bottom: 1.5rem;
  left: 35%;
  position: absolute;
  z-index: 2;
}
.img {
  display: block;
  position: relative;
  width: 65%;
  z-index: 1;
  @include transition($transitionMed);
  @include transform(scale(0.75));
}

.preview--inverted {
  flex-direction: row-reverse;
  .bg {
    left: -1.5rem;
    right: initial;
    transform-origin: left;
  }
  .texts {
    @include transform(translateX(2rem));
    .svg {
      left: initial;
      right: 22%;
    }
    p {
      padding: 0 2.5rem;
    }
  }
  .button {
    left: 65%;
  }
}

.preview--visible {
  .texts,
  .img,
  .bg {
    @include transform(initial);
    opacity: 1;
  }

  // .texts {
  //   .svg {
  //     left: initial;
  //     right: 22%;
  //   }
  //   p {
  //     padding: 0 2.5rem;
  //   }
  // }
  // .button {
  //   left: 65%;
  // }
}

@media screen and (max-width: $sm) {
  .texts {
    .svg {
      left: 40%;
      width: 80%;
    }
    p {
      font-size: 1.2rem;
      line-height: 1.2rem;
      padding-left: 4.5rem;
    }
  }
}
@media screen and (max-width: $s) {
  .preview--inverted,
  .preview {
    flex-direction: column-reverse;
    align-items: flex-start;
    justify-content: center;
    margin: 0;
    padding-top: 1rem;
    width: 100%;
    .texts {
      width: 100%;
      padding: 0 1rem 1rem;
      p {
        font-size: 1.6rem;
        line-height: 1.6rem;
        margin: 0;
        padding: 0 1rem 1rem;
      }
    }
    & > * {
      order: 0;
    }
  }
  .preview--inverted .bg,
  .bg {
    height: 100%;
    left: 0;
    top: 0;
    width: 100%;
  }
  .img {
    margin-left: 1rem;
    width: calc(100% - 1rem);
  }
  .button,
  .preview--inverted .button {
    left: 2rem;
    position: relative;
  }
  .svg {
    mix-blend-mode: difference;
    width: calc(100% - 2rem);
    margin: 0 1rem -9vw;
    z-index: 1;
  }
}
</style>