<template>
  <div class="showcase-content">
    <div class="showcase-about">
      <div class="txts">
        <h2 class="title">{{ showcase.ttl }}</h2>

        <span
          class="tables"
          v-for="(t, index) in showcase.tables"
          :key="`table-${index}`"
        >
          <span class="table-name" :key="`tables-name-${index}`">{{
            t.name
          }}</span>

          <span class="svg-wrapper">
            <img class="svg" :key="`news-image-${index}`" :src="t.image" />
          </span>
        </span>

        <template v-for="(t, i) in showcase.txts">
          <p :key="`txts-${i}`" v-if="typeof t === 'string'" v-html="t"></p>
          <ul :key="`txts-ul-${i}`" v-else>
            <li
              v-for="(item, j) in t"
              :key="`txts-ul-${i}-${j}`"
              v-html="item"
            ></li>
          </ul>

          <!-- request form -->
        </template>
        <p class="request-title">
          {{ showcase.request }}
        </p>

        <div class="form">
          <Form />
        </div>
      </div>

      <!-- News -->
      <div class="news" v-if="showcase.news.length !== 0">
        <span
          class="news-title news-title-1"
          v-html="$t('offer-side-title-1')"
        ></span>
        <span
          class="news-title news-title-2"
          v-html="$t('offer-side-title-2')"
        ></span>

        <a
          class="current-news"
          v-for="(n, index) in showcase.news"
          :href="n.link"
          :key="`news-link-${index}`"
          target="_blank"
        >
          <img :key="`news-image-${index}`" :src="n.image" />
          <span class="text-block" :key="`news-name-${index}`">
            <span class="news-name">
              {{ n.name }}
            </span>
          </span>
        </a>
      </div>
    </div>
    <Gallery v-if="showcase.gallery.length !== 0" :images="showcase.gallery" />
  </div>
</template>

<script>
import Form from "./../form/Form";
import Gallery from "./Gallery";
export default {
  components: {
    Form,
    Gallery,
  },
  props: ["showcase"],
};
</script>

<style lang="scss" scoped>
@import "@/scss/inner_page.scss";
@import "@/scss/transition.scss";
.showcase-content {
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 1) 0%,
    rgba(177, 181, 182, 1) 200%
  );
}
.showcase-about {
  display: flex;
  padding: 3.75rem 2.5rem 2.5rem 9rem;
  .title {
    padding-bottom: 5%;
  }
  .txts {
    position: relative;
    padding-top: 2.5rem;
    padding-right: 3%;
    width: 47%;
    .table-name {
      display: flex;
      margin-bottom: 5%;
      font-size: 20px;
      font-weight: bolder;
    }
    p {
      margin: 1.6rem 0;
    }
    ul {
      list-style: disc;
      padding-left: 1rem;
    }
  }
}

.svg {
  width: 100%;
  position: relative;
  margin-bottom: 5%;
}

.request-title {
  font-size: 1.6rem;
  padding-top: 10%;
  padding-right: 30%;
}

.news {
  align-items: flex-start;
  height: 10%;
  border-radius: 13px;
  margin-top: -6%;
  margin-left: 25%;
  background: rgba(255, 255, 255, 0.5) 0% 0% no-repeat padding-box;
  width: 25%;
  z-index: 2;
  .news-title {
    font-size: 1.1rem;
    display: flex;
    justify-content: center;
    font-weight: bolder;
  }
  .news-title-1 {
    padding-top: 10%;
  }
  .news-title-2 {
    padding-bottom: 5%;
  }
  a {
    position: relative;
    img {
      width: 100%;
      border-radius: 13px;
      padding-bottom: 5%;
    }
    .text-block {
      position: absolute;
      opacity: 0.9;
      bottom: 25px;
      right: -5px;
      background-color: white;
      color: black;
      padding-left: 10px;

      width: 90%;
      font-size: 1.6rem;
      line-height: 2rem;
      text-align: center;
    }
  }
}

@media screen and (max-width: $s) {
  .showcase-about {
    flex-direction: column;
    padding: 0;
    font-size: 16px;
    .txts {
      padding: 1rem;
      width: 100%;
    }
  }

  .svg-wrapper {
    display: flex;
    flex-wrap: no-wrap;
    overflow-x: auto;
    margin: 20px;
  }
  .svg {
    flex: 0 0 auto;
    width: auto !important;
    margin-right: 10px;
  }

  .news {
    flex-direction: column;
    margin: 0;
    width: 100%;
    .text-block {
      padding-top: 5%;
      padding-bottom: 5%;
      margin-bottom: 5%;
    }
  }
}
</style>
