<template>
  <main class="home-modal">
    <transition name="slide" appear>
      <section class="home-modal_content">
        <img
          @click="$emit('close')"
          class="close"
          src="@/assets/img/new-year-modal/close.svg"
          alt="close"
        />
        <img
          @click="goTo()"
          class="main"
          :src="
            require(`@/assets/img/new-year-modal/${
              mobile ? 'mob' : 'desktop'
            }-${$i18n.locale}.png`)
          "
          alt="new-year"
        />
      </section>
    </transition>
  </main>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      mobile: false,
    };
  },
  methods: {
    goTo() {
      this.$router.push(`/offers/nye`);
      this.$emit('close')
    },
    handleResize() {
      this.mobile = window.innerWidth <= 1280;
    },
  },

  mounted() {
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/variables.scss";
.home-modal {
  align-items: center;
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  display: flex;
  justify-content: center;
  height: 100%;
  top: 0;
  width: 100%;
  z-index: 99999;

  .home-modal_content {
    align-items: center;
    display: flex;
    justify-content: center;
    height: 85vh;
    position: relative;
    max-height: 720px;
    max-width: 1200px;
    width: 100%;

    img {
      cursor: pointer;
      object-fit: cover;

      &.close {
        top: 3%;
        right: 2%;
        position: absolute;
      }
    }
  }
}

@media screen and (max-width: $sm) {
  .home-modal {
    .home-modal_content {
      height: 100vh;
      max-width: 320px;

      img {
        &.main {
          height: 100%;
        }
      }
    }
  }
}
</style>