import { render, staticRenderFns } from "./Tawkto.vue?vue&type=template&id=683b10c3&scoped=true"
import script from "./Tawkto.vue?vue&type=script&lang=js"
export * from "./Tawkto.vue?vue&type=script&lang=js"
import style0 from "./Tawkto.vue?vue&type=style&index=0&id=683b10c3&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "683b10c3",
  null
  
)

export default component.exports