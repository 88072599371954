<template>
  <div>
    <!-- <h2>{{ $t("gallery") }}</h2> -->
    <div
      class="gallery-wrapper"
      @touchstart="handleTouchStart"
      @touchmove="handleTouchMove"
    >
      <transition-group tag="div" class="gallery" :name="`slide-${direction}`">
        <template v-for="(file, i) in images">
          <video
            ref="galleryVideo"
            v-if="regex.test(file)"
            :key="`video-${i}`"
            controls
          >
            <source :src="file" />
            Sorry, your browser doesn't support embedded videos.
          </video>
          <img :src="file" :key="`image-${i}`" v-show="active === i" v-else />
        </template>
      </transition-group>
      <div class="buttons" v-if="images.length > 1">
        <span @click="prev">
          <img src="@/assets/img/l.svg" alt="previous" />
        </span>
        <span @click="next">
          <img src="@/assets/img/r.svg" alt="next" />
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["images"],
  data() {
    return {
      direction: "left",
      active: 0,
      xDown: null,
      yDown: null,
      regex: new RegExp(".(mp4|MP4)$"),
    };
  },
  methods: {
    handleTouchStart(evt) {
      const firstTouch = evt.touches[0];
      this.xDown = firstTouch.clientX;
      this.yDown = firstTouch.clientY;
    },
    handleTouchMove(evt) {
      if (!this.xDown || !this.yDown) {
        return;
      }

      var xUp = evt.touches[0].clientX;
      var yUp = evt.touches[0].clientY;

      var xDiff = this.xDown - xUp;
      var yDiff = this.yDown - yUp;

      if (Math.abs(xDiff) > Math.abs(yDiff)) {
        // left swipe
        if (xDiff > 0) {
          this.next();
        }
        // right swipe
        else {
          this.prev();
        }
      }

      this.xDown = null;
      this.yDown = null;
    },
    stopVideo() {
      if (this.regex.test(this.images[this.active])) {
        const videos = this.$refs.galleryVideo;
        for (let v of videos) {
          v.pause();
        }
      }
    },
    prev() {
      this.stopVideo();
      this.direction = "right";
      if (this.active > 0) {
        this.active -= 1;
      } else {
        this.active = this.images.length - 1;
      }
    },
    next() {
      this.stopVideo();
      this.direction = "left";
      if (this.active < this.images.length - 1) {
        this.active += 1;
      } else {
        this.active = 0;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/variables.scss";
@import "@/scss/mixins.scss";
h2 {
  padding: 1rem 9rem;
}
.gallery-wrapper {
  position: relative;
  padding: 1rem 9rem;
}
.buttons {
  span {
    align-items: center;
    background-color: rgba($color: #fff, $alpha: 0.9);
    cursor: pointer;
    display: flex;
    height: 100px;
    left: 9rem;
    top: 50%;
    justify-content: center;
    position: absolute;
    width: 100px;
    @include transform(translateY(-50%) translateX(-1rem));
    @include noselect();
    img {
      height: 60%;
      @include transition($transition);
    }
    &:last-child {
      left: initial;
      right: 9rem;
      @include transform(translateY(-50%) translateX(1rem));
    }

    &:hover {
      img {
        @include transform(translateX(-25%));
      }
      &:last-child {
        img {
          @include transform(translateX(25%));
        }
      }
    }
  }
}
.gallery {
  height: 90vh;
  position: relative;
  overflow: hidden;
  width: 100%;
  video,
  img {
    display: block;
    height: 100%;
    left: 0;
    object-fit: cover;
    object-position: center;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.slide-left-enter-active,
.slide-right-enter-active,
.slide-left-leave-active,
.slide-right-leave-active {
  @include transition($transition);
}
.slide-left-enter {
  @include transform(translateX(100%));
}
.slide-left-leave-to {
  @include transform(translateX(-100%));
}
.slide-right-enter {
  @include transform(translateX(-100%));
}
.slide-right-leave-to {
  @include transform(translateX(100%));
}

@media screen and (max-width: $sm) {
  .gallery-wrapper {
    padding: 1rem 6rem;
  }
  .buttons {
    span {
      height: 80px;
      left: 6rem;
      width: 80px;
      &:last-child {
        left: initial;
        right: 6rem;
      }
    }
  }
}
@media screen and (max-width: $s) {
  .gallery-wrapper {
    padding: 1rem;
  }
  .gallery {
    height: 50vw;
  }
  .buttons {
    span {
      height: 50px;
      left: 1rem;
      width: 50px;
      &:last-child {
        left: initial;
        right: 1rem;
      }
    }
  }
}
</style>