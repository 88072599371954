<template>
  <transition-group tag="div" class="ny-carousel" name="slide">
    <template v-for="(img, i) in images">
      <img :src="img" :key="`nyc-${i}`" v-show="active === i" />
    </template>
  </transition-group>
</template>

<script>
export default {
  props: ["images"],
  data() {
    return {
      interval: null,
      active: 0,
    };
  },
  methods: {
    startRotation() {
      this.interval = setInterval(() => {
        if (this.active < this.images.length - 1) this.active += 1;
        else this.active = 0;
      }, 3000);
    },
    stopRotation() {
      clearInterval(this.interval);
    },
  },
  mounted() {
    this.startRotation();
  },
  destroyed() {
    this.stopRotation();
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/variables.scss";
@import "@/scss/mixins.scss";
.ny-carousel {
  overflow: hidden;
  position: relative;
  img {
    height: 100%;
    left: 0;
    object-fit: contain;
    position: absolute;
    top: 0;
    width: 100%;
  }
}
.slide-enter-active,
.slide-leave-active {
  @include transition($transition);
}
.slide-enter {
  @include transform(translateX(100%));
}
.slide-leave-to {
  @include transform(translateX(-100%));
}
</style> 