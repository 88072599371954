<template>
  <div class="inner-page">
    <header class="inner-page__header">
      <aside class="inner-page__aside">
        <transition name="slide" mode="out-in">
          <img
            :src="require(`@/assets/img/contacts/contacts_${$i18n.locale}.svg`)"
            :alt="$t('contacts-alt')"
            class="svg"
          />
        </transition>
        <p>
          <img src="@/assets/img/scroll_h.svg" class="scroll-h" />
          {{ $t("contacts-p") }}
          <br />
          <br />
          <strong>{{ $t("contacts-p-2") }}</strong>
        </p>
      </aside>
      <div class="inner-page__img"></div>
      <p class="mobile">
        {{ $t("contacts-p") }}
        <br />
        {{ $t("contacts-p-2") }}
      </p>
    </header>
    <main class="inner-page__main">
      <section class="contacts__main">
        <div class="left">
          <div class="inner-page__texts">
            <h2>{{ $t("contacts-sc1-ttl") }}</h2>
            <p class="sttl">{{ $t("contacts-sc1-sttl") }}</p>
          </div>
          <table class="contacts__table">
            <tr>
              <td>
                <p>{{ $t("contacts-table-1") }}</p>
                <p class="mobile">
                  <a href="mailto:regnum@regnum.bg"> regnum@regnum.bg</a>
                </p>
              </td>
              <td>
                <a href="mailto:regnum@regnum.bg"> regnum@regnum.bg </a>
              </td>
              <td>
                <!-- <a href="tel:+35974984000">0800 18 089</a> / -->
                <a href="tel:+359884493924">+359 884 493 924</a>
              </td>
            </tr>
            <tr>
              <td>
                <p>{{ $t("contacts-table-2") }}</p>
                <p class="mobile">
                  <a href="mailto:reception@regnum.bg">reception@regnum.bg</a>
                </p>
              </td>
              <td>
                <a href="mailto:reception@regnum.bg">reception@regnum.bg</a>
              </td>
              <td><a href="tel:+35974984000">+359 749 84 000</a></td>
            </tr>
            <tr>
              <td>
                <p>{{ $t("contacts-table-3") }}</p>
                <p class="mobile">
                  <a href="mailto:restaurant@regnum.bg">restaurant@regnum.bg</a>
                </p>
              </td>
              <td>
                <a href="mailto:restaurant@regnum.bg">restaurant@regnum.bg</a>
              </td>
              <td><a href="tel:+35974984000">+359 749 84 000</a></td>
            </tr>
            <tr>
              <td>
                <p>{{ $t("contacts-table-4") }}</p>
                <p class="mobile">
                  <a href="mailto:regnum@regnum.bg">regnum@regnum.bg</a>
                </p>
              </td>
              <td><a href="mailto:regnum@regnum.bg">regnum@regnum.bg</a></td>
              <td><a href="tel:+35974984000">+359 749 84 000</a></td>
            </tr>
            <tr>
              <td>
                <p>{{ $t("contacts-table-5") }}</p>
                <p class="mobile">
                  <a href="mailto:regnum@regnum.bg">regnum@regnum.bg</a>
                </p>
              </td>
              <td><a href="mailto:regnum@regnum.bg">regnum@regnum.bg</a></td>
              <td><a href="tel:+359877159060">+359 877 159 060</a></td>
            </tr>
            <tr>
              <td>
                <p>{{ $t("contacts-table-6") }}</p>
                <p class="mobile">
                  <a href="mailto:regnum@regnum.bg">regnum@regnum.bg</a>
                </p>
              </td>
              <td><a href="mailto:regnum@regnum.bg">regnum@regnum.bg</a></td>
              <td>
                <a href="tel:+359749830240">+359 749 83 024</a> /
                <a href="tel:+359887472730">+359 887 472 730</a>
              </td>
            </tr>
            <!-- <tr>
              <td>
                <p>{{ $t("contacts-table-7") }}</p>
                <p class="mobile">
                  <a href="mailto:kenan@regnum.bg">kenan@regnum.bg</a>
                </p>
              </td>
              <td>
                <a href="mailto:kenan@regnum.bg">kenan@regnum.bg</a>
              </td>
              <td></td>
            </tr> -->
            <tr>
              <td>
                <p>{{ $t("contacts-table-8") }}</p>
                <p class="mobile">
                  <a href="viber://chat/?number=%2B359884493924" target="_blank"
                    >+359 884 49 39 24</a
                  >
                </p>
              </td>
              <td></td>
              <td>
                <a href="viber://chat/?number=%2B359884493924" target="_blank"
                  >+359 884 49 39 24</a
                >
              </td>
            </tr>
            <tr>
              <td>
                <p>{{ $t("contacts-table-9") }}</p>
                <p class="mobile">
                  <a href="https://wa.me/359884493924" target="_blank"
                    >+359 884 49 39 24</a
                  >
                </p>
              </td>
              <td></td>
              <td>
                <a href="https://wa.me/359884493924" target="_blank"
                  >+359 884 49 39 24</a
                >
              </td>
            </tr>
          </table>

          <div class="right mobile">
            <img
              class="map1"
              :src="require(`@/assets/img/contacts/map1_${$i18n.locale}.svg`)"
              :alt="$t('alt.contacts-map-1')"
            />
            <p>
              <a href="https://g.page/regnumbansko?share" target="_blank">{{
                $t("contacts-maps-google")
              }}</a>
              <a
                href="https://maps.apple.com/?address=Pirin%20National%20Park,%20kv.%20Glazne,%202770%20Bansko,%20Bulgaria&auid=13638270321814414559&ll=41.824896,23.472682&lsp=9902&q=Regnum%20Bansko&_ext=ChgKBAgEEF8KBAgFEAMKBAgGEAsKBAgKEAASJikTuNDhF+lEQDF4+JdxoXc3QDmRjfY9PupEQ"
                target="_blank"
                >{{ $t("contacts-maps-apple") }}</a
              >
            </p>
          </div>
          <p class="sttl">{{ $t("contacts-sc2-sttl") }}</p>

          <!-- form -->
          <Form />
        </div>
        <div class="right desktop">
          <img
            class="map1"
            :src="require(`@/assets/img/contacts/map1_${$i18n.locale}.svg`)"
            :alt="$t('alt.contacts-map-1')"
          />
          <p>
            <a href="https://g.page/regnumbansko?share" target="_blank">{{
              $t("contacts-maps-google")
            }}</a>
            <a
              href="https://maps.apple.com/?address=Pirin%20National%20Park,%20kv.%20Glazne,%202770%20Bansko,%20Bulgaria&auid=13638270321814414559&ll=41.824896,23.472682&lsp=9902&q=Regnum%20Bansko&_ext=ChgKBAgEEF8KBAgFEAMKBAgGEAsKBAgKEAASJikTuNDhF+lEQDF4+JdxoXc3QDmRjfY9PupEQ"
              target="_blank"
              >{{ $t("contacts-maps-apple") }}</a
            >
          </p>
        </div>
      </section>
      <section class="contacts__map">
        <div class="inner-page__texts">
          <h2 id="getToKnowBansko">{{ $t("contacts-sc3-ttl") }}</h2>
          <p class="sttl">{{ $t("contacts-sc3-sttl") }}</p>
        </div>
        <img
          class="map2"
          :src="require(`@/assets/img/contacts/map2_${$i18n.locale}.svg`)"
          :alt="$t('alt.contacts-map-2')"
        />
      </section>
    </main>
  </div>
</template>

<script>
import Form from "./../form/Form";
export default {
  components: {
    Form,
  },
  methods: {
    mounted() {
      if (this.$route.query.bansko === "true") {
        document
          .getElementById("getToKnowBansko")
          .scrollIntoView({ behavior: "smooth" });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/inner_page.scss";
@import "@/scss/transition.scss";
.inner-page__img {
  background-image: url("../../assets/img/contacts/contacts.jpg");
}

.inner-page__main {
  background-image: url("../../assets/img/bg.jpg");
}

.inner-page__header {
  background-color: #ffeddb;
}

.contacts__main {
  display: flex;
  padding: 3rem 2.25rem 1rem 9rem;
  a {
    color: #0086ae;
    text-decoration: underline;
    white-space: nowrap;
  }
  .left {
    padding-right: 1.35rem;
    width: 46%;
  }
  .right {
    padding-left: 2.5rem;
    width: 54%;
    .map1 {
      display: block;
      width: 100%;
    }
    a:last-child {
      margin-left: 4rem;
    }
  }
}

.contacts__table {
  border-collapse: collapse;
  margin: 3rem -1.35rem;
  width: calc(100% + 2.7rem);
  tr {
    &:nth-child(even) {
      background-color: rgba($color: #b7d1e2, $alpha: 0.5);
    }
    td {
      padding: 0.65rem 1.35rem;
      &:nth-child(2) {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}

.contacts__map {
  padding: 1.6rem;
  .inner-page__texts {
    padding: 0 7.4rem;
    margin-bottom: 1rem;
  }
  .map2 {
    display: block;
    width: 100%;
  }
}

@media screen and (max-width: $sm) {
  .contacts__main {
    padding: 3rem 2.25rem 1rem 6rem;
    a {
      white-space: initial;
    }
    .left,
    .right {
      width: 50%;
    }
  }

  .contacts__map {
    .inner-page__texts {
      padding: 0 4.4rem;
    }
  }
}

@media screen and (max-width: $s) {
  .inner-page__header {
    height: initial;
    max-height: initial;
    > p {
      padding: 1rem;
    }
  }
  .inner-page__img {
    margin-left: 1rem;
    margin-top: calc(13vh + 5rem);
    width: calc(100% - 1rem);
  }
  .contacts__main {
    flex-direction: column;
    padding: 0;
    .left,
    .right {
      padding: 0;
      width: 100%;
    }
    .left > p {
      margin-top: 2rem;
      padding: 0 1rem;
    }
    .right > p {
      padding: 0 1rem;
      a {
        display: block;
        font-size: 1.6rem;
        margin: 1rem 0;
        &:last-child {
          margin: 0;
        }
      }
    }
  }
  .inner-page__texts {
    padding: 1rem;
  }
  .contacts__table,
  .contacts__form {
    margin: 3rem 0;
    width: 100%;
  }
  .contacts__table {
    margin: 0;
    tr {
      td {
        padding: 1rem;
        &:nth-child(2) {
          display: none;
        }
      }
    }
  }

  .contacts__map {
    padding: 0;
    .inner-page__texts {
      padding: 1rem 1rem 0;
    }
  }
}
</style>
