<template>
  <div
    class="footer__gallery"
    :class="{ 'footer__gallery--masage': $route.name === 'spa-services' }"
  >
    <!-- <p class="mini-title" v-if="$route.name === 'spa-services'">{{ $t("massage-inquiry") }}</p>

    <Inquiry v-if="$route.name === 'spa-services'" ref="inquiry" /> -->

    <section class="gallery-content">
      <img
        :src="
          require(`@/assets/img/home/about/eu_mask_${this.$i18n.locale}.svg`)
        "
        alt="map"
        class="map"
        :class="{ 'map--bigger-massage': $route.name === 'spa-services' }"
      />
      <div class="texts">
        <h2>{{ $t("footer-gallery-ttl") }}</h2>
        <p class="sttl">{{ $t("footer-gallery-sttl") }}</p>
      </div>
      <div class="gallery-thumbnail">
        <img
          src="../assets/img/gallery/gallery-desktop.png"
          alt="Gallery destkop image"
          class="desktop"
        />
        <img
          src="../assets/img/gallery/gallery-mobile.png"
          alt="Gallery mobile image"
          class="mobile"
        />
        <div class="gallery-thumbnail__hover" @click="openGalleryOnMobile()">
          <img
            src="../assets/img/gallery/gallery-desktop-hover.png"
            alt="Gallery desktop image on hover"
            class="desktop"
          />
          <Button to="/gallery">{{ $t("footer-gallery-button") }}</Button>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// import Inquiry from "@/views/showcases/Inquiry";
import Button from "@/components/Button";
export default {
  components: {
    Button,
    // Inquiry,
  },
  watch: {
    $route: { handler: "scrollTo" },
  },
  data() {
    return {
      visible: false,
    };
  },
  methods: {
    visibilityChanged(v) {
      this.visible = v;
    },
    openGalleryOnMobile() {
      if (window.innerWidth <= 1280) {
        this.$router.push("/gallery");
      }
    },
    scrollTo() {
      let str = this.$route.query.inquiry;
      const el = this.$refs[str];
      if (el) {
        el.$el.scrollIntoView({ block: "center", behavior: "smooth" });
      }
    },
  },
  mounted() {
    this.scrollTo();
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/variables.scss";
@import "@/scss/mixins.scss";
.footer__gallery {
  background-image: url("../assets/img/bg.jpg");
  background-position: center;
  background-size: cover;
  overflow: hidden;
  padding: 9rem 0;
  position: relative;
  width: 100%;

  &.footer__gallery--masage {
    padding: 1rem 0 6rem 0;
    .mini-title{
      text-align: center;
      margin-bottom: 1rem;
    }
  }
  .gallery-content {
    padding: 0 9rem;
  }

  .map {
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    @include transition($transitionMed);
    &.map--bigger {
      left: -75%;
      top: -50%;
      width: 200%;
    }
    &.map--bigger-massage {
      left: -55%;
      top: 0%;
      width: 170%;
    }
  }
  .texts {
    margin-bottom: 2.5rem;
    position: relative;
    z-index: 1;
  }
  .gallery-thumbnail {
    position: relative;
    width: 100%;
    z-index: 1;
    > img {
      display: block;
      width: 100%;

      &.mobile {
        display: none;
      }
    }

    .gallery-thumbnail__hover {
      align-items: center;
      display: flex;
      height: 100%;
      justify-content: center;
      left: 0;
      opacity: 0;
      position: absolute;
      top: 0;
      width: 100%;
      @include transition($transition);

      > img {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
      }

      .button {
        box-shadow: 0px 0px 99px #003f5266;
        height: 100%;
        position: relative;
        white-space: nowrap;
        width: 100%;
        z-index: 1;
        @include transition($transition);
        justify-content: center;
      }
    }
  }
}

@media screen and (min-width: $sm) {
  .footer__gallery {
    .gallery-thumbnail {
      .button {
        min-height: 128px;
        min-width: 460px;
      }
      &:hover {
        .gallery-thumbnail__hover {
          opacity: 1;
          .button {
            background-color: #fff;
            height: 1%;
            width: 1%;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $sm) {
  .footer__gallery {
    padding: 6rem 0;

    .gallery-content {
      padding: 0 6rem;
    }

    .gallery-thumbnail {
      > img {
        &.desktop {
          display: none;
        }
        &.mobile {
          display: block;
        }
      }
      .gallery-thumbnail__hover {
        > img {
          &.desktop {
            display: none;
          }
        }
        opacity: 1;
        .button {
          background-color: #fff;
          height: initial;
          padding: 2rem;
          width: initial;
        }
      }
    }
  }
}
@media screen and (max-width: $s) {
  .footer__gallery {
    padding: 0 0 3rem 0;

    .gallery-content {
      padding: 1rem 1rem 0rem;
    }

    .gallery-thumbnail {
      .gallery-thumbnail__hover {
        .button {
          background-color: #fff;
          min-height: initial;
          min-width: initial;
          height: initial;
          width: initial;
        }
      }
    }
  }
}
</style>
