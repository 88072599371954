<template>
  <div class="home-about">
    <img
      :src="require(`@/assets/img/home/about/eu_mask_${this.$i18n.locale}.svg`)"
      alt="map"
      class="map"
    />
    <img
      :src="require(`@/assets/img/home/about/bg_mask_${this.$i18n.locale}.svg`)"
      alt="map"
      class="map"
      :class="{ 'map--bigger': !visible }"
    />
    <div class="texts" v-observe-visibility="visibilityChanged">
      <h2>{{ $t('home-ab-ttl') }}</h2>
      <!-- <p class="sttl"><strong>{{ $t('home-msg') }}</strong></p> -->
      <p class="sttl">{{ $t('home-ab-sttl') }}</p>
      <div class="paragraphs">
        <div>
          <p>{{ $t('home-ab-txt1') }}</p>
          <p>{{ $t('home-ab-txt2') }}</p>
        </div>
        <div>
          <p>{{ $t('home-ab-txt3') }}</p>
          <p>{{ $t('home-ab-txt4') }}</p>
          <p>{{ $t('home-ab-txt5') }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
    };
  },
  methods: {
    visibilityChanged(v) {
      this.visible = v;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss';
@import '@/scss/mixins.scss';
.home-about {
  background-image: url('../../assets/img/bg.jpg');
  background-position: center;
  background-size: cover;
  overflow: hidden;
  padding: 9rem;
  position: relative;
  width: 100%;
}
.map {
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  @include transition($transitionMed);
  &.map--bigger {
    left: -75%;
    top: -50%;
    width: 200%;
  }
}
.texts {
  position: relative;
  width: 65%;
  z-index: 1;
}
.paragraphs {
  display: flex;
  margin-top: 2.5rem;
  div {
    width: 50%;
    &:first-child {
      margin-right: 2.5rem;
    }
    p {
      margin: 0.5rem 0;
    }
  }
}

@media screen and (max-width: $sm) {
  .home-about {
    padding: 6rem;
  }
}
@media screen and (max-width: $s) {
  .home-about {
    padding: 1rem 1rem 3rem;
  }
  .texts {
    width: 100%;
  }

  .paragraphs {
    overflow: auto;
    scroll-snap-type: x mandatory;
    div {
      min-width: 100%;
      scroll-snap-align: end;
      width: 100%;
      p {
        font-size: 1.6rem;
      }

      p:last-child {
        &::after {
          content: '➡';
          font-family: 'icons';
          font-size: 1.6rem;
          margin-left: 0.5rem;
          opacity: 0.3;
          text-align: center;
          width: 100%;
        }
      }

      &:last-child {
        p:last-child {
          &::after {
            content: '⬅';
          }
        }
      }
    }
  }
}
</style>
