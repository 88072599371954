<template>
  <div class="parallax-carousel" v-observe-visibility="visibilityChanged">
    <transition name="slide-top" appear mode="out-in">
      <div
        class="top-background"
        :key="`top-background-${active}`"
        :style="{
          background: `linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, ${
            slides[active].gradient || 'rgba(0, 63, 82, 1)'
          } 100%)`,
        }"
      ></div>
    </transition>

    <template v-for="(s, i) in slides">
      <transition name="slide" :key="`transition-${i}`">
        <Parallax
          :bg="s.bg"
          :video="s.video"
          :poster="s.poster"
          :svg="s.svg"
          :alt="s.alt"
          :gradient="s.gradient"
          :to="s.to"
          :key="`slide-${i}`"
          :arrows="slides.length > 1"
          :visible="visible"
          :mobile="mobile"
          @prev="prev"
          @next="next"
          @ended="next"
          v-if="i === active"
        />
      </transition>
    </template>

    <transition name="slide-bottom" appear mode="out-in">
      <footer :key="`footer-${page}`">
        <img
          src="@/assets/img/left_white.svg"
          alt="Previous"
          class="arrow"
          v-if="slides.length > 1"
          @click="prev"
        />
        <div
          v-for="(s, i) in currentSlides"
          :class="{ active: i === active - limit * page }"
          :key="`current-${i + limit * page}`"
          @click="changeSlide(i + limit * page)"
        >
          <img :src="require(`@/${s.svg}`)" :alt="s.alt" />
        </div>
        <img
          src="@/assets/img/right_white.svg"
          alt="Next"
          class="arrow"
          v-if="slides.length > 1"
          @click="next"
        />
        <transition name="slide-bottom" appear mode="out-in">
          <div
            class="background"
            :key="`background-${active}`"
            :style="{
              background: `linear-gradient(0deg, ${
                slides[active].gradient || 'rgba(0, 63, 82, 1)'
              } 0%, rgba(255,255,255,0) 100%)`,
            }"
          ></div>
        </transition>
      </footer>
    </transition>
    <div class="weather" v-if="weather">
      <div>
        <img
          :src="require(`@/assets/img/weather/${weather.weather[0].icon}.png`)"
          :alt="$t('alt.icon')"
        />
        <span>{{ weather.main.temp.toFixed(1) }}&deg;C</span>
      </div>
      <p>
        {{ weather.weather[0].description
        }}<template v-if="weather.snow"
          >,<br />{{ weather.snow["3h"] || weather.snow["1h"] }}{{ $t("new-snow") }}</template
        >
      </p>
    </div>
  </div>
</template>

<script>
import Parallax from "@/components/Parallax";
export default {
  props: ["slides"],
  components: {
    Parallax,
  },
  data() {
    return {
      interval: null,
      visible: false,
      speed: 12000,
      active: 0,
      limit: 4,
      page: 0,
      mobile: false,
      weather: null,
    };
  },
  watch: {
    "$i18n.locale": {
      handler: "getWeatherData",
      immediate: true,
    },
  },
  computed: {
    currentSlides() {
      if (this.slides.length > this.limit) {
        let current = [];

        current = this.slides.slice(
          this.limit * this.page,
          this.limit * (this.page + 1)
        );

        return current;
      } else return this.slides;
    },
  },
  methods: {
    visibilityChanged(v) {
      this.visible = v;
      if (v) {
        this.start();
      } else {
        this.stop();
      }
    },
    changeSlide(s) {
      this.stop();
      this.active = s;
      this.page = Math.floor(this.active / this.limit);
      this.start();
    },
    prev() {
      if (!this.active) this.changeSlide(this.slides.length - 1);
      else this.changeSlide(this.active - 1);
    },
    next() {
      if (this.active < this.slides.length - 1)
        this.changeSlide(this.active + 1);
      else this.changeSlide(0);
    },
    start() {
      this.interval = setInterval(() => {
        if (this.slides[this.active].wait && !this.mobile) {
          return this.stop();
        }

        if (this.active < this.slides.length - 1) {
          this.active += 1;
        } else {
          this.active = 0;
        }

        this.page = Math.floor(this.active / this.limit);
      }, this.speed);
    },
    stop() {
      clearInterval(this.interval);
    },
    handleResize() {
      this.mobile = window.innerWidth <= 768;
    },
    getWeatherData() {
      this.weather = null;
      const lat = "41.82481718759037";
      const lon = "23.472389085770082";
      fetch(
        `https://api.openweathermap.org/data/2.5/weather?lat=${lat}&lon=${lon}&units=metric&lang=${this.$i18n.locale}&APPID=e3e8f892961b314f8c8bbb419293609d`
      )
        .then((response) => response.json())
        .then((data) => {
          this.weather = data;
        });
    },
  },
  mounted() {
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
  },
  destroyed() {
    this.stop();
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/variables.scss";
@import "@/scss/mixins.scss";
@import "@/scss/transition.scss";
.parallax-carousel {
  background: #f3f3f3;
  height: 100vh;
  max-height: 100vh;
  min-height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  max-height: calc(var(--vh, 1vh) * 100);
  min-height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;
  position: relative;
  width: 100%;
  .parallax {
    left: 0;
    position: absolute;
    top: 0;
  }

  .top-background {
    left: 0;
    position: absolute;
    top: 0;
    height: 15vh;
    width: 100%;
    z-index: 1;
  }

  footer {
    align-items: center;
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    padding: 2rem 0;
    position: absolute;
    width: 100%;

    > img {
      position: relative;
      z-index: 1;
      margin: 0 1rem;
      @include hover();
    }

    > div:not(.background) {
      margin: 0 5px;
      width: 10vw;
      position: relative;
      @include hover();
      z-index: 1;

      &.active {
        width: 12vw;
      }
      img {
        width: 100%;
      }
    }

    .background {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }
}

.arrow {
  width: 3vw;
}

.weather {
  align-items: center;
  bottom: 2rem;
  color: #fff;
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 1rem;
  text-shadow: 0px 2px 11px #003f52;
  z-index: 1;
  div {
    align-items: center;
    display: flex;
    img {
      height: auto;
      margin-right: 0.25rem;
      filter: drop-shadow(0px 2px 11px #003f52);
      width: 3rem;
    }
    span {
      font-size: 23px;
      font-weight: 500;
    }
  }
  p {
    font-size: 14px;
    font-weight: 500;
    margin-top: 0.35rem;
    text-align: center;
    padding-top: 0.35rem;
    position: relative;
    &::first-letter {
      text-transform: uppercase;
    }
    &::before {
      background-color: #fff;
      content: "";
      height: 1px;
      left: calc(50% - 12.5px);
      position: absolute;
      top: 0;
      width: 25px;
    }
  }
}

@media screen and (max-width: $s) {
  .parallax-carousel {
    footer {
      display: none;
    }
  }
  .weather {
    align-items: center;
    bottom: initial;
    flex-direction: row-reverse;
    left: 50%;
    right: initial;
    top: 100px;
    @include transform(translateX(-50%));
    div {
      span {
        font-size: 14px;
      }
    }
    p {
      font-size: 14px;
      margin: 0 10px 0 0;
      padding: 5px 10px 5px 0;

      &::before {
        height: 100%;
        top: 0;
        left: 100%;
        width: 1px;
      }
    }
  }
}

.slide-enter-active,
.slide-leave-active {
  @include transition(all 1s);
}
.slide-enter {
  opacity: 0.5;
  @include transform(translateX(100%) scale(0.5));
}
.slide-leave-to {
  opacity: 0.5;
  @include transform(translateX(-100%) scale(0.5));
}
</style>