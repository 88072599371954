var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"inner-page"},[_c('header',{staticClass:"inner-page__header"},[_c('aside',{staticClass:"inner-page__aside"},[_c('transition',{attrs:{"name":"slide","mode":"out-in"}},[_c('img',{staticClass:"svg",attrs:{"src":require(`@/assets/img/loyal/svg_${_vm.$i18n.locale}.svg`)}})]),_c('p',[_c('img',{staticClass:"scroll-h",attrs:{"src":require("@/assets/img/scroll_h.svg")}}),_vm._v(" "+_vm._s(_vm.$t("loyalty-p"))+" ")])],1),_c('div',{staticClass:"inner-page__img"})]),_c('main',{staticClass:"inner-page__main"},[_c('About'),_c('section',{staticClass:"inner-page__section"},[_c('p',{staticClass:"mobile"},[_vm._v(_vm._s(_vm.$t("loyalty-sc1-txt")))]),_c('p',{staticClass:"mobile"},[_vm._v(_vm._s(_vm.$t("loyalty-sc1-txt1")))]),_c('p',{staticClass:"mobile"},[_vm._v(_vm._s(_vm.$t("loyalty-sc1-txt2")))]),_c('p',{staticClass:"mobile"},[_vm._v(_vm._s(_vm.$t("loyalty-sc1-txt3")))]),_c('p',{staticClass:"mobile"},[_vm._v(_vm._s(_vm.$t("loyalty-sc1-txt4")))]),_c('p',{staticClass:"mobile"},[_vm._v(_vm._s(_vm.$t("loyalty-sc1-txt5")))]),_c('Slideshow',{attrs:{"reversed":true,"images":[
          require('@/assets/img/loyal/s1-1.jpg'),
          require('@/assets/img/loyal/s1-2.jpg'),
          require('@/assets/img/loyal/s1-3.jpg'),
        ]}}),_c('div',{staticClass:"inner-page__texts"},[_c('h2',[_vm._v(_vm._s(_vm.$t("loyalty-sc1-ttl")))]),_c('p',{staticClass:"sttl"},[_vm._v(_vm._s(_vm.$t("loyalty-sc1-sttl")))]),_c('p',{staticClass:"desktop"},[_vm._v(_vm._s(_vm.$t("loyalty-sc1-txt")))]),_c('p',{staticClass:"desktop"},[_vm._v(_vm._s(_vm.$t("loyalty-sc1-txt1")))]),_c('p',{staticClass:"desktop"},[_vm._v(_vm._s(_vm.$t("loyalty-sc1-txt2")))]),_c('p',{staticClass:"desktop"},[_vm._v(_vm._s(_vm.$t("loyalty-sc1-txt3")))]),_c('p',{staticClass:"desktop"},[_vm._v(_vm._s(_vm.$t("loyalty-sc1-txt4")))]),_c('p',{staticClass:"desktop"},[_vm._v(_vm._s(_vm.$t("loyalty-sc1-txt5")))])])],1),_c('section',{staticClass:"inner-page__section section--reversed section--top"},[_c('div',{staticClass:"inner-page__texts"},[_c('h2',[_vm._v(_vm._s(_vm.$t("loyalty-sc2-ttl")))]),_c('p',{staticClass:"sttl"},[_vm._v(_vm._s(_vm.$t("loyalty-sc2-sttl")))]),_c('p',{staticClass:"desktop"},[_vm._v(_vm._s(_vm.$t("loyalty-sc2-txt")))]),_c('p',{staticClass:"desktop"},[_vm._v(_vm._s(_vm.$t("loyalty-sc2-txt1")))]),_c('p',{staticClass:"desktop"},[_vm._v(_vm._s(_vm.$t("loyalty-sc2-txt2")))]),_c('p',{staticClass:"desktop"},[_vm._v(_vm._s(_vm.$t("loyalty-sc2-txt3")))]),_c('p',{staticClass:"desktop"},[_vm._v(_vm._s(_vm.$t("loyalty-sc2-txt4")))]),_c('p',{staticClass:"desktop"},[_vm._v(_vm._s(_vm.$t("loyalty-sc2-txt5")))]),_c('p',{staticClass:"desktop"},[_vm._v(_vm._s(_vm.$t("loyalty-sc2-txt6")))]),_c('p',{staticClass:"desktop"},[_vm._v(_vm._s(_vm.$t("loyalty-sc2-txt7")))])]),_c('Slideshow',{attrs:{"images":[
          require('@/assets/img/loyal/s2-1.jpg'),
          require('@/assets/img/loyal/s2-2.jpg'),
          require('@/assets/img/loyal/s2-3.jpg'),
        ]}}),_c('div',{staticClass:"inner-page__section"},[_c('h2',{staticClass:"mobile"},[_vm._v(_vm._s(_vm.$t("loyalty-sc2-ttl")))]),_c('p',{staticClass:"mobile"},[_vm._v(_vm._s(_vm.$t("loyalty-sc2-sttl")))]),_c('p',{staticClass:"mobile"},[_vm._v(_vm._s(_vm.$t("loyalty-sc2-txt")))]),_c('p',{staticClass:"mobile"},[_vm._v(_vm._s(_vm.$t("loyalty-sc2-txt1")))]),_c('p',{staticClass:"mobile"},[_vm._v(_vm._s(_vm.$t("loyalty-sc2-txt2")))]),_c('p',{staticClass:"mobile"},[_vm._v(_vm._s(_vm.$t("loyalty-sc2-txt3")))]),_c('p',{staticClass:"mobile"},[_vm._v(_vm._s(_vm.$t("loyalty-sc2-txt4")))]),_c('p',{staticClass:"mobile"},[_vm._v(_vm._s(_vm.$t("loyalty-sc2-txt5")))]),_c('p',{staticClass:"mobile"},[_vm._v(_vm._s(_vm.$t("loyalty-sc2-txt6")))]),_c('p',{staticClass:"mobile"},[_vm._v(_vm._s(_vm.$t("loyalty-sc2-txt7")))])])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }