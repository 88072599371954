<template>
  <main
    class="inner-about"
    :class="{ 'inner-about--spa': $route.name === 'spa-services' }"
  >
    <!-- <div
      class="inner-about__img"
      :class="{ 'inner-about__img--rotated': visible }"
      v-observe-visibility="{ callback: visibilityChanged, throttle: 2000, }"
    >
      <img src="@/assets/img/spa-s/rotate.webp" />
    </div> -->
    <header>
      <h2>{{ $t("spa-s-ab-ttl") }}</h2>
      <p class="sttl">{{ $t("spa-s-ab-sttl") }}</p>
    </header>
    <section class="test">
      <div class="inner-about__paragraphs">
        <div>
          <p>{{ $t("spa-s-ab-txt1") }}</p>
          <p>{{ $t("spa-s-ab-txt2") }}</p>
          <p>{{ $t("spa-s-ab-txt5") }}</p>
        </div>
        <div>
          <p>{{ $t("spa-s-ab-txt8") }}</p>
          <p>{{ $t("spa-s-ab-txt9") }}</p>
        </div>
      </div>
    </section>

    <section class="info--massage" v-for="c in 8" :key="c">
      <h2>{{ $t(`spa-s-ab-m-type${c}`) }}</h2>

      <div class="massages-images">
        <img
          :src="
            require(`@/assets/img/spa/spa-services/m${c}_t1_${$i18n.locale}.svg`)
          "
          alt=""
        />
        <img
          :style="c == 1 ? 'margin-bottom:-50px;' : ''"
          :src="
            require(`@/assets/img/spa/spa-services/m${c}_t2_${$i18n.locale}.svg`)
          "
          alt=""
        />
      </div>
    </section>
    <p class="vat">{{ $t("spa-s-ab-vat") }}</p>
  </main>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
    };
  },
  methods: {
    visibilityChanged(v) {
      this.visible = v;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/inner_about.scss";
.info--massage {
  padding-top: 40px;

  h2 {
    font-size: 60px;
    text-shadow: none;
    text-align: center;
    overflow: hidden;
    margin-bottom: 40px;

    &::before,
    &:after {
      background-color: #f1d5c8;
      bottom: 5px;
      content: "";
      display: inline-block;
      height: 2px;
      position: relative;
      vertical-align: middle;
      width: 50%;
    }

    &:before {
      right: 0.5em;
      margin-left: -50%;
    }
    &:after {
      left: 0.5em;
      margin-right: -50%;
    }
  }
  .massages-images {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    img {
      width: calc(50% - 20px);
    }
  }
}
@media screen and (max-width: $sm) {
  .info--massage {
    h2 {
      font-size: 2.5rem;
    }
    .massages-images {
      flex-direction: column;
      gap: 20px;

      img {
        width: 100%;
      }
    }
  }
}
</style>