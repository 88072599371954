<template>
  <form class="halls-form">
    <p class="halls-form-title">{{ $t("form-halls-title") }}</p>

    <div class="halls-form-fields">
      <input type="text" :placeholder="$t('form-name')" v-model="inquiry.name" required />

      <div class="grid-container">
      <input class="email" type="email" :placeholder="$t('form-email')" v-model="inquiry.email" required />
      <input class="phone" type="text" :placeholder="$t('form-phone')" v-model="inquiry.phone" required />

      <div class="start-date" :class="{'dropdown--active': dropdown[0]}">

      </div>

      <div class="end-date" :class="{'dropdown--active': dropdown[1]}">

      </div>

      </div>
    </div>
  </form>
</template>

<script>
export default {
  data() {
    return {
      inquiry: {
        name: "",
        email: "",
        phone: "",
        message: "",
      },
      agreed: false,
      success: false,

      dropdown: [false, false],
      today: new Date(),
    };
  },
};
</script>

<style lang="scss" scoped>
.halls-form {
  width: 47%;
  padding-left: 6rem;
  padding-right: 3%;

  .halls-form-title {
    font-size: 1.6rem;
  }

  .halls-form-fields {
    display: flex;
    flex-direction: column;
    margin: 3rem -1.35rem;

    input,
    .start-date,
    .end-date {
      background-color: rgba($color: #b7d1e2, $alpha: 0.5);
      border: none;
      outline: none;
      padding: 0.65rem 1.35rem;
      margin-bottom: 0.5rem;
      margin-right: 0.5rem;
    }
  }
}

.email {
  grid-area: email;
}
.phone {
  grid-area: phone;
}
.start-date {
  grid-area: start-date;
}
.end-date {
  grid-area: end-date;
}

.grid-container {
  grid-template-areas: 
  'email phone'
  'start-date end-date';
}
</style>
