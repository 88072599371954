var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"inner-page inner-page--spa"},[_c('header',{staticClass:"inner-page__header"},[_c('aside',{staticClass:"inner-page__aside"},[_c('transition',{attrs:{"name":"slide","mode":"out-in"}},[_c('img',{staticClass:"svg",attrs:{"src":require(`@/assets/img/spa-s/svg_${_vm.$i18n.locale}.svg`),"alt":_vm.$t('spa-s-alt')}})]),_c('p',[_c('img',{staticClass:"scroll-h",attrs:{"src":require("@/assets/img/scroll_h.svg")}}),_vm._l((_vm.$t('spa-s-info')),function(text){return [_vm._v(" "+_vm._s(text)+" ")]})],2)],1),_c('div',{staticClass:"inner-page__img"})]),_c('main',{staticClass:"inner-page__main"},[_c('About'),_c('section',{staticClass:"inner-page__section"},[_c('Slideshow',{attrs:{"reversed":true,"images":[
          require('@/assets/img/spa-s/s1-1.jpg'),
          require('@/assets/img/spa-s/s1-2.jpg'),
          require('@/assets/img/spa-s/s1-3.jpg'),
        ]}}),_c('div',{staticClass:"inner-page__texts"},[_c('h2',[_vm._v(_vm._s(_vm.$t("spa-s-sc1-ttl")))]),_c('p',{staticClass:"sttl",domProps:{"innerHTML":_vm._s(_vm.$t('spa-s-sc1-sttl'))}}),_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t('spa-s-sc1-txt'))}})])],1),_c('section',{staticClass:"inner-page__section section--reversed section--top"},[_c('div',{staticClass:"inner-page__texts"},[_c('h2',[_vm._v(_vm._s(_vm.$t("spa-s-sc2-ttl")))]),_c('p',{staticClass:"sttl",domProps:{"innerHTML":_vm._s(_vm.$t('spa-s-sc1-sttl'))}}),_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t('spa-s-sc2-sttl1'))}})]),_c('Slideshow',{attrs:{"images":[
          require('@/assets/img/spa-s/s2-1.jpg'),
          require('@/assets/img/spa-s/s2-2.jpg'),
          require('@/assets/img/spa-s/s2-3.jpg'),
        ]}})],1),_c('section',{staticClass:"inner-page__section"},[_c('Slideshow',{attrs:{"reversed":true,"images":[
          require('@/assets/img/spa-s/s3-1.jpg'),
          require('@/assets/img/spa-s/s3-2.jpg'),
          require('@/assets/img/spa-s/s3-3.jpg'),
        ]}}),_c('div',{staticClass:"inner-page__texts"},[_c('h2',[_vm._v(_vm._s(_vm.$t("spa-s-sc3-ttl")))]),_c('p',{staticClass:"sttl",domProps:{"innerHTML":_vm._s(_vm.$t('spa-s-sc1-sttl'))}}),_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t('spa-s-sc3-sttl1'))}})])],1),_c('section',{staticClass:"inner-page__section section--reversed section--top"},[_c('div',{staticClass:"inner-page__texts"},[_c('h2',[_vm._v(_vm._s(_vm.$t("spa-s-sc4-ttl")))]),_c('p',{staticClass:"sttl",domProps:{"innerHTML":_vm._s(_vm.$t('spa-s-sc1-sttl'))}}),_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t('spa-s-sc4-txt1'))}})]),_c('Slideshow',{attrs:{"images":[
          require('@/assets/img/spa-s/s4-1.jpg'),
          require('@/assets/img/spa-s/s4-2.jpg'),
          require('@/assets/img/spa-s/s4-3.jpg'),
        ]}})],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }