import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/home/Home.vue";
import Showcases from "@/views/showcases/Showcases.vue";
import Restaurant from "@/views/restaurant/Restaurant.vue";
// import SpaServices from "@/views/spa/SpaServices.vue";
// import SpaOffers from "@/views/spa/SpaOffers.vue";
import SpaWellnessAndFitness from "@/views/spa/SpaWellnessAndFitness.vue";
import Loyalty from "@/views/loyalty/Loyalty.vue";
import Banya from "@/views/banya/Banya.vue";
import Aquapark from "@/views/aquapark/Aquapark.vue";
import Kids from "@/views/kids/Kids.vue";
import Contacts from "@/views/contacts/Contacts.vue";
import Terms from "@/views/terms/Terms.vue";
import Aem from "@/views/aem/aem.vue";
import Gallery from "@/views/gallery/Gallery.vue";
import Menus from "@/views/menus/Menus.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/rooms/:id?",
    name: "rooms",
    component: Showcases,
  },
  {
    path: "/halls/:id?",
    name: "halls",
    component: Showcases,
  },
  {
    path: "/news/:id?",
    name: "news",
    component: Showcases,
  },
  {
    path: "/offers/nye-bosphorus",
    name: "offers-nye-b",
    component: () => import("@/views/showcases/NewYearB.vue"),
  },
  {
    path: "/offers/nye",
    name: "offers-nye-b",
    component: () => import("@/views/showcases/NewYear.vue"),
  },
  {
    path: "/offers/nye-restaurant",
    name: "offers-nye-r",
    component: () => import("@/views/showcases/NewYearR.vue"),
  },
  {
    path: "/offers/vip-ski-pass",
    name: "vip-ski-pass",
    component: () => import("@/views/showcases/Vipskipass.vue"),
  },
  {
    path: "/offers/:id?",
    name: "offers",
    component: Showcases,
  },
  {
    path: "/services",
    name: "services",
    component: Showcases,
  },
  {
    path: "/spa",
    name: "spa",
    component: Showcases,
  },
  {
    path: "/restaurant",
    name: "restaurant",
    component: Restaurant,
  },
  {
    path: "/spa/services",
    name: "spa-services",
    component: Showcases,
  },
  // {
  //   path: "/spa/offers",
  //   name: "spa-offers",
  //   component: SpaOffers,
  // },
  {
    path: "/spa/wellness-and-fitness",
    name: "spa-wellness-and-fitness",
    component: SpaWellnessAndFitness,
  },
  {
    path: "/loyalty",
    name: "loyalty",
    component: Loyalty,
  },
  {
    path: "/banya",
    name: "banya",
    component: Banya,
  },
  {
    path: "/aquapark",
    name: "aquapark",
    component: Aquapark,
  },
  {
    path: "/kids",
    name: "kids",
    component: Kids,
  },
  {
    path: "/contacts",
    name: "contacts",
    component: Contacts,
  },
  {
    path: "/terms",
    name: "terms",
    component: Terms,
  },
  {
    path: "/aem",
    name: "aem",
    component: Aem,
  },
  {
    path: "/menus",
    name: "menus",
    component: Menus,
  },
  {
    path: "/gallery",
    name: "gallery",
    component: Gallery,
  },
  {
    path: "/404",
    name: "404",
    component: Showcases,
  },
  {
    path: "/*",
    redirect: "/404",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
