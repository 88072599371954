<template>
  <div class="not-found">
    <img src="@/assets/img/404.svg" alt="404" class="desktop" />
    <img src="@/assets/img/404m.svg" alt="404" class="mobile" />
    <main>
      <h2>
        {{ $t("404.page") }}
        <br />
        {{ $t("404.doesnt-exist") }} :(
      </h2>
      <ButtonSmaller class="big" @click="goTo('offers')">
        <p>
          {{ $t("404.offers") }}
        </p>
      </ButtonSmaller>
      <ButtonSmaller class="big" @click="goTo('rooms')">
        <p>
          {{ $t("404.rooms") }}
        </p>
      </ButtonSmaller>
      <ButtonSmaller class="big" @click="goTo('contacts')">
        <p>
          {{ $t("404.contacts") }}
        </p>
      </ButtonSmaller>
    </main>
  </div>
</template>

<script>
import ButtonSmaller from "@/components/ButtonSmaller";
export default {
  components: {
    ButtonSmaller,
  },
  methods: {
    goTo(url) {
      this.$router.push(`/${url}`);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/variables.scss";
.not-found {
  align-items: center;
  color: #fff;
  display: flex;
  height: 100%;
  justify-content: center;
  position: absolute;
  width: 100%;
  z-index: 1;

  > img {
    display: block;
    height: 23rem;

    &.mobile {
      display: none;
    }
  }

  > main {
    margin-left: 2rem;

    button {
      margin-top: 2rem;
    }
  }
}

@media screen and (max-width: $s) {
  .not-found {
    flex-direction: column;

    > img {
      height: initial;
      width: 75vw;
      &.desktop {
        display: none;
      }
      &.mobile {
        display: block;
      }
    }

    > main {
      margin: 2rem 0 0;
      padding: 0 2rem;
      width: 100%;
      h2 {
        text-align: center;
      }
      button {
        height: initial;
        min-height: 50px;
        padding-bottom: 16px;
        padding-top: 16px;
        white-space: initial;
      }
    }
  }
}
</style>