<template>
  <div class="inner-about">
    <!-- <div
      class="inner-about__img"
      :class="{ 'inner-about__img--rotated': visible }"
      v-observe-visibility="{ callback: visibilityChanged, throttle: 2000, }"
    >
      <img src="@/assets/img/banya/rotate.webp" />
    </div> -->
    <div class="inner-about__texts">
      <header>
        <h2>{{ $t("banya-ab-ttl") }}</h2>
        <p class="sttl">{{ $t("banya-ab-sttl") }}</p>
      </header>
      <div class="inner-about__paragraphs">
        <div>
          <p>{{ $t("banya-ab-txt1") }}</p>
          <!-- <p>{{ $t("banya-ab-txt2") }}</p> -->
        </div>
        <div>
          <p>{{ $t("banya-ab-txt2") }}</p>
          <p>{{ $t("banya-ab-txt3") }}</p>
          <p>{{ $t("banya-ab-txt4") }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
    };
  },
  methods: {
    visibilityChanged(v) {
      this.visible = v;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/inner_about.scss";
</style>