<template>
  <div
    class="tawk-to-toggle"
    :class="{ scrolled: y, hidden: hidden }"
    v-if="show"
    @click="toggle"
  >
    <span>
      {{ $t("chat") }}
    </span>
    <svg
      id="chat"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <rect id="Canvas" width="24" height="24" fill="#003f52" opacity="0" />
      <path
        id="Path_13456"
        data-name="Path 13456"
        d="M12.543,7.32a.66.66,0,0,1,.66.66V15.9a.66.66,0,0,1-.66.66H6.328a.66.66,0,0,0-.467.193l-1.9,1.9V17.223a.66.66,0,0,0-.66-.66H1.98a.66.66,0,0,1-.66-.66V7.98a.66.66,0,0,1,.66-.66ZM1.98,6A1.98,1.98,0,0,0,0,7.981V15.9a1.98,1.98,0,0,0,1.98,1.98h.66v3.164a.33.33,0,0,0,.564.233l3.4-3.4h5.941a1.98,1.98,0,0,0,1.98-1.98V7.98A1.98,1.98,0,0,0,12.543,6Z"
        transform="translate(0.117 2.039)"
      />
      <path
        id="Path_13457"
        data-name="Path 13457"
        d="M13.922,9.318a3.037,3.037,0,0,0-3.037-3.037H6V2.98A1.98,1.98,0,0,1,7.981,1H19.863a1.98,1.98,0,0,1,1.98,1.98V10.9a1.98,1.98,0,0,1-1.98,1.98H17.883v3.164a.33.33,0,0,1-.564.233l-3.4-3.4Z"
        transform="translate(2.039 0.437)"
      />
    </svg>
  </div>
</template>

<script>
export default {
  props: ["y", "hidden"],
  computed: {
    show() {
      return window.Tawk_API;
    },
  },
  methods: {
    toggle() {
      window.Tawk_API.popup();

      // window.Tawk_API.onChatMaximized = function () {
      //   console.log('ok')
      // };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/variables.scss";
@import "@/scss/transition.scss";
.tawk-to-toggle {
  align-items: center;
  border: 1px solid #fff;
  border-left: 0;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-size: 18px;
  font-weight: 500;
  justify-content: flex-end;
  height: 40px;
  left: 0;
  padding: 9px 10px;
  position: absolute;
  top: 30px;
  width: 90px;
  z-index: 11;
  @include transition($transition);

  svg {
    margin-left: 10px;
    path {
      @include transition($transition);
      fill: #fff;
    }
  }

  &.scrolled {
    border-color: $mainColor;
    color: $mainColor;
    top: 15px;
    svg {
      path {
        fill: $mainColor;
      }
    }
  }
}

@media screen and (min-width: $s) {
  .tawk-to-toggle {
    &:hover {
      width: 142px;
    }
  }
}
@media screen and (max-width: $s) {
  .tawk-to-toggle {
    &.hidden {
      opacity: 0;
      z-index: 0;
    }
  }
}
</style>