<template>
  <div
    class="dropdown"
    :class="{
      'dropdown--date': type === 'date',
      'dropdown--active': open,
      'dropdown--disabled': disabled,
      'dropdown--invalid': invalid,
    }"
    @click="!disabled ? (open = !open) : null"
    v-click-outside="clickedOutside"
  >
    <p>{{ placeholder }}</p>
    <p v-if="type === 'date'">{{ value | formatDate }}</p>
    <p v-else>{{ value || $t('select') }}</p>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="8.25"
      viewBox="0 0 15 8.25"
    >
      <path
        id="dd"
        d="M1498.72,3513.97a.749.749,0,0,1,1.06,0l6.22,6.219,6.22-6.219a.75.75,0,0,1,1.06,1.06l-6.75,6.75a.749.749,0,0,1-1.06,0l-6.75-6.75A.749.749,0,0,1,1498.72,3513.97Z"
        transform="translate(-1498.5 -3513.75)"
        fill="#003f52"
        fill-rule="evenodd"
      />
    </svg>
    <transition name="dropdown">
      <div class="dropdown__content" v-show="open" @click.stop>
        <Datepicker
          :language="getLang"
          :inline="true"
          :monday-first="true"
          :minimumView="'day'"
          :maximumView="'day'"
          :disabled-dates="disabledDates"
          :full-month-name="true"
          :value="value"
          @input="$emit('input', $event)"
          @selected="open = false"
          v-if="type === 'date'"
        />

        <template v-else>
          <p
            v-for="(d, i) in data"
            :key="`data-${i}`"
            @click="$emit('input', d), (open = false)"
          >
            {{ d }}
          </p>
        </template>
      </div>
    </transition>
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";
// eslint-disable-next-line
import { bg, en, ru, tr, el } from "vuejs-datepicker/dist/locale";
import Datepicker from "vuejs-datepicker";
export default {
  directives: {
    ClickOutside,
  },
  components: {
    Datepicker,
  },
  props: [
    "type",
    "value",
    "placeholder",
    "disabledDates",
    "data",
    "disabled",
    "invalid"
  ],
  computed: {
    getLang() {
      return this[this.$i18n.locale];
    },
  },
  data() {
    return {
      bg,
      en,
      open: false,
    };
  },
  methods: {
    clickedOutside() {
      if (this.open) {
        this.open = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/variables.scss";
@import "@/scss/mixins.scss";
.dropdown {
  border: 1px solid rgba($color: $mainColor, $alpha: 0.66);
  background-color: rgba($color: $mainColor, $alpha: 0.1);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 70px;
  justify-content: center;
  // margin: 10px 0;
  padding: 0 2.5rem 0 1rem;
  position: relative;
  width: 100%;
  @include noselect();
  > svg {
    position: absolute;
    right: 0.5rem;
    top: 50%;
    @include transform(translateY(-50%));
    @include transition($transition);
  }
  p {
    font-size: 1.3rem;
    overflow: hidden;
    text-overflow: ellipsis;
    @include noselect();
  }
  > p {
    &:first-of-type {
      font-size: 0.6rem;
      left: 1rem;
      position: absolute;
      text-transform: uppercase;
      top: 10px;
      @include transition($transition);
    }

    &:last-of-type {
      margin-top: 0.6rem;
      line-height: 1.3rem;
    }
  }
  .dropdown__content {
    background-color: #e5ebed;
    border: 1px solid rgba($color: $mainColor, $alpha: 0.66);
    top: calc(100% + 10px);
    left: 0;
    position: absolute;
    transform-origin: top;
    width: 100%;
    z-index: 10;
    p {
      padding: 0.25rem 0.5rem;
      white-space: initial;
      @include transition(all 0.1s $cubic);

      &:hover {
        background-color: rgba($color: $mainColor, $alpha: 0.1);
      }
    }
  }
  &:not(.dropdown--date) {
    white-space: nowrap;
  }
  &.dropdown--date {
    width: 15rem;
    .dropdown__content {
      padding: 0.5rem;
    }
  }
  &.dropdown--room {
    width: 25rem;
  }

  &.dropdown--active {
    > svg {
      @include transform(translateY(-50%) scale(-1));
    }
  }
  &.dropdown--disabled {
    cursor: default;
    opacity: 0.5;
  }
  &.dropdown--invalid {
    background-color: rgba($color: $mainError, $alpha: 0.1);
    border-color: $mainError;
  }
}

.dropdown-enter-active,
.dropdown-leave-active {
  @include transition($transition);
  // transform-origin: top;
}
.dropdown-enter,
.dropdown-leave-to {
  @include transform(scaleY(0));
}

@media screen and (max-width: $s) {
  .dropdown {
    height: 50px;
    > p {
      &:first-of-type {
        font-size: 0.9rem;
      }
    }
  }
}
</style>