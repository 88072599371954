<template>
  <div
    class="preview-carousel"
    @touchstart="handleTouchStart"
    @touchmove="handleTouchMove"
    v-observe-visibility="visibilityChanged"
  >
    <template v-for="(p, i) in previews">
      <transition-group
        tag="div"
        :name="`slide-${direction}`"
        :key="`transition-${i}`"
        mode="in-out"
        ref="carousel"
      >
        <Preview
          :bg="p.bg"
          :img="p.img"
          :svg="p.svg"
          :alt="p.alt"
          :p="p.p"
          :linkTo="p.linkTo"
          :linkTexts="p.linkTexts"
          :key="`preview-${i}`"
          :show="visible"
          v-show="i === active"
        />
      </transition-group>
    </template>
    <div class="toggle-ul-wrapper">
      <ul class="toggle-ul">
        <li
          v-for="(p, i) in previews"
          :key="`toggle-${i}`"
          :class="{ active: i === active }"
          @click="change(i)"
        ></li>
      </ul>
    </div>
  </div>
</template>

<script>
import Preview from '@/components/Preview';
export default {
  props: ['previews'],
  components: {
    Preview,
  },
  data() {
    return {
      interval: null,
      speed: 10000,
      active: 0,
      direction: 'left',
      dragStartedAt: 0,
      dragOffset: 0,
      xDown: null,
      yDown: null,
      visible: false,
    };
  },
  methods: {
    visibilityChanged(v) {
      this.visible = v;
      if (v) {
        this.start();
      } else {
        this.stop();
      }
    },
    prev(d, restart) {
      if (restart) {
        this.stop();
      }
      if (d) {
        this.direction = d;
      }
      if (this.active > 0) {
        this.active -= 1;
      } else {
        this.active = this.previews.length - 1;
      }
      if (restart) {
        this.start();
      }
    },
    next(d, restart) {
      if (restart) {
        this.stop();
      }
      if (d) {
        this.direction = d;
      }
      if (this.active < this.previews.length - 1) {
        this.active += 1;
      } else {
        this.active = 0;
      }
      if (restart) {
        this.start();
      }
    },
    start() {
      this.interval = setInterval(() => {
        this.next('left');
      }, this.speed);
    },
    change(n) {
      if (n < this.active) {
        this.direction = 'right';
      } else {
        this.direction = 'left';
      }

      this.stop();
      this.active = n;
      this.start();
    },
    stop() {
      clearInterval(this.interval);
    },
    handleTouchStart(evt) {
      const firstTouch = evt.touches[0];
      this.xDown = firstTouch.clientX;
      this.yDown = firstTouch.clientY;
    },
    handleTouchMove(evt) {
      if (!this.xDown || !this.yDown) {
        return;
      }

      var xUp = evt.touches[0].clientX;
      var yUp = evt.touches[0].clientY;

      var xDiff = this.xDown - xUp;
      var yDiff = this.yDown - yUp;

      if (Math.abs(xDiff) > Math.abs(yDiff)) {
        // left swipe
        if (xDiff > 0) {
          this.next('left', true);
        }
        // right swipe
        else {
          this.prev('right', true);
        }
      }
      // else {
      //   if (yDiff > 0) {
      //     /* up swipe */
      //   } else {
      //     /* down swipe */
      //   }
      // }

      this.xDown = null;
      this.yDown = null;
    },
  },
  destroyed() {
    this.stop();
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss';
@import '@/scss/mixins.scss';
.preview-carousel {
  height: 90vh;
  display: flex;
  margin: 2rem 0;
  overflow: hidden;
  position: relative;
  width: 100%;
  @include noselect();
  // @include transition($transition);
  .preview {
    height: 100%;
    margin-bottom: 0;
    margin-top: 0;

    left: 0;
    position: absolute;
    top: 0;
    min-width: 100vw;
  }
}
.toggle-ul-wrapper {
  align-items: center;
  bottom: 0;
  display: flex;
  height: 1.5rem;
  mix-blend-mode: difference;
  padding: 0 1.5rem;
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 2;
  .toggle-ul {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 35%;
    li {
      background-color: rgba($color: #fff, $alpha: 0.2);
      border-radius: 10px;
      cursor: pointer;
      display: inline-block;
      height: 4px;
      margin: 0 3px;
      width: 9.8%;
      @include transition($transition);
      &:hover {
        background-color: rgba($color: #fff, $alpha: 0.5);
      }
      &.active {
        background-color: #fff;
      }
    }
  }
}
.slide-left-enter-active,
.slide-right-enter-active,
.slide-left-leave-active,
.slide-right-leave-active {
  @include transition($transition);
}
.slide-left-enter {
  @include transform(translateX(100%) scale(0.5));
}
.slide-left-leave-to {
  @include transform(translateX(-100%) scale(0.5));
}
.slide-right-enter {
  @include transform(translateX(-100%) scale(0.5));
}
.slide-right-leave-to {
  @include transform(translateX(100%) scale(0.5));
}

@media screen and (max-width: $s) {
  .preview-carousel {
    margin: 0;
    height: 150vw;
  }
  .toggle-ul-wrapper {
    .toggle-ul {
      width: 100%;
    }
  }
}
</style>