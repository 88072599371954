<template>
  <div class="showcases-wrapper">
    <header
      :style="{
        'background-color': opened !== null ? type[opened].color : '#cfced3',
      }"
    >
      <transition name="fade">
        <div class="close" v-if="opened !== null" @click="closeShowcase()">
          <strong>{{ $t("back") }}</strong>
        </div>
      </transition>
      <img src="@/assets/img/scroll_t.svg" class="scroll-t mobile" />
      <aside>
        <transition name="slide" mode="out-in">
          <img
            :src="
              require(`@/assets/img/${$route.name}/svg_${$i18n.locale}.svg`)
            "
            class="svg"
            v-if="opened === null"
            key="showcases-svg"
          />
          <h2
            :key="`showcases-news-title-${opened}`"
            v-else-if="$route.name === 'news'"
          >
            {{ type[opened].ttl }}
          </h2>
          <img
            class="svg"
            :src="type[opened].svg"
            :alt="type[opened].name"
            :key="`showcases-svg-${opened}`"
            v-else
          />
        </transition>
        <transition name="fade" mode="out-in">
          <p v-if="opened !== null" key="description">
            <img src="@/assets/img/scroll_h.svg" class="scroll-h" />
            {{ type[opened].description }}
          </p>
          <p v-else key="no-description">
            <img src="@/assets/img/scroll_h.svg" class="scroll-h" />
            {{ $t(`${$route.name}-description`) }}
          </p>
        </transition>
      </aside>
      <div class="showcases" id="showcases">
        <div
          v-for="(r, i) in type"
          class="showcase"
          :class="{
            active: opened === i,
            inactive: opened !== i && opened !== null,
          }"
          :key="`showcase-${i}`"
          :style="{
            'background-image': `url(${r.bg})`,
          }"
          @click="openShowcase(r)"
        >
          <!-- <footer> -->
          <footer>
            <p>
              <span v-for="(n, j) in r.name" :key="`r-${i}-n-${j}`">{{
                n
              }}</span>
            </p>
            <div class="info">
              <template v-if="$route.name === 'rooms'">
                <p>
                  <strong>{{ $t("area") }}:</strong>
                  {{ r.area }}
                </p>
                <p>
                  <strong>{{ $t("beds") }}:</strong>
                  {{ r.beds }}
                </p>
                <p>
                  <strong>{{ $t("bathroom") }}:</strong>
                  {{ r.bathroom }}
                </p>
              </template>
              <template v-else-if="$route.name === 'halls'">
                <p>
                  <strong>{{ $t("area") }}:</strong>
                  {{ r.area }}
                </p>
                <p>
                  <strong>{{ $t("size") }}:</strong>
                  {{ r.size }}
                </p>
                <p>
                  <strong>{{ $t("places") }}:</strong>
                  {{ r.places }}
                </p>
              </template>
              <template
                v-else-if="
                  $route.name === 'news' ||
                  $route.name === 'services' ||
                  $route.name === 'spa'
                "
              >
                <p class="can-wrap">
                  {{ r.subname }}
                </p>
              </template>
              <template v-else-if="$route.name === 'offers'">
                <p>
                  {{ r.validation }}
                </p>
                <p>
                  <span v-html="r.dates"></span>
                </p>
                <p>
                  {{ r.includes }}
                  <span v-html="r.promotion"></span>
                </p>
              </template>
            </div>
          </footer>
        </div>
      </div>
    </header>
    <transition name="slide-bottom">
      <template v-if="$route.name === 'offers'">
        <Offer v-if="opened !== null" :showcase="type[opened]" />
      </template>
      <template v-else>
        <Showcase
          v-if="opened !== null"
          :showcase="type[opened]"
          :previousShowcase="
            opened > 0 ? type[opened - 1] : type[type.length - 1]
          "
          :nextShowcase="opened < type.length - 1 ? type[opened + 1] : type[0]"
        /> </template
    ></transition>
  </div>
</template>

<script>
import Showcase from "./Showcase";
import Offer from "./Offer";
export default {
  components: {
    Showcase,
    Offer,
  },
  computed: {
    type() {
      return this[this.$route.name];
    },
    getTallest() {
      const sc = document.getElementsByClassName("showcase__footer");
      let tallest = 0;
      for (let s of sc) {
        if (tallest < s.offsetHeight) {
          tallest = s.offsetHeight;
        }
      }

      return tallest;
    },
  },
  data() {
    return {
      rooms: [
        {
          id: 1,
          bg: require("@/assets/img/rooms/1/bg.jpg"),
          svg: require(`@/assets/img/rooms/1/svg_${this.$i18n.locale}.svg`),
          name: this.$t("room-1-name"),
          area: this.$t("room-1-area"),
          beds: this.$t("room-1-beds"),
          bathroom: this.$t("room-1-bathroom"),
          description: this.$t("room-1-description"),
          ttl: this.$t("room-1-ttl"),
          sttl: this.$t("room-1-sttl"),
          txts: this.$t("room-1-txts"),
          gallery: [
            // if you need to add videos, just require it the same way as an image
            // * works only for mp4 files
            // require("@/assets/img/home/parallax/video.mp4"),
            require("@/assets/img/rooms/1/g1.jpg"),
            require("@/assets/img/rooms/1/g2.jpg"),
            require("@/assets/img/rooms/1/g3.jpg"),
            require("@/assets/img/rooms/1/g4.jpg"),
            require("@/assets/img/rooms/1/g5.jpg"),
            require("@/assets/img/rooms/1/g6.jpg"),
          ],
          aboutImages: [
            require("@/assets/img/rooms/1/a1.jpg"),
            require("@/assets/img/rooms/1/a2.jpg"),
            require("@/assets/img/rooms/1/a3.jpg"),
          ],
          color: "#CFCED3",
          features: [1, 2, 3, 4, 5, 6, 7, 8],
          optionalFeatures: [4, 5, 6],
        },
        {
          id: 2,
          bg: require("@/assets/img/rooms/2/bg.jpg"),
          svg: require(`@/assets/img/rooms/2/svg_${this.$i18n.locale}.svg`),
          name: this.$t("room-2-name"),
          area: this.$t("room-2-area"),
          beds: this.$t("room-2-beds"),
          bathroom: this.$t("room-2-bathroom"),
          description: this.$t("room-2-description"),
          ttl: this.$t("room-2-ttl"),
          sttl: this.$t("room-2-sttl"),
          txts: this.$t("room-2-txts"),
          gallery: [
            require("@/assets/img/rooms/2/g1.jpg"),
            require("@/assets/img/rooms/2/g3.jpg"),
            require("@/assets/img/rooms/2/g2.jpg"),
            require("@/assets/img/rooms/2/g4.jpg"),
            require("@/assets/img/rooms/2/g5.jpg"),
            require("@/assets/img/rooms/2/g6.jpg"),
          ],
          aboutImages: [
            require("@/assets/img/rooms/2/a1.jpg"),
            require("@/assets/img/rooms/2/a2.jpg"),
            require("@/assets/img/rooms/2/a3.jpg"),
          ],
          color: "#D7DEE4",
          features: [1, 2, 3, 4, 5, 6, 7, 8],
          optionalFeatures: [2, 3, 4, 5, 6, 7, 8],
        },
        {
          id: 3,
          bg: require("@/assets/img/rooms/3/bg.jpg"),
          svg: require(`@/assets/img/rooms/3/svg_${this.$i18n.locale}.svg`),
          name: this.$t("room-3-name"),
          area: this.$t("room-3-area"),
          beds: this.$t("room-3-beds"),
          bathroom: this.$t("room-3-bathroom"),
          description: this.$t("room-3-description"),
          ttl: this.$t("room-3-ttl"),
          sttl: this.$t("room-3-sttl"),
          txts: this.$t("room-3-txts"),
          gallery: [
            require("@/assets/img/rooms/3/g1.jpg"),
            require("@/assets/img/rooms/3/g3.jpg"),
            require("@/assets/img/rooms/3/g2.jpg"),
            require("@/assets/img/rooms/3/g4.jpg"),
            require("@/assets/img/rooms/3/g5.jpg"),
            require("@/assets/img/rooms/3/g6.jpg"),
            require("@/assets/img/rooms/3/g7.jpg"),
            require("@/assets/img/rooms/3/g8.jpg"),
            require("@/assets/img/rooms/3/g9.jpg"),
            require("@/assets/img/rooms/3/g10.jpg"),
            require("@/assets/img/rooms/3/g11.jpg"),
          ],
          aboutImages: [
            require("@/assets/img/rooms/3/a1.jpg"),
            require("@/assets/img/rooms/3/a2.jpg"),
            require("@/assets/img/rooms/3/a3.jpg"),
          ],
          color: "#E5E1D8",
          features: [1, 2, 3, 4, 5, 6, 7, 8],
          optionalFeatures: [2, 3, 4, 5, 6, 7, 8],
        },
        {
          id: 4,
          bg: require("@/assets/img/rooms/4/bg.jpg"),
          svg: require(`@/assets/img/rooms/4/svg_${this.$i18n.locale}.svg`),
          name: this.$t("room-4-name"),
          area: this.$t("room-4-area"),
          beds: this.$t("room-4-beds"),
          bathroom: this.$t("room-4-bathroom"),
          description: this.$t("room-4-description"),
          ttl: this.$t("room-4-ttl"),
          sttl: this.$t("room-4-sttl"),
          txts: this.$t("room-4-txts"),
          gallery: [
            require("@/assets/img/rooms/4/g1.jpg"),
            require("@/assets/img/rooms/4/g3.jpg"),
            require("@/assets/img/rooms/4/g2.jpg"),
            require("@/assets/img/rooms/4/g4.jpg"),
            require("@/assets/img/rooms/4/g5.jpg"),
            require("@/assets/img/rooms/4/g6.jpg"),
            require("@/assets/img/rooms/4/g7.jpg"),
          ],
          aboutImages: [
            require("@/assets/img/rooms/4/a1.jpg"),
            require("@/assets/img/rooms/4/a2.jpg"),
            require("@/assets/img/rooms/4/a3.jpg"),
          ],
          color: "#F5EAE1",
          features: [1, 2, 3, 4, 5, 6, 7, 8],
          optionalFeatures: [2, 3, 4, 5, 6, 7, 8],
        },
        {
          id: 5,
          bg: require("@/assets/img/rooms/5/bg.jpg"),
          svg: require(`@/assets/img/rooms/5/svg_${this.$i18n.locale}.svg`),
          name: this.$t("room-5-name"),
          area: this.$t("room-5-area"),
          beds: this.$t("room-5-beds"),
          bathroom: this.$t("room-5-bathroom"),
          description: this.$t("room-5-description"),
          ttl: this.$t("room-5-ttl"),
          sttl: this.$t("room-5-sttl"),
          txts: this.$t("room-5-txts"),
          gallery: [
            require("@/assets/img/rooms/5/g1.jpg"),
            require("@/assets/img/rooms/5/g3.jpg"),
            require("@/assets/img/rooms/5/g2.jpg"),
            require("@/assets/img/rooms/5/g4.jpg"),
            require("@/assets/img/rooms/5/g5.jpg"),
            require("@/assets/img/rooms/5/g6.jpg"),
            require("@/assets/img/rooms/5/g7.jpg"),
            require("@/assets/img/rooms/5/g8.jpg"),
            require("@/assets/img/rooms/5/g9.jpg"),
            require("@/assets/img/rooms/5/g10.jpg"),
            require("@/assets/img/rooms/5/g11.jpg"),
          ],
          aboutImages: [
            require("@/assets/img/rooms/5/a1.jpg"),
            require("@/assets/img/rooms/5/a2.jpg"),
            require("@/assets/img/rooms/5/a3.jpg"),
          ],
          color: "#E7ECEF",
          features: [1, 2, 3, 4, 5, 6, 7, 8],
          optionalFeatures: [2, 3, 4, 5, 6, 7, 8],
        },
        {
          id: 6,
          bg: require("@/assets/img/rooms/6/bg.jpg"),
          svg: require(`@/assets/img/rooms/6/svg_${this.$i18n.locale}.svg`),
          name: this.$t("room-6-name"),
          area: this.$t("room-6-area"),
          beds: this.$t("room-6-beds"),
          bathroom: this.$t("room-6-bathroom"),
          description: this.$t("room-6-description"),
          ttl: this.$t("room-6-ttl"),
          sttl: this.$t("room-6-sttl"),
          txts: this.$t("room-6-txts"),
          gallery: [
            require("@/assets/img/rooms/6/g1.jpg"),
            require("@/assets/img/rooms/6/g3.jpg"),
            require("@/assets/img/rooms/6/g2.jpg"),
            require("@/assets/img/rooms/6/g4.jpg"),
            require("@/assets/img/rooms/6/g5.jpg"),
            require("@/assets/img/rooms/6/g6.jpg"),
            require("@/assets/img/rooms/6/g7.jpg"),
            require("@/assets/img/rooms/6/g8.jpg"),
          ],
          aboutImages: [
            require("@/assets/img/rooms/6/a1.jpg"),
            require("@/assets/img/rooms/6/a2.jpg"),
            require("@/assets/img/rooms/6/a3.jpg"),
          ],
          color: "#FFEFEF",
          features: [1, 2, 3, 4, 5, 6, 7, 8],
          optionalFeatures: [2, 3, 4, 5, 6, 7, 8],
        },
        {
          id: 7,
          bg: require("@/assets/img/rooms/7/bg.jpg"),
          svg: require(`@/assets/img/rooms/7/svg_${this.$i18n.locale}.svg`),
          name: this.$t("room-7-name"),
          area: this.$t("room-7-area"),
          beds: this.$t("room-7-beds"),
          bathroom: this.$t("room-7-bathroom"),
          description: this.$t("room-7-description"),
          ttl: this.$t("room-7-ttl"),
          sttl: this.$t("room-7-sttl"),
          txts: this.$t("room-7-txts"),
          gallery: [
            require("@/assets/img/rooms/7/g1.jpg"),
            require("@/assets/img/rooms/7/g3.jpg"),
            require("@/assets/img/rooms/7/g2.jpg"),
            require("@/assets/img/rooms/7/g4.jpg"),
            require("@/assets/img/rooms/7/g5.jpg"),
            require("@/assets/img/rooms/7/g6.jpg"),
            require("@/assets/img/rooms/7/g7.jpg"),
            require("@/assets/img/rooms/7/g8.jpg"),
            require("@/assets/img/rooms/7/g9.jpg"),
            require("@/assets/img/rooms/7/g10.jpg"),
          ],
          aboutImages: [
            require("@/assets/img/rooms/7/a1.jpg"),
            require("@/assets/img/rooms/7/a2.jpg"),
            require("@/assets/img/rooms/7/a3.jpg"),
          ],
          color: "#F8E8D7",
          features: [1, 2, 3, 4, 5, 6, 7, 8],
          optionalFeatures: [2, 3, 4, 5, 6, 7, 8],
        },
        {
          id: 8,
          bg: require("@/assets/img/rooms/8/bg.jpg"),
          svg: require(`@/assets/img/rooms/8/svg_${this.$i18n.locale}.svg`),
          name: this.$t("room-8-name"),
          area: this.$t("room-8-area"),
          beds: this.$t("room-8-beds"),
          bathroom: this.$t("room-8-bathroom"),
          description: this.$t("room-8-description"),
          ttl: this.$t("room-8-ttl"),
          sttl: this.$t("room-8-sttl"),
          txts: this.$t("room-8-txts"),
          gallery: [
            require("@/assets/img/rooms/8/g1.jpg"),
            require("@/assets/img/rooms/8/g3.jpg"),
            require("@/assets/img/rooms/8/g2.jpg"),
            require("@/assets/img/rooms/8/g4.jpg"),
            require("@/assets/img/rooms/8/g5.jpg"),
          ],
          aboutImages: [
            require("@/assets/img/rooms/8/a1.jpg"),
            require("@/assets/img/rooms/8/a2.jpg"),
            require("@/assets/img/rooms/8/a3.jpg"),
          ],
          color: "#EBE3E3",
          features: [1, 2, 3, 4, 5, 6, 7, 8],
          optionalFeatures: [2, 3, 4, 5, 6, 7, 8],
        },
        {
          id: 9,
          bg: require("@/assets/img/rooms/9/bg.jpg"),
          svg: require(`@/assets/img/rooms/9/svg_${this.$i18n.locale}.svg`),
          name: this.$t("room-9-name"),
          area: this.$t("room-9-area"),
          beds: this.$t("room-9-beds"),
          bathroom: this.$t("room-9-bathroom"),
          description: this.$t("room-9-description"),
          ttl: this.$t("room-9-ttl"),
          sttl: this.$t("room-9-sttl"),
          txts: this.$t("room-9-txts"),
          gallery: [
            require("@/assets/img/rooms/9/g1.jpg"),
            require("@/assets/img/rooms/9/g3.jpg"),
            require("@/assets/img/rooms/9/g2.jpg"),
            require("@/assets/img/rooms/9/g4.jpg"),
            require("@/assets/img/rooms/9/g5.jpg"),
            require("@/assets/img/rooms/9/g6.jpg"),
            require("@/assets/img/rooms/9/g7.jpg"),
            require("@/assets/img/rooms/9/g8.jpg"),
            require("@/assets/img/rooms/9/g9.jpg"),
            require("@/assets/img/rooms/9/g10.jpg"),
          ],
          aboutImages: [
            require("@/assets/img/rooms/9/a1.jpg"),
            require("@/assets/img/rooms/9/a2.jpg"),
            require("@/assets/img/rooms/9/a3.jpg"),
          ],
          color: "#D1CCC8",
          features: [1, 2, 3, 4, 5, 6, 7, 8],
          optionalFeatures: [2, 3, 4, 5, 6, 7, 8],
        },
        {
          id: 10,
          bg: require("@/assets/img/rooms/10/bg.jpg"),
          svg: require(`@/assets/img/rooms/10/svg_${this.$i18n.locale}.svg`),
          name: this.$t("room-10-name"),
          area: this.$t("room-10-area"),
          beds: this.$t("room-10-beds"),
          bathroom: this.$t("room-10-bathroom"),
          description: this.$t("room-10-description"),
          ttl: this.$t("room-10-ttl"),
          sttl: this.$t("room-10-sttl"),
          txts: this.$t("room-10-txts"),
          gallery: [
            require("@/assets/img/rooms/10/g1.jpg"),
            require("@/assets/img/rooms/10/g3.jpg"),
            require("@/assets/img/rooms/10/g2.jpg"),
            require("@/assets/img/rooms/10/g4.jpg"),
            require("@/assets/img/rooms/10/g5.jpg"),
            require("@/assets/img/rooms/10/g6.jpg"),
            require("@/assets/img/rooms/10/g7.jpg"),
            require("@/assets/img/rooms/10/g8.jpg"),
            require("@/assets/img/rooms/10/g9.jpg"),
            require("@/assets/img/rooms/10/g10.jpg"),
            require("@/assets/img/rooms/10/g11.jpg"),
            require("@/assets/img/rooms/10/g12.jpg"),
            require("@/assets/img/rooms/10/g13.jpg"),
            require("@/assets/img/rooms/10/g14.jpg"),
          ],
          aboutImages: [
            require("@/assets/img/rooms/10/a1.jpg"),
            require("@/assets/img/rooms/10/a2.jpg"),
            require("@/assets/img/rooms/10/a3.jpg"),
          ],
          color: "#EEEEEE",
          features: [1, 2, 3, 4, 5, 6, 7, 8],
          optionalFeatures: [2, 3, 4, 5, 6, 7, 8],
        },
        // {
        //   id: 11,
        //   bg: require("@/assets/img/rooms/11/bg.jpg"),
        //   svg: require(`@/assets/img/rooms/11/svg_${this.$i18n.locale}.svg`),
        //   name: this.$t("room-11-name"),
        //   area: this.$t("room-11-area"),
        //   beds: this.$t("room-11-beds"),
        //   bathroom: this.$t("room-11-bathroom"),
        //   description: this.$t("room-11-description"),
        //   ttl: this.$t("room-11-ttl"),
        //   sttl: this.$t("room-11-sttl"),
        //   txts: this.$t("room-11-txts"),
        //   gallery: [
        //     require("@/assets/img/rooms/11/g1.jpg"),
        //     require("@/assets/img/rooms/11/g2.jpg"),
        //   ],
        //   aboutImages: [
        //     require("@/assets/img/rooms/11/a1.jpg"),
        //     require("@/assets/img/rooms/11/a2.jpg"),
        //     require("@/assets/img/rooms/11/a3.jpg"),
        //     require("@/assets/img/rooms/11/g4.jpg"),
        //     require("@/assets/img/rooms/11/g5.jpg"),
        //     require("@/assets/img/rooms/11/g6.jpg"),
        //     require("@/assets/img/rooms/11/g7.jpg"),
        //     require("@/assets/img/rooms/11/g8.jpg"),
        //     require("@/assets/img/rooms/11/g9.jpg"),
        //   ],
        //   color: "#EEEEEE",
        //   features: [1, 2, 3, 4, 5, 6, 7, 8],
        //   optionalFeatures: [1, 2, 3, 4, 5, 6, 7, 8],
        // },
      ],
      halls: [
        {
          id: 1,
          bg: require("@/assets/img/halls/1/bg.jpg"),
          svg: require(`@/assets/img/halls/1/svg_${this.$i18n.locale}.svg`),
          name: this.$t("hall-1-name"),
          area: this.$t("hall-1-area"),
          size: this.$t("hall-1-size"),
          places: this.$t("hall-1-places"),
          description: this.$t("hall-1-description"),
          ttl: this.$t("hall-1-ttl"),
          sttl: this.$t("hall-1-sttl"),
          txts: this.$t("hall-1-txts"),
          gallery: [
            require("@/assets/img/halls/1/g1.jpg"),
            require("@/assets/img/halls/1/g2.jpg"),
          ],
          aboutImages: [
            require("@/assets/img/halls/1/a1.jpg"),
            require("@/assets/img/halls/1/a2.jpg"),
            require("@/assets/img/halls/1/a3.jpg"),
          ],
          color: "#E4D7D7",
          features: [1, 2, 3, 4, 5, 6],
          optionalFeatures: [1, 2, 4],
        },
        {
          id: 2,
          bg: require("@/assets/img/halls/2/bg.jpg"),
          svg: require(`@/assets/img/halls/2/svg_${this.$i18n.locale}.svg`),
          name: this.$t("hall-2-name"),
          area: this.$t("hall-2-area"),
          size: this.$t("hall-2-size"),
          places: this.$t("hall-2-places"),
          description: this.$t("hall-2-description"),
          ttl: this.$t("hall-2-ttl"),
          sttl: this.$t("hall-2-sttl"),
          txts: this.$t("hall-2-txts"),
          gallery: [
            require("@/assets/img/halls/2/g1.jpg"),
            require("@/assets/img/halls/2/g2.jpg"),
            require("@/assets/img/halls/2/g3.jpg"),
            require("@/assets/img/halls/2/g4.jpg"),
            require("@/assets/img/halls/2/g5.jpg"),
          ],
          aboutImages: [
            require("@/assets/img/halls/2/a1.jpg"),
            require("@/assets/img/halls/2/a2.jpg"),
            require("@/assets/img/halls/2/a3.jpg"),
          ],
          color: "#E4E4D7",
          features: [1, 2, 3, 4, 5, 6],
          optionalFeatures: [1, 2, 3, 4, 6],
        },
        {
          id: 3,
          bg: require("@/assets/img/halls/3/bg.jpg"),
          svg: require(`@/assets/img/halls/3/svg_${this.$i18n.locale}.svg`),
          name: this.$t("hall-3-name"),
          area: this.$t("hall-3-area"),
          size: this.$t("hall-3-size"),
          places: this.$t("hall-3-places"),
          description: this.$t("hall-3-description"),
          ttl: this.$t("hall-3-ttl"),
          sttl: this.$t("hall-3-sttl"),
          txts: this.$t("hall-3-txts"),
          gallery: [
            require("@/assets/img/halls/3/g1.jpg"),
            require("@/assets/img/halls/3/g2.jpg"),
          ],
          aboutImages: [
            require("@/assets/img/halls/3/a1.jpg"),
            require("@/assets/img/halls/3/a2.jpg"),
            require("@/assets/img/halls/3/a3.jpg"),
          ],
          color: "#E4DED7",
          features: [1, 2, 3, 4, 5, 6],
          optionalFeatures: [1, 2, 3, 4, 6],
        },
        {
          id: 4,
          bg: require("@/assets/img/halls/4/bg.jpg"),
          svg: require(`@/assets/img/halls/4/svg_${this.$i18n.locale}.svg`),
          name: this.$t("hall-4-name"),
          area: this.$t("hall-4-area"),
          size: this.$t("hall-4-size"),
          places: this.$t("hall-4-places"),
          description: this.$t("hall-4-description"),
          ttl: this.$t("hall-4-ttl"),
          sttl: this.$t("hall-4-sttl"),
          txts: this.$t("hall-4-txts"),
          gallery: [
            require("@/assets/img/halls/4/g1.jpg"),
            require("@/assets/img/halls/4/g2.jpg"),
          ],
          aboutImages: [
            require("@/assets/img/halls/4/a1.jpg"),
            require("@/assets/img/halls/4/a2.jpg"),
            require("@/assets/img/halls/4/a3.jpg"),
          ],
          color: "#E0D7E4",
          features: [1, 2, 3, 4, 5, 6],
          optionalFeatures: [1, 2, 3, 4, 5, 6],
        },
        {
          id: 5,
          bg: require("@/assets/img/halls/5/bg.jpg"),
          svg: require(`@/assets/img/halls/5/svg_${this.$i18n.locale}.svg`),
          name: this.$t("hall-5-name"),
          area: this.$t("hall-5-area"),
          size: this.$t("hall-5-size"),
          places: this.$t("hall-5-places"),
          description: this.$t("hall-5-description"),
          ttl: this.$t("hall-5-ttl"),
          sttl: this.$t("hall-5-sttl"),
          txts: this.$t("hall-5-txts"),
          gallery: [
            require("@/assets/img/halls/5/g1.jpg"),
            require("@/assets/img/halls/5/g2.jpg"),
            require("@/assets/img/halls/5/g3.jpg"),
            require("@/assets/img/halls/5/g4.jpg"),
          ],
          aboutImages: [
            require("@/assets/img/halls/5/a1.jpg"),
            require("@/assets/img/halls/5/a2.jpg"),
            require("@/assets/img/halls/5/a3.jpg"),
          ],
          color: "#EAEAEC",
          features: [1, 2, 3, 4, 5, 6],
          optionalFeatures: [1, 2, 3, 4, 5, 6],
        },
      ],
      news: [
        {
          id: 10,
          bg: require("@/assets/img/news/1/bg.jpg"),
          name: this.$t("news-10-name"),
          subname: this.$t("news-10-subname"),
          description: this.$t("news-10-description"),
          ttl: this.$t("news-10-ttl"),
          sttl: this.$t("news-10-sttl"),
          txts: this.$t("news-10-txts"),
          color: "#163d8e",
        },
        {
          id: 8,
          bg: require("@/assets/img/news/8/8.jpg"),
          name: this.$t("news-8-name"),
          subname: this.$t("news-8-subname"),
          description: this.$t("news-8-description"),
          ttl: this.$t("news-8-ttl"),
          sttl: this.$t("news-8-sttl"),
          txts: this.$t("news-8-txts"),
          color: "#FDFDFD",
        },
        {
          id: 7,
          bg: require("@/assets/img/news/7/7.jpg"),
          name: this.$t("news-7-name"),
          subname: this.$t("news-7-subname"),
          description: this.$t("news-7-description"),
          ttl: this.$t("news-7-ttl"),
          sttl: this.$t("news-7-sttl"),
          txts: this.$t("news-7-txts"),
          color: "#FDFDFD",
        },
        {
          id: 6,
          bg: require("@/assets/img/news/6.jpg"),
          name: this.$t("news-6-name"),
          subname: this.$t("news-6-subname"),
          description: this.$t("news-6-description"),
          ttl: this.$t("news-6-ttl"),
          sttl: this.$t("news-6-sttl"),
          txts: this.$t("news-6-txts"),
          color: "#FDFDFD",
        },
        {
          id: 4,
          bg: require("@/assets/img/news/4.jpg"),
          name: this.$t("news-4-name"),
          subname: this.$t("news-4-subname"),
          description: this.$t("news-4-description"),
          ttl: this.$t("news-4-ttl"),
          sttl: this.$t("news-4-sttl"),
          txts: this.$t("news-4-txts"),
          color: "#FDFDFD",
        },
        {
          id: 5,
          bg: require("@/assets/img/news/5.jpg"),
          name: this.$t("news-5-name"),
          subname: this.$t("news-5-subname"),
          description: this.$t("news-5-description"),
          ttl: this.$t("news-5-ttl"),
          sttl: this.$t("news-5-sttl"),
          txts: this.$t("news-5-txts"),
          color: "#F8F9FE",
        },
        // {
        //   id: 2,
        //   bg: require('@/assets/img/news/2/bg.jpg'),
        //   name: this.$t('news-2-name'),
        //   subname: this.$t('news-2-subname'),
        //   description: this.$t('news-2-description'),
        //   ttl: this.$t('news-2-ttl'),
        //   sttl: this.$t('news-2-sttl'),
        //   txts: this.$t('news-2-txts'),
        //   color: '#FFCECE',
        // },
        // {
        //   id: 3,
        //   bg: require('@/assets/img/news/3/bg.jpg'),
        //   name: this.$t('news-3-name'),
        //   subname: this.$t('news-3-subname'),
        //   description: this.$t('news-3-description'),
        //   ttl: this.$t('news-3-ttl'),
        //   sttl: this.$t('news-3-sttl'),
        //   txts: this.$t('news-3-txts'),
        //   color: '#FFE8CE',
        // },
        {
          id: 1,
          bg: require("@/assets/img/news/1/bg.jpg"),
          name: this.$t("news-1-name"),
          subname: this.$t("news-1-subname"),
          description: this.$t("news-1-description"),
          ttl: this.$t("news-1-ttl"),
          sttl: this.$t("news-1-sttl"),
          txts: this.$t("news-1-txts"),
          color: "#163d8e",
        },
      ],
      services: [
        {
          bg: require("@/assets/img/banya/bg.jpg"),
          name: this.$t("services-1-name"),
          subname: this.$t("home-pr-banya-p"),
          to: "/banya",
        },
        {
          bg: require("@/assets/img/aquapark/bg.jpg"),
          name: this.$t("services-2-name"),
          subname: this.$t("home-pr-aquapark-p"),
          to: "/aquapark",
        },
        {
          bg: require("@/assets/img/spa/bg.jpg"),
          name: this.$t("services-3-name"),
          subname: this.$t("home-pr-spa-p"),
          to: "/spa",
        },
        {
          bg: require("@/assets/img/kids/bg.jpg"),
          name: this.$t("services-4-name"),
          subname: this.$t("home-pr-kids-p"),
          to: "/kids",
        },
      ],
      offers: [
        //  {
        //   id: 202108,
        //   bg: require("@/assets/img/offers/202108/bg.jpg"),
        //   svg: require(`@/assets/img/offers/202108/svg_${this.$i18n.locale}.svg`),
        //   name: this.$t("offer-202108-name"),
        //   validation: this.$t("offer-202108-validation"),
        //   dates: this.$t("offer-202108-dates"),
        //   includes: this.$t("offer-202108-includes"),
        //   promotion: this.$t("offer-202108-promotion"),
        //   description: this.$t("offer-202108-description"),
        //   ttl: this.$t("offer-202108-ttl"),
        //   tables: [
        //     {
        //       name: this.$t("offer-202108-table-1-name"),
        //       image: require(`@/assets/img/offers/202108/table1-${this.$i18n.locale}.svg`),
        //     },
        //     {
        //       name: this.$t("offer-202108-table-2-name"),
        //       image: require(`@/assets/img/offers/202108/table2-${this.$i18n.locale}.svg`),
        //     }
        //   ],
        //   sttl: this.$t("offer-202108-sttl"),
        //   request: this.$t("offer-request"),
        //   txts: this.$t("offer-202108-txts"),
        //   news: [
        //     {
        //       name: this.$t("offer-side-6-name"),
        //       image: require(`@/assets/img/offers/side/n6.jpg`),
        //       link: "https://regnum.bg/aquapark",
        //     },
        //     {
        //       name: this.$t("offer-side-7-name"),
        //       image: require(`@/assets/img/offers/side/n7.jpg`),
        //       link: "https://regnum.bg/banya",
        //     },
        //   ],
        //   gallery: [],
        //   color: "#004C9C",
        // },
        // {
        //   id: 202107,
        //   bg: require("@/assets/img/offers/202107/bg.jpg"),
        //   svg: require(`@/assets/img/offers/202107/svg_${this.$i18n.locale}.svg`),
        //   name: this.$t("offer-202107-name"),
        //   validation: this.$t("offer-202107-validation"),
        //   dates: this.$t("offer-202107-dates"),
        //   includes: this.$t("offer-202107-includes"),
        //   promotion: this.$t("offer-202107-promotion"),
        //   description: this.$t("offer-202107-description"),
        //   ttl: this.$t("offer-202107-ttl"),
        //   tables: [
        //     {
        //       name: this.$t("offer-202107-table-1-name"),
        //       image: require(`@/assets/img/offers/202107/table1-${this.$i18n.locale}.svg`),
        //     },
        //     {
        //       name: this.$t("offer-202107-table-2-name"),
        //       image: require(`@/assets/img/offers/202107/table2-${this.$i18n.locale}.svg`),
        //     }
        //   ],
        //   sttl: this.$t("offer-202107-sttl"),
        //   request: this.$t("offer-request"),
        //   txts: this.$t("offer-202107-txts"),
        //   news: [
        //     {
        //       name: this.$t("offer-side-6-name"),
        //       image: require(`@/assets/img/offers/side/n6.jpg`),
        //       link: "https://regnum.bg/aquapark",
        //     },
        //     {
        //       name: this.$t("offer-side-7-name"),
        //       image: require(`@/assets/img/offers/side/n7.jpg`),
        //       link: "https://regnum.bg/banya",
        //     },
        //   ],
        //   gallery: [],
        //   color: "#004C9C",
        // },
        // {
        //   id: 202106,
        //   bg: require("@/assets/img/offers/202106/bg.jpg"),
        //   svg: require(`@/assets/img/offers/202106/svg_${this.$i18n.locale}.svg`),
        //   name: this.$t("offer-202106-name"),
        //   validation: this.$t("offer-202106-validation"),
        //   dates: this.$t("offer-202106-dates"),
        //   includes: this.$t("offer-202106-includes"),
        //   promotion: this.$t("offer-202106-promotion"),
        //   description: this.$t("offer-202106-description"),
        //   ttl: this.$t("offer-202106-ttl"),
        //   tables: [
        //     {
        //       name: this.$t("offer-202106-table-1-name"),
        //       image: require(`@/assets/img/offers/202106/table1-${this.$i18n.locale}.svg`),
        //     },
        //     {
        //       name: this.$t("offer-202106-table-2-name"),
        //       image: require(`@/assets/img/offers/202106/table2-${this.$i18n.locale}.svg`),
        //     }
        //   ],
        //   sttl: this.$t("offer-202106-sttl"),
        //   request: this.$t("offer-request"),
        //   txts: this.$t("offer-202106-txts"),
        //   news: [
        //     {
        //       name: this.$t("offer-side-6-name"),
        //       image: require(`@/assets/img/offers/side/n6.jpg`),
        //       link: "https://regnum.bg/aquapark",
        //     },
        //     {
        //       name: this.$t("offer-side-7-name"),
        //       image: require(`@/assets/img/offers/side/n7.jpg`),
        //       link: "https://regnum.bg/banya",
        //     },
        //   ],
        //   gallery: [],
        //   color: "#004C9C",
        // },
        // {
        //   id: 202109,
        //   bg: require("@/assets/img/offers/202109/bg.jpg"),
        //   svg: require(`@/assets/img/offers/202109/svg_${this.$i18n.locale}.svg`),
        //   name: this.$t("offer-202109-name"),
        //   validation: this.$t("offer-202109-validation"),
        //   dates: this.$t("offer-202109-dates"),
        //   description: this.$t("offer-202109-description"),
        //   ttl: this.$t("offer-202109-ttl"),
        //   tables: [
        //     {
        //       name: this.$t("offer-202109-table-1-name"),
        //       image: require(`@/assets/img/offers/202109/table1-${this.$i18n.locale}.svg`),
        //     },
        //     {
        //       name: this.$t("offer-202109-table-2-name"),
        //       image: require(`@/assets/img/offers/202109/table2-${this.$i18n.locale}.svg`),
        //     },
        //   ],
        //   sttl: this.$t("offer-202109-sttl"),
        //   request: this.$t("offer-request"),
        //   txts: this.$t("offer-202109-txts"),
        //   news: [
        //     {
        //       name: this.$t("offer-side-6-name"),
        //       image: require(`@/assets/img/offers/side/n6.jpg`),
        //       link: "https://regnum.bg/aquapark",
        //     },
        //     {
        //       name: this.$t("offer-side-7-name"),
        //       image: require(`@/assets/img/offers/side/n7.jpg`),
        //       link: "https://regnum.bg/banya",
        //     },
        //   ],
        //   gallery: [],
        //   color: "#705600",
        // },
        // {
        //   id: 202110,
        //   bg: require("@/assets/img/offers/202110/bg.jpg"),
        //   svg: require(`@/assets/img/offers/202110/svg_${this.$i18n.locale}.svg`),
        //   name: this.$t("offer-202110-name"),
        //   validation: this.$t("offer-202110-validation"),
        //   dates: this.$t("offer-202110-dates"),
        //   description: this.$t("offer-202110-description"),
        //   ttl: this.$t("offer-202110-ttl"),
        //   tables: [
        //     {
        //       name: this.$t("offer-202110-table-1-name"),
        //       image: require(`@/assets/img/offers/202110/table1-${this.$i18n.locale}.svg`),
        //     },
        //     {
        //       name: this.$t("offer-202110-table-2-name"),
        //       image: require(`@/assets/img/offers/202110/table2-${this.$i18n.locale}.svg`),
        //     },
        //   ],
        //   sttl: this.$t("offer-202110-sttl"),
        //   request: this.$t("offer-request"),
        //   txts: this.$t("offer-202110-txts"),
        //   news: [
        //     {
        //       name: this.$t("offer-side-6-name"),
        //       image: require(`@/assets/img/offers/side/n6.jpg`),
        //       link: "https://regnum.bg/aquapark",
        //     },
        //     {
        //       name: this.$t("offer-side-7-name"),
        //       image: require(`@/assets/img/offers/side/n7.jpg`),
        //       link: "https://regnum.bg/banya",
        //     },
        //   ],
        //   gallery: [],
        //   color: "#8F4C00",
        // },
        // {
        //   id: 202111,
        //   bg: require("@/assets/img/offers/202111/bg.jpg"),
        //   svg: require(`@/assets/img/offers/202111/svg_${this.$i18n.locale}.svg`),
        //   name: this.$t("offer-202111-name"),
        //   validation: this.$t("offer-202111-validation"),
        //   dates: this.$t("offer-202111-dates"),
        //   description: this.$t("offer-202111-description"),
        //   ttl: this.$t("offer-202111-ttl"),
        //   tables: [
        //     {
        //       name: this.$t("offer-202111-table-1-name"),
        //       image: require(`@/assets/img/offers/202111/table1-${this.$i18n.locale}.svg`),
        //     },
        //   ],
        //   sttl: this.$t("offer-202111-sttl"),
        //   request: this.$t("offer-request"),
        //   txts: this.$t("offer-202111-txts"),
        //   news: [
        //     {
        //       name: this.$t("offer-side-6-name"),
        //       image: require(`@/assets/img/offers/side/n6.jpg`),
        //       link: "https://regnum.bg/aquapark",
        //     },
        //     {
        //       name: this.$t("offer-side-7-name"),
        //       image: require(`@/assets/img/offers/side/n7.jpg`),
        //       link: "https://regnum.bg/banya",
        //     },
        //   ],
        //   gallery: [],
        //   color: "#7D0F00",
        // },
        // {
        //   id: 202105,
        //   bg: require("@/assets/img/offers/202105/bg.jpg"),
        //   svg: require(`@/assets/img/offers/202105/svg_${this.$i18n.locale}.svg`),
        //   name: this.$t("offer-202105-name"),
        //   validation: this.$t("offer-202105-validation"),
        //   dates: this.$t("offer-202105-dates"),
        //   includes: this.$t("offer-202105-includes"),
        //   promotion: this.$t("offer-202105-promotion"),
        //   description: this.$t("offer-202105-description"),
        //   ttl: this.$t("offer-202105-ttl"),
        //   tables: [
        //     {
        //       name: this.$t("offer-202105-table-1-name"),
        //       image: require(`@/assets/img/offers/202105/table1-${this.$i18n.locale}.svg`),
        //     },
        //   ],
        //   sttl: this.$t("offer-202105-sttl"),
        //   txts: this.$t("offer-202105-txts"),
        //   request: this.$t("offer-request"),
        //   news: [
        //     {
        //       name: this.$t("offer-side-1-name"),
        //       image: require(`@/assets/img/offers/side/n1.jpg`),
        //       link: "https://regnum.bg/offers/202104",
        //     },
        //     {
        //       name: this.$t("offer-side-5-name"),
        //       image: require(`@/assets/img/offers/side/n4.jpg`),
        //       link: "https://regnum.bg/offers/202102",
        //     },
        //   ],
        //   gallery: [
        //     require("@/assets/img/offers/gallery/vita/g1.jpg"),
        //     require("@/assets/img/offers/gallery/vita/g2.jpg"),
        //     require("@/assets/img/offers/gallery/vita/g3.jpg"),
        //     require("@/assets/img/offers/gallery/vita/g4.jpg"),
        //     require("@/assets/img/offers/gallery/vita/g5.jpg"),
        //   ],
        //   color: "#E1F0FC",
        // },
        // {
        //   id: 202104,
        //   bg: require("@/assets/img/offers/202104/bg.jpg"),
        //   svg: require(`@/assets/img/offers/202104/svg_${this.$i18n.locale}.svg`),
        //   name: this.$t("offer-202104-name"),
        //   validation: this.$t("offer-202104-validation"),
        //   dates: this.$t("offer-202104-dates"),
        //   includes: this.$t("offer-202104-includes"),
        //   promotion: this.$t("offer-202104-promotion"),
        //   description: this.$t("offer-202104-description"),
        //   ttl: this.$t("offer-202104-ttl"),
        //   tables: [
        //     {
        //       name: this.$t("offer-202104-table-1-name"),
        //       image: require(`@/assets/img/offers/202104/table1-${this.$i18n.locale}.svg`),
        //     },
        //   ],
        //   sttl: this.$t("offer-202104-sttl"),
        //   txts: this.$t("offer-202104-txts"),
        //   request: this.$t("offer-request"),
        //   news: [
        //     {
        //       name: this.$t("offer-side-2-name"),
        //       image: require(`@/assets/img/offers/side/n2.jpg`),
        //       link: "https://regnum.bg/offers/202105",
        //     },
        //     {
        //       name: this.$t("offer-side-5-name"),
        //       image: require(`@/assets/img/offers/side/n4.jpg`),
        //       link: "https://regnum.bg/offers/202102",
        //     },
        //   ],
        //   gallery: [
        //     require("@/assets/img/offers/gallery/vita/g1.jpg"),
        //     require("@/assets/img/offers/gallery/vita/g2.jpg"),
        //     require("@/assets/img/offers/gallery/vita/g3.jpg"),
        //     require("@/assets/img/offers/gallery/vita/g4.jpg"),
        //     require("@/assets/img/offers/gallery/vita/g5.jpg"),
        //   ],
        //   color: "#E1F0FC",
        // },
        // {
        //   id: 202102,
        //   bg: require("@/assets/img/offers/202102/bg.jpg"),
        //   svg: require(`@/assets/img/offers/202102/svg_${this.$i18n.locale}.svg`),
        //   name: this.$t("offer-202102-name"),
        //   validation: this.$t("offer-202102-validation"),
        //   dates: this.$t("offer-202102-dates"),
        //   includes: this.$t("offer-202102-includes"),
        //   promotion: this.$t("offer-202102-promotion"),
        //   description: this.$t("offer-202102-description"),
        //   ttl: this.$t("offer-202102-ttl"),
        //   tables: [
        //     {
        //       name: this.$t("offer-202102-table-1-name"),
        //       image: require(`@/assets/img/offers/202102/table1-${this.$i18n.locale}.svg`),
        //     },
        //   ],
        //   sttl: this.$t("offer-202102-sttl"),
        //   request: this.$t("offer-request"),
        //   txts: this.$t("offer-202102-txts"),
        //   news: [
        //     {
        //       name: this.$t("offer-side-1-name"),
        //       image: require(`@/assets/img/offers/side/n1.jpg`),
        //       link: "https://regnum.bg/offers/202105",
        //     },
        //     {
        //       name: this.$t("offer-side-2-name"),
        //       image: require(`@/assets/img/offers/side/n2.jpg`),
        //       link: "https://regnum.bg/offers/202104",
        //     },
        //   ],
        //   gallery: [],
        //   color: "#E1F0FC",
        // },
        // {
        //   id: 202103,
        //   bg: require("@/assets/img/offers/202103/bg.jpg"),
        //   svg: require(`@/assets/img/offers/202103/svg_${this.$i18n.locale}.svg`),
        //   name: this.$t("offer-202103-name"),
        //   validation: this.$t("offer-202103-validation"),
        //   dates: this.$t("offer-202103-dates"),
        //   includes: this.$t("offer-202103-includes"),
        //   promotion: this.$t("offer-202103-promotion"),
        //   description: this.$t("offer-202103-description"),
        //   ttl: this.$t("offer-202103-ttl"),
        //   tables: [
        //     {
        //       name: this.$t("offer-202103-table-1-name"),
        //       image: require(`@/assets/img/offers/202103/table1-${this.$i18n.locale}.svg`),
        //     },
        //     {
        //       name: this.$t("offer-202103-table-2-name"),
        //       image: require(`@/assets/img/offers/202103/table2-${this.$i18n.locale}.svg`),
        //     },
        //   ],
        //   sttl: this.$t("offer-202103-sttl"),
        //   txts: this.$t("offer-202103-txts"),
        //   request: this.$t("offer-request"),
        //   news: [
        //     {
        //       name: this.$t("offer-side-1-name"),
        //       image: require(`@/assets/img/offers/side/n1.jpg`),
        //       link: "https://regnum.bg/offers/202105",
        //     },
        //     {
        //       name: this.$t("offer-side-2-name"),
        //       image: require(`@/assets/img/offers/side/n2.jpg`),
        //       link: "https://regnum.bg/offers/202104",
        //     },
        //   ],
        //   gallery: [],
        //   color: "#E1F0FC",
        // },
      ],
      spa: [
        {
          bg: require(`@/assets/img/spa/spa1.jpg`),
          name: this.$t("spa-s-name"),
          subname: this.$t("spa-s-p"),
          to: "/spa/services",
        },
        {
          bg: require(`@/assets/img/spa/spa2.jpg`),
          name: this.$t("spa-o-name"),
          subname: this.$t("spa-o-p"),
          to: "/spa/offers",
        },
        {
          bg: require(`@/assets/img/spa/spa3.jpg`),
          name: this.$t("spa-w-name"),
          subname: this.$t("spa-w-p"),
          to: "/spa/wellness-and-fitness",
        },
      ],
      scrollLeft: 0,
      opened: null,
    };
  },
  watch: {
    $route: {
      handler: "handleRoutes",
      immediate: true,
    },
  },
  methods: {
    handleWheel(e) {
      const item = document.getElementById("showcases");
      if (e.deltaY > 0) {
        item.scrollLeft += window.innerWidth / 4;
      } else {
        item.scrollLeft -= window.innerWidth / 4;
      }
    },
    openShowcase(x) {
      if (this.$route.params.id !== undefined) {
        return;
      }
      if (x.id) {
        this.$router.push(`/${this.$route.name}/${x.id}`);
      } else if (x.to) {
        this.$router.push(x.to);
      }
    },
    closeShowcase() {
      this.$router.push(`/${this.$route.name}`);
    },
    handleRoutes(to) {
      const item = document.getElementById("showcases");
      if (to.params.id) {
        const i = Number.parseInt(to.params.id);
        const index = this.type.map((r) => r.id).indexOf(i);

        if (typeof i === "number" && this.type[index]) {
          this.opened = index;
          if (item) {
            this.scrollLeft = item.scrollLeft;
          }
        }
      } else {
        this.opened = null;

        if (item) {
          setTimeout(() => {
            item.scrollLeft = this.scrollLeft;
            this.scrollLeft = 0;

            item.scroll({ x: this.scrollLeft, behavior: "smooth" });
          }, 500);
        }
      }
    },
  },
  mounted() {
    window.addEventListener("wheel", this.handleWheel);
  },
  destroyed() {
    window.removeEventListener("wheel", this.handleWheel);
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/variables.scss";
@import "@/scss/mixins.scss";
header {
  display: flex;
  height: 100vh;
  max-height: 100vh;
  min-height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  max-height: calc(var(--vh, 1vh) * 100);
  min-height: calc(var(--vh, 1vh) * 100);
  @include transition($transition);
}
.showcases-wrapper--scrollable {
  header {
    max-height: initial;
  }
}
.close {
  align-items: center;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  mix-blend-mode: difference;
  justify-content: center;
  padding: 0.5rem;
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  z-index: 12;
  @include transition($transition);
  &:hover {
    background-color: rgba($color: #fff, $alpha: 0.15);
  }
}
aside {
  color: #fff;
  display: flex;
  flex-direction: column;
  mix-blend-mode: difference;
  padding: 0 0;
  position: relative;
  width: 35%;
  z-index: 1;
  h2,
  .svg {
    height: 100%;
    position: relative;
    right: -9rem;
    width: 93%;
    @include transition($transition);
    &.hidden {
      opacity: 0;
      right: 0;
    }
  }
  h2 {
    align-items: center;
    display: flex;
    width: 120%;
  }
  p {
    padding: 0 2.5rem 3.75rem 9rem;
    position: relative;
  }
  .scroll-h {
    left: 4rem;
    position: absolute;
    top: 0;
  }
}
.showcases {
  display: flex;
  overflow: auto;
  padding-left: 0.25rem;
  scroll-behavior: smooth;
  width: 65%;
  @include scrollbar(5px, #000);
}
.showcase {
  background-position: center;
  background-size: cover;
  cursor: pointer;
  margin-left: 10px;
  min-width: 45%;
  position: relative;
  width: 45%;
  @include transition($transition);
  footer {
    background-color: rgba($color: #fff, $alpha: 0.95);
    bottom: 1rem;
    display: flex;
    flex-direction: column;
    height: 12rem;
    justify-content: flex-start;
    overflow: hidden;
    left: -0.75rem;
    padding: 0.75rem 15% 0.75rem 10%;
    position: absolute;
    text-overflow: ellipsis;
    width: 100%;
    @include transition($transition);
    > p {
      border-bottom: 1px solid #000;
      font-size: 1.85rem;
      line-height: 2rem;
      padding-bottom: 0.75rem;
      white-space: nowrap;
      @include transition($transition);
      span {
        display: block;
      }
    }
    .info {
      padding: 12.5px 0 22.5px;
      p {
        @include transition($transition);
        white-space: nowrap;
        &.can-wrap {
          white-space: initial;
        }
        strong {
          font-weight: 400;
        }
      }
    }
  }
  &.active {
    cursor: default;
    height: 100%;
    min-width: calc(100% - 0.5rem);
    width: calc(100% - 0.5rem);
    footer {
      bottom: 3rem;
      overflow: hidden;
      height: 7rem;
      padding: 1.5rem;
      width: 30vw;
      .info {
        border-top: 1px solid transparent;
        padding: 0;
        width: 100%;
      }
      > p {
        display: none;
      }
    }
  }
  &.inactive {
    min-width: 0px;
    margin-left: 0;
    opacity: 0;
    overflow: hidden;
    width: 0;
  }
}

.fade-enter-active,
.fade-leave-active {
  @include transition($transition);
  // transition-delay: 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.slide-enter-active,
.slide-leave-active {
  @include transition($transition);
}
.slide-enter,
.slide-leave-to {
  opacity: 0;
  @include transform(translateX(1rem));
}

.slide-bottom-enter-active,
.slide-bottom-leave-active {
  @include transition($transition);
}
.slide-bottom-enter,
.slide-bottom-leave-to {
  opacity: 0;
  @include transform(translateY(1rem));
}

@media screen and (min-width: $s) {
  .showcase {
    &:hover {
      background-position: 0% 50%;
      footer {
        > p,
        .info p {
          letter-spacing: 1px;
        }
      }
    }
  }
}

@media screen and (max-width: $l) {
  .showcase {
    &.active {
      footer {
        width: 40vw;
      }
    }
  }
}

@media screen and (max-width: $sm) {
  aside {
    .scroll-h {
      left: 2rem;
    }
    h2,
    .svg {
      right: -6rem;
    }
    p {
      padding-left: 6rem;
    }
  }
  .showcase {
    min-width: 65%;
    width: 65%;
  }
}

@media screen and (max-width: $s) {
  .showcases-wrapper header {
    flex-direction: column;
    justify-content: flex-end;
  }
  .scroll-t {
    bottom: 1.5rem;
    mix-blend-mode: difference;
    position: absolute;
    right: 1rem;
    z-index: 1;
  }
  aside {
    margin-bottom: -23%;
    padding: 100px 1rem 1rem;
    top: 0;
    position: absolute;
    width: 100%;
    h2,
    .svg {
      position: initial;
      width: 100%;
      max-height: 30vh;
    }
    p {
      display: none;
    }
  }
  .showcases {
    height: calc(87vh - 5rem);
    scroll-snap-type: x mandatory;
    width: 100%;
    padding-bottom: 1rem;
    padding-left: 1rem;
    .showcase {
      min-width: 100%;
      scroll-snap-align: end;
      margin-left: 0.75rem;
      width: 100%;
      &.active {
        margin: 0;
      }
      &.inactive {
        margin: 0;
        min-width: 0;
        width: 0;
      }
      footer {
        bottom: -1rem;
        height: initial;
        left: -1rem;
        padding: 0.5rem 1.25rem;
        width: calc(100% - 3rem);
        z-index: 1;
        .info {
          margin-top: 0.75rem;
          padding: 0.75rem 0;
          p {
            white-space: initial;
          }
        }
        & > * {
          position: relative;
          z-index: 1;
        }
      }
    }
  }
}
</style>
