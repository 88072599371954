<template>
  <div class="inner-page">
    <header class="inner-page__header">
      <aside class="inner-page__aside">
        <transition name="slide" mode="out-in">
          <img
            :src="require(`@/assets/img/terms/terms_${$i18n.locale}.svg`)"
            class="svg"
          />
        </transition>
        <p>
          <img src="@/assets/img/scroll_h.svg" class="scroll-h" />
          {{ $t("terms-p") }}
          <br />
          <br />
          <strong>{{ $t("terms-p-2") }}</strong>
        </p>
      </aside>
      <div class="inner-page__img"></div>
      <p class="mobile">
        {{ $t("terms-p") }}
        <br />
        {{ $t("terms-p-2") }}
      </p>
    </header>
    <main class="inner-page__main">
      <section class="terms__main">
        <div class="inner-page__texts">
          <h2>{{ $t("terms-ttl") }}</h2>
          <p class="sttl">
            <a :href="`/gdpr_${this.$i18n.locale}.pdf`">{{
              $t("terms-p-3")
            }}</a>
          </p>
          <p class="sttl">
            <a :href="`/covid_${this.$i18n.locale}.pdf`">{{
              $t("terms-p-4")
            }}</a>
          </p>
           <p class="sttl">
            <a :href="`/booking-terms_${this.$i18n.locale}.pdf`">{{
              $t("terms-p-5")
            }}</a>
          </p>
          <template
            v-for="(n, i) in [1]"
          >
            <p class="sttl" :key="`sttl-${i}`">{{ $t(`terms-sc${n}-sttl`) }}</p>
            <template v-for="(t, j) in $t(`terms-sc${n}-txts`)">
            <p :key="`txts-${i}-${j}`" v-if="typeof t === 'string'" v-html="t"></p>
            <ul :key="`txts-ul-${i}-${j}`" v-else>
              <li v-for="(item, k) in t" :key="`txts-ul-${i}-${j}-${k}`">
                {{ item }}
              </li>
            </ul>
          </template>
          </template>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "@/scss/inner_page.scss";
@import "@/scss/transition.scss";
.inner-page__img {
  background-image: url("../../assets/img/terms/terms.jpg");
}

.inner-page__main {
  background-image: url("../../assets/img/bg.jpg");
}

.inner-page__header {
  background-color: #e5efff;
}

.terms__main {
  display: flex;
  padding: 3rem 5rem 3rem 9rem;
  p {
    margin: 1rem 0;
  }
}

@media screen and (max-width: $sm) {
  .terms__main {
    padding: 3rem 4rem 3rem 6rem;
  }
}

@media screen and (max-width: $s) {
  .inner-page__header {
    height: initial;
    max-height: initial;
    > p {
      padding: 1rem;
    }
  }
  .inner-page__img {
    margin-left: 1rem;
    margin-top: calc(13vh + 5rem);
    width: calc(100% - 1rem);
  }
  .terms__main {
    flex-direction: column;
    padding: 0;
  }
  .inner-page__texts {
    padding: 1rem;
  }
}
</style>