<template>
  <div class="inner-page">
    <header class="inner-page__header">
      <aside class="inner-page__aside">
        <transition name="slide" mode="out-in">
          <img
            :src="require(`@/assets/img/loyal/svg_${$i18n.locale}.svg`)"
            class="svg"
          />
        </transition>
        <p>
          <img src="@/assets/img/scroll_h.svg" class="scroll-h" />
          {{ $t("loyalty-p") }}
        </p>
      </aside>
      <div class="inner-page__img"></div>
    </header>
    <main class="inner-page__main">
      <About />
      <!-- SECTION 1 -->
      <section class="inner-page__section">
        <p class="mobile">{{ $t("loyalty-sc1-txt") }}</p>
        <p class="mobile">{{ $t("loyalty-sc1-txt1") }}</p>
        <p class="mobile">{{ $t("loyalty-sc1-txt2") }}</p>
        <p class="mobile">{{ $t("loyalty-sc1-txt3") }}</p>
        <p class="mobile">{{ $t("loyalty-sc1-txt4") }}</p>
        <p class="mobile">{{ $t("loyalty-sc1-txt5") }}</p>
        <Slideshow
          :reversed="true"
          :images="[
            require('@/assets/img/loyal/s1-1.jpg'),
            require('@/assets/img/loyal/s1-2.jpg'),
            require('@/assets/img/loyal/s1-3.jpg'),
          ]"
        />
        <div class="inner-page__texts">
          <h2>{{ $t("loyalty-sc1-ttl") }}</h2>
          <p class="sttl">{{ $t("loyalty-sc1-sttl") }}</p>
          <p class="desktop">{{ $t("loyalty-sc1-txt") }}</p>
          <p class="desktop">{{ $t("loyalty-sc1-txt1") }}</p>
          <p class="desktop">{{ $t("loyalty-sc1-txt2") }}</p>
          <p class="desktop">{{ $t("loyalty-sc1-txt3") }}</p>
          <p class="desktop">{{ $t("loyalty-sc1-txt4") }}</p>
          <p class="desktop">{{ $t("loyalty-sc1-txt5") }}</p>
        </div>
      </section>
      <!-- SECTION 2 -->
      <section class="inner-page__section section--reversed section--top">
        <div class="inner-page__texts">
          <h2>{{ $t("loyalty-sc2-ttl") }}</h2>
          <p class="sttl">{{ $t("loyalty-sc2-sttl") }}</p>
          <p class="desktop">{{ $t("loyalty-sc2-txt") }}</p>
          <p class="desktop">{{ $t("loyalty-sc2-txt1") }}</p>
          <p class="desktop">{{ $t("loyalty-sc2-txt2") }}</p>
          <p class="desktop">{{ $t("loyalty-sc2-txt3") }}</p>
          <p class="desktop">{{ $t("loyalty-sc2-txt4") }}</p>
          <p class="desktop">{{ $t("loyalty-sc2-txt5") }}</p>
          <p class="desktop">{{ $t("loyalty-sc2-txt6") }}</p>
          <p class="desktop">{{ $t("loyalty-sc2-txt7") }}</p>
        </div>
        <Slideshow
          :images="[
            require('@/assets/img/loyal/s2-1.jpg'),
            require('@/assets/img/loyal/s2-2.jpg'),
            require('@/assets/img/loyal/s2-3.jpg'),
          ]"
        />
        <div class="inner-page__section">
          <h2 class="mobile">{{ $t("loyalty-sc2-ttl") }}</h2>
          <p class="mobile">{{ $t("loyalty-sc2-sttl") }}</p>
          <p class="mobile">{{ $t("loyalty-sc2-txt") }}</p>
          <p class="mobile">{{ $t("loyalty-sc2-txt1") }}</p>
          <p class="mobile">{{ $t("loyalty-sc2-txt2") }}</p>
          <p class="mobile">{{ $t("loyalty-sc2-txt3") }}</p>
          <p class="mobile">{{ $t("loyalty-sc2-txt4") }}</p>
          <p class="mobile">{{ $t("loyalty-sc2-txt5") }}</p>
          <p class="mobile">{{ $t("loyalty-sc2-txt6") }}</p>
          <p class="mobile">{{ $t("loyalty-sc2-txt7") }}</p>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
import About from "./About";
import Slideshow from "@/components/Slideshow";
export default {
  components: {
    About,
    Slideshow,
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/inner_page.scss";
.inner-page__img {
  background-image: url("../../assets/img/loyal/bg.jpg");
}

.inner-page__main {
  background-image: url("../../assets/img/bg.jpg");
}

.inner-page__header {
  background: linear-gradient(rgba(0, 0, 0, 1) 0%, rgba(54, 54, 54, 1) 100%);
}
</style>