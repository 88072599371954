<template>
  <footer class="footer" :class="{ 'footer--hidden': hidden }">
    <FooterGallery />
    <form class="bulletin-form-wrapper" @submit.prevent="submit">
      <span class="form-title" v-html="$t('footer-bulletin')"></span>
      <input
        class="email-field"
        type="email"
        required
        v-model="inquiry.email"
        :placeholder="$t('footer-bulletin-input')"
      />

      <div class="form-footer">
        <div class="checkbox-wrapper">
          <input
            class="checkbox"
            type="checkbox"
            id="footerTerms"
            required
            v-model="agreed"
          />
          <label class="checkbox-label" for="footerTerms">{{
            $t("form-terms")
          }}</label>
        </div>
        <Button
          class="darker"
          :disabled="loading"
          :success="success"
          @clear-success="success = false"
          type="submit"
        >
          <span>
            {{ $t("footer-bulletin-submit-1") }}
          </span>
          <span>
            {{ $t("footer-bulletin-submit-2") }}
          </span>
        </Button>
      </div>
      <!-- <div class="field"></div> -->
    </form>

    <div class="image-wrapper">
      <img
        class="thermal-bath-image"
        src="../assets/img/footer/ftr.png"
        :alt="$t('footer-additional-alt')"
      />

      <Button
        href="http://www.regnumbanyathermal.com/"
        target="_blank"
        class="darker"
      >
        <span>
          {{ $t("footer-more-information") }}
        </span>
        <span> regnumbanyathermal.com </span>
      </Button>
      <div class="img-field">
        <img
          class="thermal-bath-title"
          :src="require(`@/assets/img/footer/banya-text_${$i18n.locale}.svg`)"
          :alt="$t('footer-additional-alt')"
        />
        <div class="thermal-bath-decription">
          {{ $t("footer-thermal-bath-info") }}
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import Button from "@/components/Button";
import FooterGallery from './FooterGallery'
export default {
  components: {
    Button,
    FooterGallery
  },
  computed: {
    hidden() {
      return (
        !this.$route.params.id &&
        (this.$route.name === "rooms" ||
          this.$route.name === "halls" ||
          this.$route.name === "news" ||
          this.$route.name === "services" ||
          this.$route.name === "offers" ||
          this.$route.name === "spa" ||
          this.$route.name === "gallery" ||
          this.$route.name === "menus" ||
          this.$route.name === "404")
      );
    },
  },
  data() {
    return {
      inquiry: {
        email: "",
      },
      agreed: false,
      success: false,
      loading: false,
    };
  },
  methods: {
    submit() {
      this.loading = true;
      this.inquiry.from = window.location.href;
      fetch(`${process.env.VUE_APP_BASE_URI}subscription`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(this.inquiry),
      })
        .then((response) => response.json())
        .then((data) => {
          this.success = data.success;
          if (data.success) {
            this.inquiry = {
              email: "",
            };
            this.agreed = false;
          }
        })
        .catch((err) => console.log(err))
        .then(() => (this.loading = false));
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/inner_page.scss";
@import "@/scss/transition.scss";

.footer {
  background-image: url("../assets/img/footer/ftr-bl.png");
  background-size: cover;
  position: relative;
  &.footer--hidden {
    display: none;
  }
}

.bulletin-form-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 2rem 0;
  .form-title {
    font-size: 1.8rem;
    margin: 0 50% 0 25%;
  }
  .email-field {
    background-color: rgba($color: #b7d1e2, $alpha: 0.5);
    border: none;
    outline: none;
    padding: 0.65rem 1.35rem;
    width: 50%;
    margin-top: 10px;
    justify-content: center;
  }
}

.form-footer {
  display: flex;
  justify-content: space-between;
  // margin-left: 10%;
  width: 50%;
}

.checkbox-wrapper {
  align-items: center;
  display: inline-flex;
  font-size: 1rem;
  @include noselect();
  .checkbox {
    margin: 0 0.5rem 0 0;
  }
}

.button {
  box-shadow: 0px 0px 99px #003f5266;
}

.field {
  background-color: #0b9dce;
  height: 2.44rem;
  width: 100%;
  margin-bottom: 1rem;
}

.image-wrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: -2rem;
  position: relative;
  .thermal-bath-image {
    height: auto;
    width: 80%;
    position: relative;
    top: 2rem;
    z-index: 1;
    &.mobile {
      display: none;
    }
  }

  .img-field {
    align-items: center;
    box-sizing: border-box;
    background-color: rgba(0, 58, 130, 90%);
    bottom: 0;
    display: flex;
    flex-direction: column;
    left: 0;
    padding: 2rem;
    position: absolute;
    width: 34%;
    z-index: 2;

    .thermal-bath-title {
      height: auto;
      margin-bottom: 1rem;
      width: 80%;
    }

    .thermal-bath-decription {
      color: #ffffff;
      text-align: left;
      font-size: 1.5vw;
      // font-size: 1.65rem;
    }
  }
  .button {
    bottom: 0;
    position: absolute;
    left: 34%;
    z-index: 1;
  }
}

@media screen and (max-width: $l) {
  .img-field {
    img {
      width: 80%;
    }
  }
}

@media screen and (max-width: $s) {
  .bulletin-form-wrapper {
    .form-title {
      text-align: center;
      margin: 0 18px;
      font-size: 26px;
    }
    .email-field {
      width: 90%;
    }
  }
  .form-footer {
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding: 0;
    .checkbox-wrapper {
      margin: 1rem 0;
    }
  }

  .field {
    display: none;
  }

  .image-wrapper {
    background-color: #003a82;
    flex-direction: column-reverse;
    margin-top: 0;
    position: relative;
    padding: 1rem 0;
    .thermal-bath-image {
      margin: auto;
      display: none;
      &.desktop {
        display: none;
      }
      &.mobile {
        display: block;
        overflow: hidden;
      }
    }
    .img-field {
      position: static;
      width: 100%;
      mix-blend-mode: difference;
      background-color: transparent;
      .thermal-bath-title {
        // position: absolute;
        // margin: 0;
        top: 0;
      }
      .thermal-bath-decription {
        display: flex;
        font-size: 1.6rem;
        margin-left: 3px;
      }
    }
    .button {
      bottom: 0;
      position: relative;
      left: initial;
      margin: 0 auto;
    }
  }
}
</style>
