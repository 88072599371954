<template>
  <div class="inner-page">
    <header class="inner-page__header">
      <aside class="inner-page__aside">
        <transition name="slide" mode="out-in">
          <img
            :src="require(`@/assets/img/banya/svg_${$i18n.locale}.svg`)"
            :alt="$t('banya-alt')"
            class="svg"
          />
        </transition>
        <p>
          <img src="@/assets/img/scroll_h.svg" class="scroll-h" />
          {{ $t("banya-p") }}
        </p>
      </aside>
      <div class="inner-page__img"></div>
    </header>
    <main class="inner-page__main">
      <About />
      <!-- SECTION 1 -->
      <section class="inner-page__section">
        <Slideshow
          :reversed="true"
          :images="[
            require('@/assets/img/banya/s1-1.jpg'),
            require('@/assets/img/banya/s1-2.jpg'),
            require('@/assets/img/banya/s1-3.jpg'),
          ]"
        />
        <div class="inner-page__texts">
          <h2>{{ $t("banya-sc1-ttl") }}</h2>
          <p class="sttl" v-html="$t('banya-sc1-sttl')"></p>
          <p v-html="$t('banya-sc1-txt')"></p>
          <br>
          <img
            :src="require(`@/assets/img/banya/table-${$i18n.locale}.svg`)"
            :alt="$t('banya-alt')"
            class="svg"
          />
         
          <!-- <p v-html="$t('banya-sc2-txt')"></p>
          <br>
          <p v-html="$t('banya-prices-t1')"></p>
          <br>
          <p v-html="$t('banya-prices-1')"></p>
          <br> -->
          <!-- <p v-html="$t('banya-prices-t4')"></p>
          <br>
          <p v-html="$t('banya-prices-4')"></p>
          <br> -->
          <!-- <p v-html="$t('banya-prices-t2')"></p>
          <br>
          <p v-html="$t('banya-prices-2')"></p>
          <br>
          <p v-html="$t('banya-prices-dds')"></p>
          <br>
          <p v-html="$t('banya-prices-t3a')"></p>
          <p v-html="$t('banya-prices-t3b')"></p> <br>
          <p v-html="$t('banya-prices-t3')"></p>
          <p v-html="$t('banya-prices-t3-2')"></p> -->
        </div>
      </section>
    </main>
  </div>
</template>

<script>
import About from "./About";
import Slideshow from "@/components/Slideshow";
export default {
  components: {
    About,
    Slideshow,
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/inner_page.scss";
.inner-page__img {
  background-image: url("../../assets/img/banya/bg.jpg");
}

.inner-page__main {
  background-image: url("../../assets/img/bg.jpg");
}

.inner-page__header {
  background-color: #003a82;
}

.svg {
  max-width: calc(100% - 12rem);
}

@media screen and (max-width: $s) {
  .svg {
    max-width: 100%;
    margin-top: 1rem;
  }
}
</style>
