<template>
  <div class="inner-page inner-page--spa">
    <header class="inner-page__header">
      <aside class="inner-page__aside">
        <transition name="slide" mode="out-in">
          <img
            :src="require(`@/assets/img/spa-w/svg_${$i18n.locale}.svg`)"
            :alt="$t('spa-w-alt')"
            class="svg"
          />
        </transition>
        <p>
          <img src="@/assets/img/scroll_h.svg" class="scroll-h" />
          <template v-for="text in $t('spa-w-info')">
            {{ text }}
          </template>
        </p>
      </aside>
      <div class="inner-page__img"></div>
    </header>
    <main class="inner-page__main">
      <About />
      <!-- SECTION 1 -->
      <section class="inner-page__section">
        <Slideshow
          :reversed="true"
          :images="[
            require('@/assets/img/spa-w/s1-1.jpg'),
            require('@/assets/img/spa-w/s1-2.jpg'),
            require('@/assets/img/spa-w/s1-3.jpg'),
          ]"
        />
        <div class="inner-page__texts">
          <h2>{{ $t("spa-w-sc1-ttl") }}</h2>
          <p class="sttl">{{ $t("spa-w-sc1-sttl") }}</p>
          <p v-html="$t('spa-w-sc1-txt')"></p>
        </div>
      </section>
      <!-- SECTION 2 -->
      <section class="inner-page__section section--reversed section--top">
        <div class="inner-page__texts">
          <h2>{{ $t("spa-w-sc2-ttl") }}</h2>
          <!-- <p class="sttl">{{ $t("spa-w-sc2-sttl") }}</p> -->
          <p v-html="$t('spa-w-sc2-sttl1')"></p>
          <p v-html="$t('spa-w-sc2-sttl2')"></p>
          <p v-html="$t('spa-w-sc2-sttl3')"></p>
          <p v-html="$t('spa-w-sc2-sttl4')"></p>
        </div>
        <Slideshow
          :images="[
            require('@/assets/img/spa-w/s2-1.jpg'),
            require('@/assets/img/spa-w/s2-2.jpg'),
            require('@/assets/img/spa-w/s2-3.jpg'),
          ]"
        />
      </section>
      <!-- SECTION 3 -->
      <section class="inner-page__section">
        <Slideshow
          :reversed="true"
          :images="[
            require('@/assets/img/spa-w/s3-1.jpg'),
            require('@/assets/img/spa-w/s3-2.jpg'),
            require('@/assets/img/spa-w/s3-3.jpg'),
          ]"
        />
        <div class="inner-page__texts">
          <h2>{{ $t("spa-w-sc3-ttl") }}</h2>
          <p class="sttl">{{ $t("spa-w-sc3-sttl") }}</p>
          <p v-html="$t('spa-w-sc3-txt')"></p>
        </div>
      </section>
      <!-- SECTION 4 -->
      <section class="inner-page__section section--reversed section--top">
        <div class="inner-page__texts">
          <h2>{{ $t("spa-w-sc4-ttl") }}</h2>
          <p class="sttl">{{ $t("spa-w-sc4-sttl") }}</p>
          <p v-html="$t('spa-w-sc4-sttl1')"></p>
          <p v-html="$t('spa-w-sc4-sttl2')"></p>
          <p v-html="$t('spa-w-sc4-sttl3')"></p>
          <p v-html="$t('spa-w-sc4-sttl4')"></p>
          <p v-html="$t('spa-w-sc4-sttl5')"></p>
          <p v-html="$t('spa-w-sc4-sttl6')"></p>
        </div>
        <Slideshow
          :images="[
            require('@/assets/img/spa-w/s4-1.jpg'),
            require('@/assets/img/spa-w/s4-2.jpg'),
            require('@/assets/img/spa-w/s4-3.jpg'),
          ]"
        />
      </section>
      <!-- SECTION 5 -->
      <section class="inner-page__section">
        <Slideshow
          :reversed="true"
          :images="[
            require('@/assets/img/spa-w/s5-1.jpg'),
            require('@/assets/img/spa-w/s5-2.jpg'),
            require('@/assets/img/spa-w/s5-3.jpg'),
          ]"
        />
        <div class="inner-page__texts">
          <h2>{{ $t("spa-w-sc5-ttl") }}</h2>
          <p class="sttl">{{ $t("spa-w-sc5-sttl") }}</p>
          <p v-html="$t('spa-w-sc5-txt')"></p>
          <p v-html="$t('spa-w-sc5-txt1')"></p>
          <p v-html="$t('spa-w-sc5-txt2')"></p>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
import About from "./AboutWellnessAndFitness";
import Slideshow from "@/components/Slideshow";
export default {
  components: {
    About,
    Slideshow,
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/inner_page.scss";
.inner-page__img {
  background-image: url("../../assets/img/spa/spa3.jpg");
}

.inner-page__main {
  background-image: url("../../assets/img/bg.jpg");
}

.inner-page__header {
  background-color: #e5d5bc;
}
</style>
