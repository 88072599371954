<template>
  <button
    class="button"
    :disabled="disabled"
    type="submit"
    v-if="type === 'submit'"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17.997"
      height="12.001"
      viewBox="0 0 17.997 12.001"
    >
      <path
        id="Path_102"
        data-name="Path 102"
        d="M434.21,2487.387l.083-.094a1,1,0,0,1,1.32-.083l.094.083,5,5a1,1,0,0,1,.083,1.319l-.083.095-5,5a1,1,0,0,1-1.5-1.319l.083-.094,3.289-3.295H424a1,1,0,0,1-.993-.883L423,2493a1,1,0,0,1,.883-.993L424,2492h13.584l-3.291-3.293a1,1,0,0,1-.083-1.32l.083-.094Z"
        transform="translate(-423 -2487)"
      />
    </svg>
    <p>
      <slot />
    </p>
    <transition name="slide">
      <div
        class="success"
        @click.prevent="$emit('clear-success')"
        v-if="success"
      >
        <svg
          height="20px"
          viewBox="0 -46 417.81333 417"
          width="20px"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="m159.988281 318.582031c-3.988281 4.011719-9.429687 6.25-15.082031 6.25s-11.09375-2.238281-15.082031-6.25l-120.449219-120.46875c-12.5-12.5-12.5-32.769531 0-45.246093l15.082031-15.085938c12.503907-12.5 32.75-12.5 45.25 0l75.199219 75.203125 203.199219-203.203125c12.503906-12.5 32.769531-12.5 45.25 0l15.082031 15.085938c12.5 12.5 12.5 32.765624 0 45.246093zm0 0"
            fill="#2196f3"
          />
        </svg>
      </div>
    </transition>
  </button>
  <router-link :to="to || '/'" class="button" v-else>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17.997"
      height="12.001"
      viewBox="0 0 17.997 12.001"
    >
      <path
        id="Path_102"
        data-name="Path 102"
        d="M434.21,2487.387l.083-.094a1,1,0,0,1,1.32-.083l.094.083,5,5a1,1,0,0,1,.083,1.319l-.083.095-5,5a1,1,0,0,1-1.5-1.319l.083-.094,3.289-3.295H424a1,1,0,0,1-.993-.883L423,2493a1,1,0,0,1,.883-.993L424,2492h13.584l-3.291-3.293a1,1,0,0,1-.083-1.32l.083-.094Z"
        transform="translate(-423 -2487)"
      />
    </svg>
    <p>
      <slot />
    </p>
  </router-link>
</template>

<script>
export default {
  props: ["type", "to", "disabled", "success"],
};
</script>

<style lang="scss">
@import "@/scss/variables.scss";
@import "@/scss/mixins.scss";
.button {
  align-items: center;
  border: 0;
  background-color: #fff;
  color: #003f52;
  display: inline-flex;
  font-family: "Gill Sans Pro Cyrillic", sans-serif;
  font-weight: 500;
  overflow: hidden;
  padding: 1rem 0.75rem;
  text-align: left;
  position: relative;
  &:not(:disabled) {
    cursor: pointer;
  }
  > svg,
  span {
    position: relative;
    z-index: 1;
  }
  > svg {
    margin-right: 0.75rem;
    @include transition($transition);
    path {
      fill: $mainColor;
    }
  }
  span {
    font-size: 0.95rem;
    line-height: 0.8rem;
    display: block;
    text-transform: uppercase;
  }
  &::before {
    background-color: rgba($color: $mainColor, $alpha: 0.1);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    @include transition($transition);
    @include transform(scale(0));
  }
  &:not(:disabled):hover {
    > svg {
      @include transform(translateX(3px));
    }
    &::before {
      @include transform(scale(1));
    }
  }

  &.darker {
    > svg path,
    span {
      @include transition($transition);
    }
    &::before {
      background-color: $mainColor;
    }
    &:not(:disabled):hover {
      color: #fff;
      > svg path {
        fill: #fff;
      }
    }
  }

  .success {
    align-items: center;
    background-color: rgb(124, 214, 124);
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 2;
    svg {
      width: 2rem;
      path {
        fill: #fff;
      }
    }
  }
}
@media screen and (max-width: $s) {
  .button {
    padding: 1rem;
    max-width: calc(100% - 4rem);
  }
}

.slide-enter-active,
.slide-leave-active {
  @include transition($transition);
}
.slide-enter,
.slide-leave-to {
  @include transform(translateY(-100%));
}
</style>