<template>
  <div class="showcase">
    <header :style="{ backgroundImage: `url(${showcase.bg})` }">
      <div>
        <div class="image">
          <span class="close" @click="$emit('close')">
            <img src="@/assets/img/showcase/back.svg" alt="Back" />
          </span>
          <img :src="showcase.svg" :alt="showcase.name ? showcase.name.join(' ') : showcase.ttl" v-if="showcase.svg" />
          <h2 v-else-if="showcase.name">
            <p v-for="(n, i) in showcase.name" :key="`showcase-name-${i}`">
              {{ n }}
            </p>
          </h2>
        </div>
        <footer class="desktop">
          <div class="options">
            <template v-if="showcase.options">
              <div
                class="option"
                v-for="(o, i) in showcase.options"
                :key="`opt-${i}`"
              >
                <img :src="o.img" />
                <p>{{ o.txt }}</p>
              </div>
            </template>
          </div>
          <span></span>
          <div class="buttons">
            <ButtonSmaller @click="scrollTo('info')">{{
              $t("showcase.buttons.info")
            }}</ButtonSmaller>
            <ButtonSmaller @click="scrollTo('inquiry')"
              >{{ $t("showcase.buttons.inquiry") }}
            </ButtonSmaller>
            <ButtonSmaller v-if="showcase.gallery" @click="scrollTo('gallery')"
              >{{ $t("showcase.buttons.gallery") }}
            </ButtonSmaller>
          </div>
        </footer>
        <footer class="mobile">
          <div>
            <span class="close" @click="$emit('close')">
              <img src="@/assets/img/showcase/back.svg" alt="Back" />
            </span>
          </div>
          <div class="mini-buttons">
            <img
              :src="require(`@/assets/img/showcase/info_${$i18n.locale}.svg`)"
              alt=""
              @click="scrollTo('info')"
            />
            <img
              :src="require(`@/assets/img/showcase/inq_${$i18n.locale}.svg`)"
              alt=""
              @click="scrollTo('inquiry')"
            />
            <img
              :src="require(`@/assets/img/showcase/glr_${$i18n.locale}.svg`)"
              alt=""
              @click="scrollTo('gallery')"
            />
          </div>
          <div></div>
        </footer>
      </div>
    </header>
    <div class="showcase-content">
    <div v-if="$route.name === 'spa-services'" ref="info">
      <About />
    </div>
      <div v-if="$route.name !== 'spa-services'" class="showcase-about" ref="info">
        <div class="txts">
          <h2 v-if="$route.name !== 'news'">{{ showcase.ttl }}</h2>
          <p class="sttl" v-html="showcase.sttl"></p>
          <template v-for="(t, i) in showcase.txts">
            <p :key="`txts-${i}`" v-if="typeof t === 'string'" v-html="t"></p>
            <ul :key="`txts-ul-${i}`" v-else>
              <li
                v-for="(item, j) in t"
                :key="`txts-ul-${i}-${j}`"
                v-html="item"
              ></li>
            </ul>
          </template>
        </div>
        <div class="img-and-features" v-if="showcase.prices">
          <!-- rooms  -->
          <template v-if="$route.name === 'rooms'">
            <p class="mini-title">{{ $t("rooms-prices-1") }}</p>
            <p class="mini-title">{{ $t("rooms-prices-2") }}</p>
          </template>
          <!-- offers -->
          <p class="mini-title" v-else-if="$route.name === 'offers'">
            {{ $t("offers-prices") }}
          </p>
          <!-- halls -->
          <p class="mini-title" v-else-if="$route.name === 'halls'">
            {{ $t("halls-prices") }}
          </p>
          <div class="image">
            <img :src="showcase.prices" :alt="$t('alt.prices-table')" />
          </div>
        </div>
      </div>
      <div class="showcase-additional" v-if="showcase.additional">
        <p class="mini-title" v-if="showcase.additional.title">
          {{ showcase.additional.title }}
        </p>
        <p
          v-for="(a, i) in showcase.additional.texts"
          :key="`additional-text-${i}`"
          v-html="a"
        ></p>
      </div>

      <!-- <div v-if="$route.name === 'halls'">
      <FormHalls />
    </div> -->

      <Gallery
        v-if="showcase.additionalGallery"
        :images="showcase.additionalGallery"
        ref="additionalGallery"
      />

      <template v-if="['rooms', 'offers', 'halls', 'spa-services'].indexOf($route.name) > -1">
        <p class="mini-title">{{ $t(`${$route.name === 'spa-services' ? 'massage' : $route.name}-inquiry`) }}</p>
        <Inquiry :showcase="showcase" ref="inquiry" />
      </template>

      <Gallery
        v-if="showcase.gallery"
        :images="showcase.gallery"
        ref="gallery"
      />
      <!-- <Suggestions
        :previousShowcase="previousShowcase"
        :nextShowcase="nextShowcase"
      /> -->
    </div>
  </div>
</template>

<script>
// import Features from "./Features";
import About from "../spa/AboutServices";
import Gallery from "./Gallery";
import Inquiry from "./Inquiry";
import ButtonSmaller from "@/components/ButtonSmaller";
// import Slideshow from "@/components/Slideshow";
// import Suggestions from "./Suggestions";
// import FormHalls from "../../views/form/FormHalls";
export default {
  components: {
    // Features,
    About,
    Gallery,
    ButtonSmaller,
    Inquiry,
    // Slideshow,
    // Suggestions,
    // FormHalls,
  },
  props: ["showcase", "previousShowcase", "nextShowcase"],
  computed: {
    show() {
      return this.$route.name === "rooms" || this.$route.name === "halls";
    },
  },
  methods: {
    scrollTo(str) {
      const el = this.$refs[str];

      if (el) {
        if (str === "info") el.scrollIntoView({ behavior: "smooth" });
        else el.$el.scrollIntoView({ behavior: "smooth" });
      }
    },
  },
  mounted() {
    if (this.showcase.title) {
      document.title = this.showcase.title;
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/showcase.scss";
</style>
