var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:(_vm.visibilityChanged),expression:"visibilityChanged"}],staticClass:"parallax-carousel"},[_c('transition',{attrs:{"name":"slide-top","appear":"","mode":"out-in"}},[_c('div',{key:`top-background-${_vm.active}`,staticClass:"top-background",style:({
        background: `linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, ${
          _vm.slides[_vm.active].gradient || 'rgba(0, 63, 82, 1)'
        } 100%)`,
      })})]),_vm._l((_vm.slides),function(s,i){return [_c('transition',{key:`transition-${i}`,attrs:{"name":"slide"}},[(i === _vm.active)?_c('Parallax',{key:`slide-${i}`,attrs:{"bg":s.bg,"video":s.video,"poster":s.poster,"svg":s.svg,"alt":s.alt,"gradient":s.gradient,"to":s.to,"arrows":_vm.slides.length > 1,"visible":_vm.visible,"mobile":_vm.mobile},on:{"prev":_vm.prev,"next":_vm.next,"ended":_vm.next}}):_vm._e()],1)]}),_c('transition',{attrs:{"name":"slide-bottom","appear":"","mode":"out-in"}},[_c('footer',{key:`footer-${_vm.page}`},[(_vm.slides.length > 1)?_c('img',{staticClass:"arrow",attrs:{"src":require("@/assets/img/left_white.svg"),"alt":"Previous"},on:{"click":_vm.prev}}):_vm._e(),_vm._l((_vm.currentSlides),function(s,i){return _c('div',{key:`current-${i + _vm.limit * _vm.page}`,class:{ active: i === _vm.active - _vm.limit * _vm.page },on:{"click":function($event){return _vm.changeSlide(i + _vm.limit * _vm.page)}}},[_c('img',{attrs:{"src":require(`@/${s.svg}`),"alt":s.alt}})])}),(_vm.slides.length > 1)?_c('img',{staticClass:"arrow",attrs:{"src":require("@/assets/img/right_white.svg"),"alt":"Next"},on:{"click":_vm.next}}):_vm._e(),_c('transition',{attrs:{"name":"slide-bottom","appear":"","mode":"out-in"}},[_c('div',{key:`background-${_vm.active}`,staticClass:"background",style:({
            background: `linear-gradient(0deg, ${
              _vm.slides[_vm.active].gradient || 'rgba(0, 63, 82, 1)'
            } 0%, rgba(255,255,255,0) 100%)`,
          })})])],2)]),(_vm.weather)?_c('div',{staticClass:"weather"},[_c('div',[_c('img',{attrs:{"src":require(`@/assets/img/weather/${_vm.weather.weather[0].icon}.png`),"alt":_vm.$t('alt.icon')}}),_c('span',[_vm._v(_vm._s(_vm.weather.main.temp.toFixed(1))+"°C")])]),_c('p',[_vm._v(" "+_vm._s(_vm.weather.weather[0].description)),(_vm.weather.snow)?[_vm._v(","),_c('br'),_vm._v(_vm._s(_vm.weather.snow["3h"] || _vm.weather.snow["1h"])+_vm._s(_vm.$t("new-snow")))]:_vm._e()],2)]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }