var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"showcases-wrapper"},[_c('header',{style:({
      'background-color': _vm.opened !== null ? _vm.type[_vm.opened].color : '#cfced3',
    })},[_c('transition',{attrs:{"name":"fade"}},[(_vm.opened !== null)?_c('div',{staticClass:"close",on:{"click":function($event){return _vm.closeShowcase()}}},[_c('strong',[_vm._v(_vm._s(_vm.$t("back")))])]):_vm._e()]),_c('img',{staticClass:"scroll-t mobile",attrs:{"src":require("@/assets/img/scroll_t.svg")}}),_c('aside',[_c('transition',{attrs:{"name":"slide","mode":"out-in"}},[(_vm.opened === null)?_c('img',{key:"showcases-svg",staticClass:"svg",attrs:{"src":require(`@/assets/img/${_vm.$route.name}/svg_${_vm.$i18n.locale}.svg`)}}):(_vm.$route.name === 'news')?_c('h2',{key:`showcases-news-title-${_vm.opened}`},[_vm._v(" "+_vm._s(_vm.type[_vm.opened].ttl)+" ")]):_c('img',{key:`showcases-svg-${_vm.opened}`,staticClass:"svg",attrs:{"src":_vm.type[_vm.opened].svg,"alt":_vm.type[_vm.opened].name}})]),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.opened !== null)?_c('p',{key:"description"},[_c('img',{staticClass:"scroll-h",attrs:{"src":require("@/assets/img/scroll_h.svg")}}),_vm._v(" "+_vm._s(_vm.type[_vm.opened].description)+" ")]):_c('p',{key:"no-description"},[_c('img',{staticClass:"scroll-h",attrs:{"src":require("@/assets/img/scroll_h.svg")}}),_vm._v(" "+_vm._s(_vm.$t(`${_vm.$route.name}-description`))+" ")])])],1),_c('div',{staticClass:"showcases",attrs:{"id":"showcases"}},_vm._l((_vm.type),function(r,i){return _c('div',{key:`showcase-${i}`,staticClass:"showcase",class:{
          active: _vm.opened === i,
          inactive: _vm.opened !== i && _vm.opened !== null,
        },style:({
          'background-image': `url(${r.bg})`,
        }),on:{"click":function($event){return _vm.openShowcase(r)}}},[_c('footer',[_c('p',_vm._l((r.name),function(n,j){return _c('span',{key:`r-${i}-n-${j}`},[_vm._v(_vm._s(n))])}),0),_c('div',{staticClass:"info"},[(_vm.$route.name === 'rooms')?[_c('p',[_c('strong',[_vm._v(_vm._s(_vm.$t("area"))+":")]),_vm._v(" "+_vm._s(r.area)+" ")]),_c('p',[_c('strong',[_vm._v(_vm._s(_vm.$t("beds"))+":")]),_vm._v(" "+_vm._s(r.beds)+" ")]),_c('p',[_c('strong',[_vm._v(_vm._s(_vm.$t("bathroom"))+":")]),_vm._v(" "+_vm._s(r.bathroom)+" ")])]:(_vm.$route.name === 'halls')?[_c('p',[_c('strong',[_vm._v(_vm._s(_vm.$t("area"))+":")]),_vm._v(" "+_vm._s(r.area)+" ")]),_c('p',[_c('strong',[_vm._v(_vm._s(_vm.$t("size"))+":")]),_vm._v(" "+_vm._s(r.size)+" ")]),_c('p',[_c('strong',[_vm._v(_vm._s(_vm.$t("places"))+":")]),_vm._v(" "+_vm._s(r.places)+" ")])]:(
                _vm.$route.name === 'news' ||
                _vm.$route.name === 'services' ||
                _vm.$route.name === 'spa'
              )?[_c('p',{staticClass:"can-wrap"},[_vm._v(" "+_vm._s(r.subname)+" ")])]:(_vm.$route.name === 'offers')?[_c('p',[_vm._v(" "+_vm._s(r.validation)+" ")]),_c('p',[_c('span',{domProps:{"innerHTML":_vm._s(r.dates)}})]),_c('p',[_vm._v(" "+_vm._s(r.includes)+" "),_c('span',{domProps:{"innerHTML":_vm._s(r.promotion)}})])]:_vm._e()],2)])])}),0)],1),_c('transition',{attrs:{"name":"slide-bottom"}},[(_vm.$route.name === 'offers')?[(_vm.opened !== null)?_c('Offer',{attrs:{"showcase":_vm.type[_vm.opened]}}):_vm._e()]:[(_vm.opened !== null)?_c('Showcase',{attrs:{"showcase":_vm.type[_vm.opened],"previousShowcase":_vm.opened > 0 ? _vm.type[_vm.opened - 1] : _vm.type[_vm.type.length - 1],"nextShowcase":_vm.opened < _vm.type.length - 1 ? _vm.type[_vm.opened + 1] : _vm.type[0]}}):_vm._e()]],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }