<template>
  <button v-on="$listeners">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17.997"
      height="12.001"
      viewBox="0 0 17.997 12.001"
    >
      <path
        id="Path_102"
        data-name="Path 102"
        d="M434.21,2487.387l.083-.094a1,1,0,0,1,1.32-.083l.094.083,5,5a1,1,0,0,1,.083,1.319l-.083.095-5,5a1,1,0,0,1-1.5-1.319l.083-.094,3.289-3.295H424a1,1,0,0,1-.993-.883L423,2493a1,1,0,0,1,.883-.993L424,2492h13.584l-3.291-3.293a1,1,0,0,1-.083-1.32l.083-.094Z"
        transform="translate(-423 -2487)"
      />
    </svg>
    <slot />
  </button>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "@/scss/variables.scss";
@import "@/scss/mixins.scss";
button {
  align-items: center;
  background-color: transparent;
  border: 0;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-size: 19px;
  font-weight: 500;
  height: 50px;
  padding: 0 16px;
  position: relative;
  text-transform: uppercase;
  width: 200px;
  white-space: nowrap;

  &.big {
    width: 100%;
  }

  svg {
    margin-right: 16px;
    @include transition($transition);
    path {
      fill: #fff;
    }
  }

  &::before {
    background-color: #0000004a;
    bottom: 0;
    border: 1px solid #fff;
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    width: 2px;
    z-index: -1;
    @include transition($transition);
  }

  &:hover {
    svg {
      @include transform(translateX(3px));
    }
    &::before {
      width: 100%;
    }
  }
}
</style>