<template>
  <div class="features">
    <template v-if="$route.name === 'rooms'">
      <div class="features__group">
        <p>{{ $t('features-ttl-1') }}</p>
        <div class="feature" v-if="isVisible(1)">
          <div class="icon">
            <img src="@/assets/img/rooms/features/r1.svg" :alt="$t('features-1-1')" />
          </div>
          <span>{{ $t('features-1-1') }}</span>
        </div>
        <div class="feature" v-if="isVisible(2)">
          <div class="icon">
            <img src="@/assets/img/rooms/features/r2.svg" :alt="$t('features-1-2')" />
          </div>
          <span>{{ $t('features-1-2') }}</span>
        </div>
        <div class="feature" v-if="isVisible(3)">
          <div class="icon">
            <img src="@/assets/img/rooms/features/r3.svg" :alt="$t('features-1-3')" />
          </div>
          <span>{{ $t('features-1-3') }}</span>
        </div>
        <div class="feature" v-if="isVisible(4)">
          <div class="icon">
            <img src="@/assets/img/rooms/features/r4.svg" :alt="$t('features-1-4')" />
          </div>
          <span>{{ $t('features-1-4') }}</span>
        </div>
        <div class="feature" v-if="isVisible(5)">
          <div class="icon">
            <img src="@/assets/img/rooms/features/r5.svg" :alt="$t('features-1-5')" />
          </div>
          <span>{{ $t('features-1-5') }}</span>
        </div>
        <div class="feature" v-if="isVisible(6)">
          <div class="icon">
            <img src="@/assets/img/rooms/features/r6.svg" :alt="$t('features-1-6')" />
          </div>
          <span>{{ $t('features-1-6') }}</span>
        </div>
        <div class="feature" v-if="isVisible(7)">
          <div class="icon">
            <img src="@/assets/img/rooms/features/r7.svg" :alt="$t('features-1-7')" />
          </div>
          <span>{{ $t('features-1-7') }}</span>
        </div>
        <div class="feature" v-if="isVisible(8)">
          <div class="icon">
            <img src="@/assets/img/rooms/features/r8.svg" :alt="$t('features-1-8')" />
          </div>
          <span>{{ $t('features-1-8') }}</span>
        </div>
      </div>
      <div class="features__group">
        <p>{{ $t('features-ttl-2') }}</p>
        <div class="feature" v-if="isVisible(1, true)">
          <div class="icon">
            <img src="@/assets/img/rooms/features/o1.svg" :alt="$t('features-2-1')" />
          </div>
          <span>{{ $t('features-2-1') }}</span>
        </div>
        <div class="feature" v-if="isVisible(2, true)">
          <div class="icon">
            <img src="@/assets/img/rooms/features/o2.svg" :alt="$t('features-2-2')" />
          </div>
          <span>{{ $t('features-2-2') }}</span>
        </div>
        <div class="feature" v-if="isVisible(3, true)">
          <div class="icon">
            <img src="@/assets/img/rooms/features/o3.svg" :alt="$t('features-2-3')" />
          </div>
          <span>{{ $t('features-2-3') }}</span>
        </div>
        <div class="feature" v-if="isVisible(4, true)">
          <div class="icon">
            <img src="@/assets/img/rooms/features/o4.svg" :alt="$t('features-2-4')" />
          </div>
          <span>{{ $t('features-2-4') }}</span>
        </div>
        <div class="feature" v-if="isVisible(5, true)">
          <div class="icon">
            <img src="@/assets/img/rooms/features/o5.svg" :alt="$t('features-2-5')" />
          </div>
          <span>{{ $t('features-2-5') }}</span>
        </div>
        <div class="feature" v-if="isVisible(6, true)">
          <div class="icon">
            <img src="@/assets/img/rooms/features/o6.svg" :alt="$t('features-2-6')" />
          </div>
          <span>{{ $t('features-2-6') }}</span>
        </div>
        <div class="feature" v-if="isVisible(7, true)">
          <div class="icon">
            <img src="@/assets/img/rooms/features/o7.svg" :alt="$t('features-2-7')" />
          </div>
          <span>{{ $t('features-2-7') }}</span>
        </div>
        <div class="feature" v-if="isVisible(8, true)">
          <div class="icon">
            <img src="@/assets/img/rooms/features/o8.svg" :alt="$t('features-2-8')" />
          </div>
          <span>{{ $t('features-2-8') }}</span>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="features__group">
        <p>{{ $t('features-ttl-3') }}</p>
        <div class="feature" v-if="isVisible(1)">
          <div class="icon">
            <img src="@/assets/img/halls/features/h1.svg" :alt="$t('features-3-1')" />
          </div>
          <span>{{ $t('features-3-1') }}</span>
        </div>
        <div class="feature" v-if="isVisible(2)">
          <div class="icon">
            <img src="@/assets/img/halls/features/h2.svg" :alt="$t('features-3-2')" />
          </div>
          <span>{{ $t('features-3-2') }}</span>
        </div>
        <div class="feature" v-if="isVisible(3)">
          <div class="icon">
            <img src="@/assets/img/halls/features/h3.svg" :alt="$t('features-3-3')" />
          </div>
          <span>{{ $t('features-3-3') }}</span>
        </div>
        <div class="feature" v-if="isVisible(4)">
          <div class="icon">
            <img src="@/assets/img/halls/features/h4.svg" :alt="$t('features-3-4')" />
          </div>
          <span>{{ $t('features-3-4') }}</span>
        </div>
        <div class="feature" v-if="isVisible(5)">
          <div class="icon">
            <img src="@/assets/img/halls/features/h5.svg" :alt="$t('features-3-5')" />
          </div>
          <span>{{ $t('features-3-5') }}</span>
        </div>
        <div class="feature" v-if="isVisible(6)">
          <div class="icon">
            <img src="@/assets/img/halls/features/h6.svg" :alt="$t('features-3-6')" />
          </div>
          <span>{{ $t('features-3-6') }}</span>
        </div>
      </div>
      <div class="features__group">
        <p>{{ $t('features-ttl-4') }}</p>
        <div class="feature" v-if="isVisible(1, true)">
          <div class="icon">
            <img src="@/assets/img/halls/features/c1.svg" :alt="$t('features-4-1')" />
          </div>
          <span>{{ $t('features-4-1') }}</span>
        </div>
        <div class="feature" v-if="isVisible(2, true)">
          <div class="icon">
            <img src="@/assets/img/halls/features/c2.svg" :alt="$t('features-4-2')" />
          </div>
          <span>{{ $t('features-4-2') }}</span>
        </div>
        <div class="feature" v-if="isVisible(3, true)">
          <div class="icon">
            <img src="@/assets/img/halls/features/c3.svg" :alt="$t('features-4-3')" />
          </div>
          <span>{{ $t('features-4-3') }}</span>
        </div>
        <div class="feature" v-if="isVisible(4, true)">
          <div class="icon">
            <img src="@/assets/img/halls/features/c4.svg" :alt="$t('features-4-4')" />
          </div>
          <span>{{ $t('features-4-4') }}</span>
        </div>
        <div class="feature" v-if="isVisible(5, true)">
          <div class="icon">
            <img src="@/assets/img/halls/features/c5.svg" :alt="$t('features-4-5')" />
          </div>
          <span>{{ $t('features-4-5') }}</span>
        </div>
        <div class="feature" v-if="isVisible(6, true)">
          <div class="icon">
            <img src="@/assets/img/halls/features/c6.svg" :alt="$t('features-4-6')" />
          </div>
          <span>{{ $t('features-4-6') }}</span>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: ['features', 'optionalFeatures'],
  methods: {
    isVisible(n, optional) {
      if (this[optional ? 'optionalFeatures' : 'features'].indexOf(n) > -1) {
        return true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss';
.features {
  background-color: rgba($color: #fff, $alpha: 0.5);
  border-radius: 13px;
  padding: 0.75rem 1.25rem;
  width: 12.5rem;
  .features__group > p {
    margin: 1rem 0 1.5rem;
    text-align: center;
    white-space: nowrap;
  }
}

.feature {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 1rem;
  width: 50%;
  .icon {
    align-items: center;
    display: inline-flex;
    height: 2.4rem;
    justify-content: center;
    margin-bottom: 0.25rem;
    width: 2.4rem;
    img {
      width: 100%;
    }
  }
  span {
    color: #6b7a99;
    font-size: 0.6rem;
    line-height: 0.6rem;
    text-align: center;
    width: 3.75rem;
  }
}
@media screen and (max-width: $m) {
  .feature {
    span {
      font-size: 0.7rem;
      line-height: 0.7rem;
    }
  }
}
@media screen and (max-width: $s) {
  .features {
    display: flex;
    margin: 0 1rem;
    padding-top: 3rem;
    width: calc(100% - 2rem);
    .features__group {
      width: 50%;
    }
  }
  .feature {
    span {
      font-size: 0.8rem;
      line-height: 0.8rem;
    }
  }
}
</style>