<template>
  <div class="inner-page inner-page--spa">
    <header class="inner-page__header">
      <aside class="inner-page__aside">
        <transition name="slide" mode="out-in">
          <img
            :src="require(`@/assets/img/spa-s/svg_${$i18n.locale}.svg`)"
            :alt="$t('spa-s-alt')"
            class="svg"
          />
        </transition>
        <p>
          <img src="@/assets/img/scroll_h.svg" class="scroll-h" />
          <template v-for="text in $t('spa-s-info')">
            {{ text }}
          </template>
        </p>
      </aside>
      <div class="inner-page__img"></div>
    </header>
    <main class="inner-page__main">
      <About />
      <!-- SECTION 1 -->
      <section class="inner-page__section">
        <Slideshow
          :reversed="true"
          :images="[
            require('@/assets/img/spa-s/s1-1.jpg'),
            require('@/assets/img/spa-s/s1-2.jpg'),
            require('@/assets/img/spa-s/s1-3.jpg'),
          ]"
        />
        <div class="inner-page__texts">
          <h2>{{ $t("spa-s-sc1-ttl") }}</h2>
          <p class="sttl" v-html="$t('spa-s-sc1-sttl')"></p>
          <p v-html="$t('spa-s-sc1-txt')"></p>
        </div>
      </section>
      <!-- SECTION 2 -->
      <section class="inner-page__section section--reversed section--top">
        <div class="inner-page__texts">
          <h2>{{ $t("spa-s-sc2-ttl") }}</h2>
          <p class="sttl" v-html="$t('spa-s-sc1-sttl')"></p>
          <p v-html="$t('spa-s-sc2-sttl1')"></p>
        </div>
        <Slideshow
          :images="[
            require('@/assets/img/spa-s/s2-1.jpg'),
            require('@/assets/img/spa-s/s2-2.jpg'),
            require('@/assets/img/spa-s/s2-3.jpg'),
          ]"
        />
      </section>
      <!-- SECTION 3 -->
      <section class="inner-page__section">
        <Slideshow
          :reversed="true"
          :images="[
            require('@/assets/img/spa-s/s3-1.jpg'),
            require('@/assets/img/spa-s/s3-2.jpg'),
            require('@/assets/img/spa-s/s3-3.jpg'),
          ]"
        />
        <div class="inner-page__texts">
          <h2>{{ $t("spa-s-sc3-ttl") }}</h2>
          <p class="sttl" v-html="$t('spa-s-sc1-sttl')"></p>
          <p v-html="$t('spa-s-sc3-sttl1')"></p>
        </div>
      </section>
      <!-- SECTION 4 -->
      <section class="inner-page__section section--reversed section--top">
        <div class="inner-page__texts">
          <h2>{{ $t("spa-s-sc4-ttl") }}</h2>
          <p class="sttl" v-html="$t('spa-s-sc1-sttl')"></p>
          <p v-html="$t('spa-s-sc4-txt1')"></p>
        </div>
        <Slideshow
          :images="[
            require('@/assets/img/spa-s/s4-1.jpg'),
            require('@/assets/img/spa-s/s4-2.jpg'),
            require('@/assets/img/spa-s/s4-3.jpg'),
          ]"
        />
      </section>
    </main>
  </div>
</template>

<script>
import About from "./AboutServices";
import Slideshow from "@/components/Slideshow";
export default {
  components: {
    About,
    Slideshow,
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/inner_page.scss";
.inner-page__img {
  background-image: url("../../assets/img/spa/bg.jpg");
}

.inner-page__main {
  background-image: url("../../assets/img/bg.jpg");
}

.inner-page__header {
  background-color: #e5d5bc;
}
</style>
