<template>
  <div class="menus-wrapper">
    <main>
      <header>
        <img
          :src="require(`@/assets/img/restaurant/svg_${$i18n.locale}.svg`)"
          :alt="$t('restaurant-alt')"
          class="svg"
        />
        <p>{{ $t("menus.subtitle") }}</p>
      </header>
      <main>
        <a
          href="/public/menus/Regnum Bansko - A la carte.pdf"
          class="menu-item"
          target="_blank"
        >
          <img src="@/assets/img/menus/a_la_carte.png" alt="A La Carte" />
          <div class="menu-item__content">
            <img src="@/assets/img/menus/a_la_carte.jpg" alt="A La Carte" />
            <h2>{{ $t("menus.1-title") }}<br />{{ $t("menus.1-title-2") }}</h2>
            <hr />
            <p>{{ $t("menus.1-subtitle") }}</p>
          </div>
        </a>
        <a
          href="/public/menus/Regnum Bansko - Room Service.pdf"
          class="menu-item"
          target="_blank"
        >
          <img src="@/assets/img/menus/room_service.png" alt="Room Service" />
          <div class="menu-item__content">
            <img src="@/assets/img/menus/room_service.jpg" alt="Room Service" />
            <h2>{{ $t("menus.2-title") }}<br />{{ $t("menus.2-title-2") }}</h2>
            <hr />
            <p>{{ $t("menus.2-subtitle") }}</p>
          </div>
        </a>
        <a
          href="/public/menus/Regnum Bansko - Lobby Bar.pdf"
          class="menu-item"
          target="_blank"
        >
          <img src="@/assets/img/menus/lobby_bar.png" alt="Lobby Bar" />
          <div class="menu-item__content">
            <img src="@/assets/img/menus/lobby_bar.jpg" alt="Lobby Bar" />
            <h2>{{ $t("menus.3-title") }}<br />{{ $t("menus.3-title-2") }}</h2>
            <hr />
            <p>{{ $t("menus.3-subtitle") }}</p>
          </div>
        </a>
        <a
          href="/public/menus/Regnum Bansko - Wine Menu.pdf"
          class="menu-item"
          target="_blank"
        >
          <img src="@/assets/img/menus/wine_list.png" alt="Wine List" />
          <div class="menu-item__content">
            <img src="@/assets/img/menus/wine_list.jpg" alt="Wine List" />
            <h2>{{ $t("menus.4-title") }}<br />{{ $t("menus.4-title-2") }}</h2>
            <hr />
            <p>{{ $t("menus.4-subtitle") }}</p>
          </div>
        </a>
                 <a
          href="/public/menus/Regnum Bansko - AquaPark.pdf"
          class="menu-item"
          target="_blank"
        >
          <img src="@/assets/img/menus/aquapark.png" alt="AquaPark" />
          <div class="menu-item__content">
            <img src="@/assets/img/menus/aquapark.jpg" alt="AquaPark" />
            <h2>{{ $t("menus.5-title") }}<br />{{ $t("menus.5-title-2") }}</h2>
            <hr />
            <p>{{ $t("menus.5-subtitle") }}</p>
          </div>
        </a>
        <a
          href="/public/menus/Regnum Bansko - SPA.pdf"
          class="menu-item"
          target="_blank"
        >
          <img src="@/assets/img/menus/spa.png" alt="SPA" />
          <div class="menu-item__content">
            <img src="@/assets/img/menus/spa.jpg" alt="SPA" />
            <h2>{{ $t("menus.6-title") }}<br />{{ $t("menus.6-title-2") }}</h2>
            <hr />
            <p>{{ $t("menus.6-subtitle") }}</p>
          </div>
        </a>
      </main>
    </main>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "@/scss/variables.scss";
@import "@/scss/mixins.scss";
.menus-wrapper {
  overflow: hidden;
  padding-top: 100px;

  > main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: calc(100vh - 100px);
    min-height: calc(var(--vh, 1vh) * 100 - 100px);
    position: relative;
    width: 100%;
    @include transition($transition);
    &::before,
    &::after {
      content: "";
      height: 100%;
      left: 0;
      position: fixed;
      top: 0;
      width: 100%;
    }

    header {
      align-items: center;
      color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: relative;
      width: 100%;
      z-index: 1;

      img {
        display: block;
        margin-bottom: 1rem;
        width: 50%;
      }
      p {
        font-size: 2.2rem;
        line-height: 2.2rem;
        text-align: center;
        width: 70%;
      }
    }
    // items wrapper
    > main {
      align-items: center;
      display: flex;
      flex-wrap: wrap;

      max-width: 1282px;
      margin: 0 auto;
      padding: 20px;
      position: relative;
      z-index: 1;
    }

    &::before {
      background-image: url("../../assets/img/restaurant/s1-1.jpg");
      background-size: cover;
    }
    &::after {
      background-color: rgba($color: #000, $alpha: 0.7);
    }
  }
}

.menu-item {
  margin: 1rem 20px;
  display: block;
  flex-basis: 581px;
  padding: 1.3rem 0 1.3rem 1.3rem;
  position: relative;
  > img {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: auto;
    z-index: 2;
  }
}
.menu-item__content {
  align-items: center;
  background-color: #000;
  color: #fff;
  display: flex;
  height: 214px;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  padding-left: 173px;
  position: relative;
  > img {
    height: 100%;
    left: 0;
    object-fit: cover;
    opacity: 0.23;
    position: absolute;
    top: 0;
    text-align: center;
    width: 100%;
  }

  * {
    position: relative;
    z-index: 1;
  }
  h2 {
    font-size: 52px;
    line-height: 44px;
    text-align: center;
  }
  hr {
    background-color: #fff;
    border: 0;
    height: 1px;
    margin: 1.25rem 0 1rem;
    width: 40%;
  }
  p {
    font-size: 29px;
    line-height: 29px;
    text-align: center;
  }
}

@media screen and (min-width: 1366px) {
  .menus-wrapper {
    > main {
      header {
        display: none;
      }
    }
  }
  .menu-item {
    &:nth-child(3),
    &:nth-child(4) {
      padding: 1.3rem 1.3rem 1.3rem 0;
      > img {
        left: initial;
        right: 0;
      }
      .menu-item__content {
        padding-left: 20px;
        padding-right: 173px;
      }
    }
  }
}

@media screen and (max-width: 1366px) {
  .menus-wrapper {
    > main {
      > main {
        flex-direction: column;
        flex-wrap: initial;
      }
    }
  }
  .menu-item {
    flex-basis: initial;
    width: 581px;
    &:nth-child(1) {
      order: 1;
    }
    &:nth-child(2) {
      order: 3;
    }
    &:nth-child(3) {
      order: 2;
    }
    &:nth-child(4) {
      order: 4;
    }
    &:nth-child(5) {
      order: 5;
    }
    &:nth-child(6) {
      order: 6;
    }
    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(6) {
      padding: 1.3rem 1.3rem 1.3rem 0;
      > img {
        left: initial;
        right: 0;
      }
      .menu-item__content {
        padding-left: 20px;
        padding-right: 173px;
      }
    }
  }
}

@media screen and (max-width: $s) {
  .menus-wrapper {
    &::before {
      background: rgb(0, 18, 23);
      background: linear-gradient(
        180deg,
        rgba(0, 18, 23, 1) 0%,
        rgba(0, 18, 23, 0.3533788515406162) 50%,
        rgba(0, 18, 23, 0) 100%
      );
      content: "";
      height: 170px;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1;
    }
    > main {
      &::before {
        background-image: url("../../assets/img/bg.jpg");
      }
      &::after {
        content: initial;
      }
      header {
        mix-blend-mode: difference;
        img,
        p {
          width: 80%;
        }
      }
      > main {
        margin: initial;
      }
    }
  }

  .menu-item {
    padding-right: 10px;
    width: 100%;
    > img {
      box-shadow: 0 18px 40px #0000009e;
    }

    &:nth-child(3),
    &:nth-child(4) {
      padding-left: 10px;
      > img {
        box-shadow: 0 18px 40px #0000009e;
      }
    }
  }
  .menu-item__content {
    h2 {
      font-size: 36px;
      line-height: 32px;
    }
    hr {
      width: 28%;
    }
    p {
      font-size: 20px;
      line-height: 20px;
    }
  }
}

@media screen and (max-width: $xxs) {
  .menu-item__content {
    h2 {
      font-size: 26px;
      line-height: 22px;
    }
    p {
      font-size: 16px;
      line-height: 16px;
    }
  }
}
</style>
