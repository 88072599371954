import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueI18n from "vue-i18n";
import axios from "axios";
// import VueFbCustomerChat from 'vue-fb-customer-chat'

require("intersection-observer");
import VueObserveVisibility from "vue-observe-visibility";

Vue.config.productionTip = false;

Vue.use(VueI18n);
Vue.use(VueObserveVisibility);

Vue.prototype.$http = axios;

const messages = {
  bg: require("./locale/bg.json"),
  en: require("./locale/en.json"),
};

const i18n = new VueI18n({
  locale: "en",
  messages,
});

const savedLang = localStorage.getItem("lang");

function getLang() {
  if (navigator.languages != undefined) return navigator.languages[0];
  return navigator.language;
}

if (!savedLang) {
  if (getLang() === "bg-BG") {
    i18n.locale = "bg";
  }
} else {
  i18n.locale = savedLang;
}

// Vue.use(VueFbCustomerChat, {
//   page_id: '652671402347064',
//   theme_color: '#333333', // theme color in HEX
//   locale: 'en_US', // default 'en_US'
// })

Vue.filter("formatDate", function(value) {
  if (value) {
    const d = new Date(value);
    return (
      ("0" + d.getDate()).slice(-2) +
      "/" +
      ("0" + (d.getMonth() + 1)).slice(-2) +
      "/" +
      d.getFullYear()
    );
    // return moment(String(value)).format('DD/MM/YYYY')
  } else {
    return i18n.messages[i18n.locale]["select-date"];
  }
});

new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
