<template>
  <div class="inner-page inner-page--spa">
    <header class="inner-page__header">
      <aside class="inner-page__aside">
        <transition name="slide" mode="out-in">
          <img
            :src="require(`@/assets/img/spa-o/svg_${$i18n.locale}.svg`)"
            class="svg"
            :alt="$t('spa-o-alt')"
          />
        </transition>
        <p>
          <img src="@/assets/img/scroll_h.svg" class="scroll-h" />
          <template v-for="text in $t('spa-o-info')">
            {{ text }}
          </template>
        </p>
      </aside>
      <div class="inner-page__img"></div>
    </header>
    <main class="inner-page__main">
      <!-- SECTION PROMO TEMP
      <section class="inner-page__section section--reversed section--top">
        <div class="inner-page__texts">
          <h2>{{ $t("spa-promo-ttl") }}</h2>
          <p class="sttl" v-html="$t('spa-promo-sttl')"></p>
          <p v-html="$t('spa-promo-text')"></p>
          <p class="sttl" v-html="$t('spa-promo-sttl1')"></p>
          <p v-html="$t('spa-promo-text1')"></p>
        </div>
        <Slideshow
          :images="[
            require('@/assets/img/spa-o/sp1.jpg'),
            require('@/assets/img/spa-o/sp2.jpg'),
            require('@/assets/img/spa-o/sp3.jpg'),
          ]"
        />
      </section> -->
      <About />
      <!-- SECTION 1 Happy hour нов-->
      <section class="inner-page__section">
        <Slideshow
          :reversed="true"
          :images="[
            require('@/assets/img/spa-o/s1-1.jpg'),
            require('@/assets/img/spa-o/s1-2.jpg'),
            require('@/assets/img/spa-o/s1-3.jpg'),
          ]"
        />
        <div class="inner-page__texts">
          <h2>{{ $t("⭐Happy hour") }}</h2>
          <p class="sttl" v-html="$t('spa-promo-sttl1')"></p>
          <p v-html="$t('spa-promo-text1')"></p>
          <p v-html="$t('spa-o-sc1-sttl')"></p>
        </div>
      </section>

      <!-- SECTION 2 СПА ритуал за двама -->
      <!-- <section class="inner-page__section section--reversed section--top">
        <div class="inner-page__texts">
          <h2>{{ $t("spa-o-sc2-ttl") }}</h2>
          <p class="sttl" v-html="$t('spa-o-sc1-sttl')"></p>
          <p v-html="$t('spa-o-sc2-sttl1')"></p>
        </div>
        <Slideshow
          :images="[
            require('@/assets/img/spa-o/s2-1.jpg'),
            require('@/assets/img/spa-o/s2-2.jpg'),
            require('@/assets/img/spa-o/s2-3.jpg'),
          ]"
        />
      </section> -->

      <!-- SECTION 3 Възползвайте се от нашия Happy hour-->
      <!-- <section class="inner-page__section">
        <Slideshow
          :reversed="true"
          :images="[
            require('@/assets/img/spa-o/s3-1.jpg'),
            require('@/assets/img/spa-o/s3-2.jpg'),
            require('@/assets/img/spa-o/s3-3.jpg'),
          ]"
        />
        <div class="inner-page__texts">
          <h2>{{ $t("spa-o-sc3-ttl") }}</h2>
          <p class="sttl" v-html="$t('spa-o-sc1-sttl')"></p>
          <p v-html="$t('spa-o-sc3-txt')"></p>
        </div>
      </section> -->
      <!-- SECTION 4  За него и за нея -->
      <section class="inner-page__section section--reversed section--top">
        <div class="inner-page__texts">
          <h2>{{ $t("spa-o-sc4-ttl") }}</h2>
          <p class="sttl" v-html="$t('spa-o-sc1-sttl')"></p>
          <p v-html="$t('spa-o-sc4-sttl2')"></p>
        </div>
        <Slideshow
          :images="[
            require('@/assets/img/spa-o/s4-1.jpg'),
            require('@/assets/img/spa-o/s4-2.jpg'),
            require('@/assets/img/spa-o/s4-3.jpg'),
          ]"
        />
      </section>
    </main>
  </div>
</template>

<script>
import About from "./AboutOffers";
import Slideshow from "@/components/Slideshow";
export default {
  components: {
    About,
    Slideshow,
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/inner_page.scss";
.inner-page__img {
  background-image: url("../../assets/img/spa/spa2.jpg");
}

.inner-page__main {
  background-image: url("../../assets/img/bg.jpg");
}

.inner-page__header {
  background-color: #e5d5bc;
}
</style>